<template>
  <confirm-modal
    ref="confirmModal"
    name="missing-phone"
    :auto-close="false"
    @confirm="submit"
  >
    <form class="container" @submit.prevent="submit">
      <h2>Nepotpune korisničke informacije</h2>
      <span>Da bi ste se mogli prijaviti na oglas potrebno je prvo unijeti broj telefona.</span>
      <Input
        title="Telefon"
        placeholder="Unesite broj"
        leftIcon="fas fa-phone"
        v-model="form.phoneNumber"
        :errorMessage="phoneNumberErrors"
      />
    </form>
  </confirm-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import ConfirmModal from '@/components/basic/ConfirmModal.vue';

export default {
  name: 'MissingPhoneModal',
  components: {
    ConfirmModal,
  },
  computed: {
    phoneNumberErrors() {
      if (!this.$v.form.phoneNumber.$dirty) return null;
      if (!this.$v.form.phoneNumber.required) return 'Obavezan unos';
      return null;
    },
  },
  data() {
    return {
      form: {
        phoneNumber: '',
      },
    };
  },
  validations: {
    form: {
      phoneNumber: {
        required,
      },
    },
  },
  methods: {
    open() {
      this.$refs.confirmModal.open();
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$refs.confirmModal.close();
        this.$emit('submit', this.form.phoneNumber);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  h2 {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  span {
    display: block;
    margin: 20px 0;
    font-size: 14px;
  }
}
</style>
