<template>
  <span>
    <modal
      name='login-modal'
      height="auto"
      width="100%"
      :max-width="600"
      :adaptive="true"
    >
      <div class="authorization-modal">
        <div class="modal-close" @click="$modal.toggle('login-modal')">
          <i class="fas fa-times"></i>
        </div>
        <h2>Prijavi se putem</h2>
        <login
          @success="$modal.toggle('login-modal')"
          @gotoRegistration="switchToRegistration"
        />
      </div>
    </modal>
    <modal
      name='registration-modal'
      height="auto"
      width="100%"
      :max-width="600"
      :adaptive="true"
      :clickToClose="false"
    >
      <div class="authorization-modal">
        <div class="modal-close" @click="$modal.toggle('registration-modal')">
          <i class="fas fa-times"></i>
        </div>
        <h2>Registriraj se</h2>
        <registration
          @success="$modal.toggle('registration-modal')"
          @gotoLogin="switchToLogin"
        />
      </div>
    </modal>
  </span>
</template>

<script>
import Login from '@/views/Login/Login.vue';
import Registration from '@/views/Registration/Registration.vue';

export default {
  name: 'Modals',
  components: {
    Login,
    Registration,
  },
  methods: {
    switchToRegistration() {
      this.$modal.hide('login-modal');
      this.$modal.show('registration-modal');
    },
    switchToLogin() {
      this.$modal.hide('registration-modal');
      this.$modal.show('login-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.authorization-modal {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 860px;
  max-height: 100vh;
  padding: 40px;
  background: linear-gradient(rgba(0,0,0,0.32),rgba(0,0,0,0.32)),
  url("./assets/images/backgrounds/background_FHD.jpg") top center;
  background-size: cover;
  overflow: auto;
  .modal-close {
    position: absolute;
    top: 40px;
    right: 40px;
    font-size: 35px;
    color: #B1E9F5;
    cursor: pointer;
    &:hover {
      color: #FFFFFF;
    }
    @media (max-width: 700px) {
      top: 20px;
      right: 20px;
      font-size: 32px;
    }
  }
  h2 {
    width: 100%;
    margin-top: 2px;
    margin-bottom: 70px;
    color: #FFF;
    text-align: left;
    font-size: 40px;
    line-height: 48px;
  }
  .button {
    color: #FFFFFF!important;
  }
}
</style>
