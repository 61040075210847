<script>
export default {
  name: 'JobForm',
  props: {
    activeTab: {
      type: Number,
      default: 0,
    },
    bodyClass: {
      type: String,
    },
  },
  data() {
    return {
      selectedIndex: this.activeTab,
    };
  },
  methods: {
    tabClick(idx) {
      this.selectedIndex = idx;
      this.$emit('change', idx);
    },
  },
  watch: {
    activeTab(index) {
      this.selectedIndex = index;
    },
  },
  render(ce) {
    return ce(
      'div',
      {
        class: 'container',
      },
      [
        ce(
          'header',
          [
            ce(
              'ul',
              this.$slots.tabs.map((slot, idx) => ce(
                'li',
                {
                  class: { selected: this.selectedIndex === idx },
                  on: {
                    click: () => this.tabClick(idx),
                  },
                },
                [slot],
              )),
            ),
          ],
        ),
        ce(
          'article',
          this.$slots.default.map((slot, idx) => ce(
            'div',
            {
              class: {
                visible: idx === this.selectedIndex,
              },
            },
            [slot],
          )),
        ),
      ],
    );
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  header {
    ul {
      display: flex;
      justify-content: space-between;
      @media (max-width: 1000px) {
        flex-wrap: wrap;
      }
      li {
        width: 100%;
        cursor: pointer;
        padding-bottom: 10px;
        border-bottom: 1px solid gray;
        @media (max-width: 1000px) {
          width: 33%;
          margin-bottom: 20px;
        }
        &.selected {
          border-bottom: 5px solid gray;
        }
      }
    }
  }
  article {
    max-width: 520px;
    margin: 60px auto 0;
    text-align: left;
    > div {
      display: none;
      &.visible {
        display:block;
      }
    }
  }
}
.container {
  header {
    padding-bottom: 0;
  }
}
</style>
