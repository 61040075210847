<template>
  <div class="container">
    <div class="form-content">
      <Input
        name="companyName"
        title="NAZIV TVRTKE"
        theme="blue"
        v-model="validate.name.$model"
        :errorMessage="companyNameErrors"
      />
      <Input
        name="oib"
        title="OIB TVRTKE"
        theme="blue"
        v-model="validate.oib.$model"
        :errorMessage="oibErrors"
        placeholder="Unesite oib. Ako ne posjedujete oib stavite prazno polje"
      />
      <Input
        name="contactEmail"
        title="KONTAKT EMAIL ADRESA"
        theme="blue"
        v-model="validate.email.$model"
        :errorMessage="emailErrors"
      />
      <div class="multiselect-container">
        <h4>DJELATNOST TVRTKE</h4>
        <multiselect
          v-model="validate.industry.$model"
          :options="industries"
          label="name"
          placeholder="-"
        />
        <error-message :message="industryErrors" />
      </div>
      <div class="places-autocomplete-container">
        <h4>ADRESA TVRTKE</h4>
        <places-autocomplete
          theme="blue"
          v-model="validate.location.$model"
          :types="[]"
        />
        <error-message :message="locationErrors" />
      </div>
      <error-message :message="serverValidationMessage" />
    </div>
    <div class="controls">
      <Button
        theme="blue-100"
        icon="fas fa-chevron-left"
        @click="$emit('back')"
      />
      <Button
        title="Registriraj se"
        :loading="isLoading"
        @click="$emit('submit')"
      />
    </div>
  </div>
</template>

<script>
import PlacesAutocomplete from '@/components/shared/PlacesAutocomplete.vue';

export default {
  name: 'CompanyInfo',
  components: {
    PlacesAutocomplete,
  },
  props: {
    value: {
      type: Object,
      required: true,
    },
    industries: {
      type: Array,
      required: true,
    },
    validate: {
      type: Object,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    serverValidationMessage: {
      type: String,
    },
  },
  computed: {
    companyNameErrors() {
      if (!this.validate.name.$dirty) return null;
      if (!this.validate.name.required) return 'Obavezan unos';
      return null;
    },
    oibErrors() {
      return null;
      /* if (!this.validate.oib.$dirty) return null;
      if (!this.validate.oib.required) return 'Obavezan unos';
      if (!this.validate.oib.oib) return 'Nije OIB';
      return null; */
    },
    emailErrors() {
      if (!this.validate.email.$dirty) return null;
      if (!this.validate.email.required) return 'Obavezan unos';
      if (!this.validate.email.email) return 'Nije email';
      return null;
    },
    industryErrors() {
      if (!this.validate.industry.$dirty) return null;
      if (!this.validate.industry.required) return 'Obavezan unos';
      if (!this.validate.industry.minLength) return 'Najmanje 1';
      return null;
    },
    locationErrors() {
      if (!this.validate.location.lat.$dirty) return null;
      if (!this.validate.location.lat.required) return 'Obavezan unos';
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.multiselect-container::v-deep {
  margin-bottom: 10px;
}
h4 {
  display: block;
  margin: 0 0 5px;
  text-align: left;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: bold;
}
.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 50px;
  button {
      margin: 0;
      border: none;
      &:hover {
        border-color: transparent;
      }
    }
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .form-content {
    flex: 1;
  }
}
</style>
