import { createStore } from '@/store/storeFactories';
import JobService from '@/services/job.service';

export default createStore({
  defaultFilterState: {
    search: '',
    radius: 30,
    latitude: 45.8150,
    longitude: 15.9819,
    place: '',
    offset: 0,
    limit: 4,
  },
  defaultDetailsState: {
    company: {
      id: null,
      name: '',
    },
    location: {},
    salary: {
      from: null,
      to: null,
    },
    expirationDate: new Date(),
  },
  services: {
    getListService: JobService.getJobsForEmployee,
    getService: JobService.getJob,
  },
});
