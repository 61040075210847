<template>
  <section id="about">
    <div class="inner-container">
        <h4>Kako do potrebnih djelatnika?</h4>
        <ul>
          <li>
            <div class="circle">1</div>
            <p>
              <a @click="$modal.toggle('registration-modal')">
                <strong>Registrirajte</strong></a> svoju tvrtku
            </p>
          </li>
          <li>
            <div class="circle">2</div>
            <p>
              <strong>Odaberite željeni format oglasa</strong>,
              u ponudi su tri formata po promotivnim cijenama:
            </p>
            <div class="ads-container">
              <div class="ad"><h5><strong>TOP</strong> OGLAS</h5>
                <ul>
                  <li>Oglas koji se cijelo vrijeme pokazuje na naslovnoj stranici.</li>
                  <li>Oglas traje 15 dana.</li>
                </ul>
              </div>
              <div class="ad"><h5><strong>IZDVOJENI</strong> OGLAS</h5>
                <ul>
                  <li>Oglas za jednu radnu poziciju pozicioniran na vrhu liste oglasa prema pripadajućoj lokaciji i radnoj poziciji.</li>
                  <li>Oglas traje 15 dana.</li>
                </ul>
              </div>
              <div class="ad"><h5><strong>OBIČAN</strong> OGLAS</h5>
                <ul>
                  <li>Oglas za jednu radnu poziciju pozicioniran ispod Izdvojenih oglasa prema pripadajućoj lokaciji i radnoj poziciji.</li>
                  <li>Oglas traje 15 dana.</li>
                </ul>
              </div>
            </div>
          </li>
          <li>
            <div class="circle">3</div>
            <p><strong>Odaberite željeni način primanja i pregledavanja prijava</strong>
            – telefonski ili unutar profila svoje tvrtke na web stranici
            </p>
          </li>
        </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
};
</script>

<style lang="scss" scoped>
  section#about {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    h4 { margin: 10px 0 20px 0; font-size: 30px; font-weight: bold; color: #393939; }
    .inner-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      max-width: 1000px;
      padding: 56px 0 90px 0;
      color: #777777;

        > ul {
            width: 100%;

          > li {
            min-height: 56px;
            width: 100%;
            margin: 10px 0;
            background-color:#F8F8F8;
            padding: 17px;
            text-align: left;
            border-radius: 10px;
            a {
              color: #777777;
              text-decoration: underline !important;
              cursor: pointer;
            }
          }
          .circle {
            display: inline-block;
            width: 30px;
            line-height: 30px;
            border-radius: 50%;
            background-color: #26D35D;
            color: #E4FFEC;
            text-align: center;
            font-size: 16px;
            margin-right: 11px;
          }
          p {
            margin: 0;
            display: inline;
            strong {
              font-weight: bold;
            }
          }
          .ads-container {
            display: flex;
            margin-top: 28px;
            .ad {
              flex: 1;
              background-color: #ECECEC;
              margin-right: 20px;
              padding: 20px;
              border-radius: 10px;
              &:last-of-type {
                margin: 0;
              }
              h5 {
                font-size:18px;
                color: #393939;
                text-align: center;
                margin-bottom: 20px;
                strong {
                  font-weight: bold;
                }
              }
              ul {
                list-style-type: disc;
                padding: 0 28px;
              }
            }
          }
        }
    }
  }
  @media (max-width: 990px) {
    section#about {
      .inner-container {
        padding: 40px 5% 80px 5%;
        .ads-container {
          flex-direction: column;
          .ad {
            margin: 0 0 20px 0;
          }
        }
      }
    }
  }
</style>
