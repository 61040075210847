<template>
  <div class="container">
    <i class="far fa-check-circle" />
    <h1>Hvala na registraciji</h1>
    <div class="controls">
      <Button
        title="Završi"
        @click="$emit('done')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ThankYou',
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
  color: white;
  text-align: center;
  i {
    color: #26D35D;
    font-size: 120px;
    margin-top: 120px;
  }
  h1 {
    color: #fff;
    margin: 40px 0 85px 0;
    font-size: 30px;
  }
  button {
    margin: 0 0 0 auto;
    &:hover {
      border-color: transparent;
    }
  }
}
</style>
