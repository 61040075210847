<template>
  <placeholder-container
    :hide="!!job.id"
    message="Označite oglas kako bi ste ga pročitali"
  >
    <div class="container">
      <job-details
        :data="job"
        :isApplying="isApplying"
        :showThankYou="showThankYou"
        :showCallButton="true"
        :showLocationButton="true"
      />
      <div class="actions" v-if="job.id">
        <Button
          v-if="showApplyButton"
          title="Prijavi se"
          icon="far fa-paper-plane"
          :loading="isApplying"
          :disabled="disableAppyForJobButton"
          @click="maybeMakeJobApplication"
        />
        <Button
          v-if="job.applyViaPhone"
          theme="white"
          title="Nazovi"
          icon="fas fa-phone"
          @click="maybeMakeJobPhoneApplication"
        />
        <Button
          theme="white"
          title="Lokacija"
          icon="fas fa-map-marker-alt"
          @click="showJobLocationOnMap"
        />
      </div>
      <modal name='phone-modal' height="auto" :adaptive="true">
        <div class="phone-modal-container">
          <div class="modal-close" @click="$modal.hide('phone-modal')">
            <i class="fas fa-times"></i>
          </div>
          <div class="modal-content">
            <a href="`tel:${phone_number}`">
              <i class="fas fa-phone"></i>
              {{ job.phoneNumber }}
            </a>
          </div>
        </div>
      </modal>
      <missing-phone-modal
        ref="missingPhoneModal"
        @submit="savePhoneNumberAndMakeJobApplication"
      />
      <confirm-modal
        ref="confirmJobApplicationModal"
        title="Želite li se prijaviti za posao?"
        @confirm="makeJobApplication(job.id)"
      />
    </div>
  </placeholder-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ConfirmModal from '@/components/basic/ConfirmModal.vue';
import JobDetails from '@/components/shared/JobDetails.vue';
import MissingPhoneModal from './MissingPhoneModal.vue';

export default {
  name: 'JobSearchDetail',
  components: {
    ConfirmModal,
    MissingPhoneModal,
    JobDetails,
  },
  props: {
    jobId: {
      type: Number,
    },
  },
  data() {
    return {
      showThankYou: false,
    };
  },
  computed: {
    ...mapGetters('session', ['isAuthorized', 'user']),
    ...mapState('employee/jobs', {
      job: state => state.details.data,
      loadingJob: state => state.details.load.inProgress,
    }),
    ...mapState('employee/jobApplication', {
      jobApplicationSuccess: state => state.details.create.success,
      jobApplicationMessage: state => state.details.create.message,
      isApplying: state => state.details.create.inProgress,
    }),
    ...mapState('employee/profile', {
      profile: state => state.details.data,
    }),
    showApplyButton() {
      return (
        this.job.applyViaApplication || this.job.applyViaExternalLink
      );
    },
    disableAppyForJobButton() {
      return this.isAuthorized && (this.user.isEmployer || this.job.hasApplied);
    },
  },
  metaInfo() {
    const meta = [];
    if (this.job.noIndex === false) {
      meta.push({
        name: 'robots',
        content: 'noindex',
      });
    }

    return {
      meta: [{
        name: 'robots',
        content: 'noindex',
      }],
    };
  },
  methods: {
    maybeMakeJobApplication() {
      if (this.job.applyViaExternalLink) {
        this.visitExternalLink(this.job.externalLink);
      } else if (this.isAuthorized === false) {
        this.openLoginModal();
      } else if (this.isAuthorized && !this.profile.phoneNumber) {
        this.notifyForMissingPhoneNumber();
      } else {
        this.openConfirmJobApplicationModal();
      }
    },
    maybeMakeJobPhoneApplication() {
      if (this.isAuthorized) {
        this.makeJobPhoneApplication();
      }
      this.openPhoneModal();
    },
    openLoginModal() {
      this.$modal.show('login-modal');
    },
    openPhoneModal() {
      this.$modal.show('phone-modal');
    },
    visitExternalLink(externalLink) {
      this.makeExternalLinkApplication();
      const win = window.open(externalLink);
      win.focus();
    },
    notifyForMissingPhoneNumber() {
      this.$refs.missingPhoneModal.open();
    },
    openConfirmJobApplicationModal() {
      this.$refs.confirmJobApplicationModal.open();
    },
    savePhoneNumberAndMakeJobApplication(phoneNumber) {
      this.$store.dispatch('employee/profile/save', { phoneNumber })
        .then(() => {
          this.$store.dispatch('employee/profile/set', { phoneNumber });
          this.makeJobApplication();
        });
    },
    makeJobApplication() {
      this.$store.dispatch('employee/jobApplication/create', this.job.id)
        .then(() => {
          if (this.jobApplicationSuccess) {
            this.showThankYou = true;
            this.$store.dispatch('employee/jobs/set', { hasApplied: true });
          } else {
            this.$store.dispatch('applicatio/ui/showToast', { message: this.jobApplicationMessage });
          }
        });
    },
    makeJobPhoneApplication() {
      this.$store.dispatch('employee/jobPhoneApplication/create', this.job.id);
    },
    makeExternalLinkApplication() {
      this.$store.dispatch('employee/jobExternalLinkApplication/create', this.job.id);
    },
    showJobLocationOnMap() {
      const win = window.open(`https://www.google.com/maps/search/?api=1&query=${this.job.location.lat},${this.job.location.lng}`);
      win.focus();
    },
  },
  watch: {
    jobId(value) {
      this.showThankYou = false;
      this.$store.dispatch('employee/jobs/select', value);
      window.dataLayer.push({ job_id: value });
      window.dataLayer.push({ event: 'job_view' });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
  button {
    margin: 0 15px 0 0;
  }
  @media (max-width: 430px) {
    flex-direction: column;
    button {
      width: 100%;
      margin: 0 0 10px;
    }
  }
}
.phone-modal-container {
  padding: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  .modal-close {
    position: absolute;
    top: 20px;
    right: 25px;
    font-size: 34px;
    color: #333333;
    cursor: pointer;
    transition: all 150ms linear;
    &:hover {
      color: #545454;
    }
  }
  .modal-content {
    font-size: 24px;
    a {
      font-weight: bold;
    }
  }
}
</style>
