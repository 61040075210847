/* eslint-disable import/prefer-default-export */
import { format } from 'date-fns';

export function getJobResponseDataToModel(data) {
  /**
   * For some ducked up reason we have string 'false' in remote_link field
   * so normalized this before proceding forward.
   */
  const externalLink = data.remote_link !== 'false' ? data.remote_link : '';
  let applyViaExternalLink = false;
  if (data.recruitment === false) {
    applyViaExternalLink = externalLink !== '';
  }

  return {
    id: data.id,
    title: data.title,
    description: data.description,
    phoneNumber: data.phone_number,
    externalLink,
    expirationDate: new Date(data.expires),
    location: {
      lat: data.point.latitude,
      lng: data.point.longitude,
      place: data.town,
    },
    salary: {
      from: data.salary_from,
      to: data.salary_to,
    },
    company: {
      id: data.company,
      name: data.company_name,
      avatar: data.company_avatar,
    },
    hasApplied: data.has_applied,
    applyViaApplication: data.recruitment,
    applyViaPhone: data.phone_contact,
    applyViaExternalLink,
    noIndex: data.html_meta_tag_no_index,
    /**
     * Employer specific fields:
     */
    typeID: data.ad_type || 1,
    jobApplicationsCount: data.application_count,
    isActive: data.active,
    applicationTypes: [
      ...data.recruitment ? [1] : [],
      ...data.phone_contact ? [2] : [],
    ],
  };
}

export function getJobsResponseDataToModelForEmployer(data) {
  return data.map(job => ({
    id: job.id,
    title: job.title,
    description: job.description,
    position: job.position[0],
    company: {
      id: job.company,
      name: job.company_name,
    },
    location: {
      lat: job.point.latitude,
      lng: job.point.longitude,
      place: job.town,
    },
    expirationDate: new Date(job.expires),
    isActive: job.active,
  }));
}

export function getJobsResponseDataToModelForEmployee(data) {
  return data.map(job => ({
    id: job.id,
    typeID: job.ad_type || 1,
    title: job.title,
    description: job.description,
    position: job.position[0],
    company: {
      name: job.company,
    },
    location: {
      lat: job.location.lat,
      lng: job.location.lng,
      place: job.town,
    },
    expirationDate: new Date(job.expires),
    /**
     * Hidden for Employee
     */
    isActive: null,
  }));
}

export function saveJobModelToRequestData(data) {
  return {
    company: data.companyID,
    ad_type: data.typeID,
    title: data.title,
    description: data.description,
    point: {
      latitude: data.location.lat,
      longitude: data.location.lng,
    },
    town: data.location.place,
    salary_from: data.salary.from,
    salary_to: data.salary.to,
    special_benefit: data.specialBenefit,
    recruitment: data.applicationTypes.includes(1),
    phone_contact: data.applicationTypes.includes(2),
    phone_number: data.phoneNumber,
  };
}

export function getJobApplicationResponseDataToModel(jobAppModel, profileData, workExperienceData) {
  return {
    id: jobAppModel.id,
    job: jobAppModel.job,
    user: {
      id: profileData.data.id,
      firstName: profileData.data.first_name,
      lastName: profileData.data.last_name,
      email: profileData.data.email,
      biography: profileData.data.bio,
      phoneNumber: profileData.data.phone_number,
      avatarUrl: profileData.data.avatar,
      highSchool: profileData.data.higher_education,
      academicEducation: profileData.data.secondary_education,
      workExperience: workExperienceData.map(item => ({
        id: item.id,
        companyTitle: item.company_name,
        description: item.description,
        startDate: new Date(item.start),
        endDate: new Date(item.end),
        position: {
          id: item.position.id,
          title: item.position.title_en,
        },
      })),
      userDocuments: profileData.data.documents.map(item => ({
        documentId: item.documentId,
        partialUrl: item.partialUrl,
        documentName: item.documentName
      }))
    },
  };
}

export function getJobApplicationsResponseDataToModel(data) {
  return data.map(item => ({
    id: item.id,
    applicationDate: new Date(item.applied_at),
    applicationStatus: item.status,
    user: {
      id: item.applicant,
      firstName: item.applicant_name,
      lastName: item.applicant_lastname,
      avatarUrl: item.applicant_avatar,
    },
    job: {
      id: item.job,
      title: item.job_title,
    },
  }));
}

export function getCompaniesResponseDataToModel(data) {
  return data.map(company => ({
    id: company.pk,
    name: company.name,
    avatarUrl: company.avatar,
    location: {
      lat: company.point.latitude,
      lng: company.point.longitude,
      place: company.address,
    },
    email: company.e_mail,
    // address: company.address,
    oib: company.oib,
    phoneNumber: company.phone_number,
    industries: company.industries.map(item => ({ id: item.id, name: item.title_en })),
  }));
}

export function saveCompanyModelToRequestData(data) {
  return {
    name: data.name,
    email: data.email,
    oib: data.oib,
    address: data.location.place,
    industries: [data.industry.id],
  };
}

export function getProfileResponseDataToModel(data) {
  return {
    id: data.pk,
    firstName: data.first_name,
    lastName: data.last_name,
    avatarUrl: data.avatar,
    phoneNumber: data.phone_number,
    biography: data.bio,
    highSchool: data.secondary_education,
    academicEducation: data.higher_education,
  };
}

export function saveProfileModelToRequestData(data) {
  return {
    first_name: data.firstName,
    last_name: data.lastName,
    phone_number: data.phoneNumber,
    bio: data.biography,
    secondary_education: data.highSchool,
    higher_education: data.academicEducation,
  };
}

export function getWorkExperienceResponseDataToModel(data) {
  return data.map(item => ({
    id: item.id,
    companyTitle: item.company_name,
    description: item.description,
    startDate: new Date(item.start),
    endDate: new Date(item.end),
    position: {
      id: item.position.id,
      title: item.position.title_en,
    },
  }));
}

export function saveWorkExperienceModelToRequestData(data) {
  return {
    user: data.userId,
    company_name: data.companyTitle,
    start: format(data.startDate, 'yyyy-MM-dd'),
    end: format(data.endDate, 'yyyy-MM-dd'),
    position: data.position.id,
    description: data.description,
  };
}

export function getIndustriesResponseDataToModel(data) {
  return data.map(item => ({ id: item.id, name: item.title_en }));
}

export function getPositionsResponseDataToModel(data) {
  return data.map(item => ({ id: item.id, name: item.name }));
}
