import { createStore } from '@/store/storeFactories';
import ProfileService from '@/services/profile.service';

export default createStore({
  defaultDetailsState: {
    id: null,
    firstName: '',
    lastName: '',
    avatarUrl: '',
    phoneNumber: '',
    biography: '',
    workExperience: [],
    highSchool: '',
    academicEducation: '',
  },
  services: {
    getService: ProfileService.getProfile,
    saveService: ProfileService.saveProfile,
  },
});
