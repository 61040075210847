<template>
  <ul>
    <li
      v-for="item in options"
      :key="item.id"
      :class="isChecked(item) ? 'selected' : null"
    >
      <input
        :type="inputType"
        :name="inputName"
        :checked="isChecked(item)"
        @click="handleClick(item)"
      />
      <slot :item="item" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'Selectable',
  props: {
    value: {
      type: [Number, Array],
    },
    type: String,
    options: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      inputName: Array.isArray(this.value)
        ? null : new Date().getTime().toString(),
      inputType: this.type,
    };
  },
  methods: {
    isChecked(item) {
      if (Array.isArray(this.value)) {
        return this.value.includes(item.id);
      }
      return this.value === item.id;
    },
    handleClick(item) {
      if (Array.isArray(this.value)) {
        let newValue = [];
        if (this.isChecked(item)) {
          newValue = this.value.filter(id => id !== item.id);
        } else {
          newValue = this.value.concat(item.id);
        }

        this.$emit('input', newValue);
      } else {
        this.$emit('input', item.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 20px;
    margin-top: 18px;
    background-color: #F5F5F5;
    border: solid 1px #CBCBCB;
    border-radius: 5px;
    color: #393939;
    cursor: pointer;
    transition: all 50ms linear;
    &:first-of-type {
      margin-top: 0;
    }
    &.selected {
      background-color: #FFFFFF;
      .left {
        color: black!important;
        h3 {
          color: #26D35D!important;
        }
      }
      .right {
       span {
       color: #333 !important;
       }
      }
    }
    input {
      margin-right: 20px;
      transform-origin: center;
      &[type="radio"] {
        transform: scale(1.3);
      }
      &[type="checkbox"] {
        transform: scale(1.4);
      }
    }
    .slot-container { width: 100%; }
    .new-phone-input {
      margin-left: 30px;
      color: black;
      height: 30px;
    }
  }
}
</style>
