<template>
  <div class="container">
    <slot v-if="hide" />
    <div v-else class="placeholder-container">
      <i class="fab fa-earlybirds" />
      <h1>{{ message }}</h1>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PlaceholderContainer',
  props: {
    hide: {
      type: Boolean,
      default() {
        return false;
      },
    },
    message: {
      type: String,
      default() {
        return 'Please select';
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  text-align: left;
}
.placeholder-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: gray;
  background: #F1F1F1;
  h1 {
    margin-top: 30px;
    font-size: 30px;
    color: #54B454;
  }
  i {
    font-size: 100px;
    color: #54B454;
  }
}
</style>
