<template>
  <placeholder-container
    :hide="!!jobApplication.user.id"
    message="Označite prijavu kako bi ste ju pročitali"
  >
    <div class="application-details">
      <div class="details">
        <div class="basic-info">
          <div class="profile-info">
            <div class="profile-picture">
              <img src="@/assets/images/test_profile.svg" />
            </div>
            <div class="inner-wrapper">
              <h2>{{ jobApplication.user.firstName }} {{ jobApplication.user.lastName }}</h2>
              <div class="contact-info">
                <div class="phone" v-if="jobApplication.user.phoneNumber">
                  <i class="fas fa-phone"></i>
                  {{ jobApplication.user.phoneNumber }}
                </div>
                <div class="email" v-if="jobApplication.user.email">
                  <i class="fas fa-envelope"></i>
                  {{ jobApplication.user.email }}
                </div>
              </div>
            </div>
          </div>
          <div class="biography" v-if="jobApplication.user.biography">
            <h3>Biografija</h3>
            <p>{{ jobApplication.user.biography }}</p>
          </div>
        </div>
        <div class="extended-info">
          <div class="inner-container" v-if="!jobApplication.user.userDocuments || jobApplication.user.userDocuments.length === 0">
            <h3>Radno iskustvo</h3>
            <work-experience-list v-if="!jobApplication.user.userDocuments || jobApplication.user.userDocuments.length === 0" :data="jobApplication.user.workExperience" />
          </div>

          <div v-if="!jobApplication.user.userDocuments || jobApplication.user.userDocuments.length !== 0" class="inner-container">
            <h3>Dokumenti korisnika</h3>
            <user-documents :data="jobApplication.user.userDocuments" />
          </div>

          <div v-if="!jobApplication.user.userDocuments || jobApplication.user.userDocuments.length === 0" class="inner-container">
            <h3>Obrazovanje</h3>
            <div class="higher-education">
              <h4>Visoko obrazovanje</h4>
              <p>{{ jobApplication.user.academicEducation }}</p>
            </div>
            <div class="middle-school">
              <h4>Srednja škola</h4>
              <p>{{ jobApplication.user.highSchool }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </placeholder-container>
</template>

<script>
import { mapState } from 'vuex';
import WorkExperienceList from './WorkExperienceList.vue';
import UserDocuments from './UserDocuments.vue'
export default {
  name: 'JobApplicationDetail',
  components: {
    WorkExperienceList,
    UserDocuments
  },
  props: {
    userId: {
      type: [String, Number],
    },
  },
  computed: {
    ...mapState('employer/jobApplications', {
      jobApplication: state => state.details.data,
      jobApplicationLoading: state => state.details.load.inProgress,
    }),
  },
  watch: {
    userId(value) {
      // Construct sufficient job app model in order to make the call
      const jobApp = {
        user: { id: value },
      };
      this.$store.dispatch('employer/jobApplications/select', jobApp);
    },
  },
};
</script>

<style lang="scss" scoped>
.application-details {
  flex: 1;
  text-align: left;
  h3 {
    font-size: 19px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 25px;
  }
  p {
    font-size: 19px;
    color: #777777;
  }
  .details {
    border: solid 1px #EEEEEE;
    border-radius: 5px;
  }
  .basic-info {
    padding: 25px;
    .profile-info {
      display: flex;
      margin-bottom: 50px;
      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
      }
      .profile-picture {
        width: 144px;
        height: 154px;
        overflow: hidden;
        img {
          border-radius: 5px;
        }
      }
      .inner-wrapper {
        margin-left: 30px;
        @media (max-width: 1000px) {
          margin-left: 0;
        }
        h2 {
          margin-bottom: 20px;
          font-size: 36px;
          font-weight: bold;
          @media (max-width: 1000px) {
            text-align: center;
          }
        }
        .contact-info {
          @media (max-width: 400px) {
            max-width: 280px;
          }
          > div {
            border: solid 1px #E8E8E8;
            border-radius: 10px;
            font-size: 17px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            i {
              padding-right: 16px;
              color: #26D35D;
            }
          }
          .phone {
            margin-bottom: 5px;
            padding: 8px 16px;
          }
          .email {
            padding: 8px 16px;
          }
        }
      }
    }
    .biography {
      h3 {
        margin-bottom: 12px;
      }
      p {
        color: #393939;
        font-size: 17px;
      }
    }
  }
  .inner-container {
    padding: 25px;
    background-color: #F5F5F5;
    &:first-child {
      border-bottom: solid 1px #EEEEEE;
    }
    h4 {
      margin-bottom: 5px;
      font-size: 19px;
      font-weight: bold;
      color: #000000;
    }
    > div {
      margin-bottom: 25px;
    }
    li {
      padding-top: 25px;
      &:first-of-type {
        padding-top: 0;
      }
      h6 {
        margin-bottom: 8px;
        font-size: 19px;
        font-weight: bold;
        color: #54B454;
      }
      p {
        color: #BBBBBB;
        &.description {
          margin-top: 15px;
          color: #777777
        }
      }
    }
  }
}
</style>
