<template>
  <div :class="['input-container', 'default', theme]">
    <h4>{{ title }}</h4>
    <label>
      <i v-if="leftIcon" :class="leftIcon"></i>
      <textarea
        :class="{'has-icon': leftIcon}"
        :type="type"
        v-model="inputValue"
      />
    </label>
    <error-message :message="errorMessage" />
  </div>
</template>

<script>
export default {
  name: 'TextArea',
  props: {
    title: String,
    value: String,
    leftIcon: String,
    type: {
      type: String,
      default: 'text',
    },
    errorMessage: String,
    theme: {
      type: String,
      default() {
        return 'default';
      },
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.default {
  h4 { color: #393939 }
  textarea {
    font-size: 19px;
    color: #777777;
    border: 1px solid #e8e8e8;
    &:-webkit-autofill {
      box-shadow: inset 0 0 0 9999px #0A4057;
      -webkit-box-shadow: inset 0 0 0 9999px #0A4057;
      -webkit-text-fill-color: #9CE2FF;
      border-width: 0;
    }
    &:focus { outline: none; }
  }
}
.blue {

}
.gray {
  textarea { background-color: #F5F5F5; }
}
.input-container {
  position: relative;
  margin-bottom: 10px;
  h4 {
    display: block;
    margin: 0 0 5px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  label {
    height: 60px;
    i {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      width: 70px;
      color: rgb(156, 226, 255);
      font-size: 19px;
      opacity: .5;
    }
    textarea {
      width: 100%;
      min-height: 130px;
      padding: 20px;
      border-radius: 5px;
      font-size: 19px;
      resize: vertical;
    }
  }
}
</style>
