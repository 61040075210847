import Vue from 'vue';
import Vuelidate from 'vuelidate';
import VModal from 'vue-js-modal';
import VueSlider from 'vue-slider-component';
import 'vue-multiselect/dist/vue-multiselect.min.css';
import Multiselect from 'vue-multiselect';
import VueMeta from 'vue-meta';

import config from '@/config';

import './registerServiceWorker';
import Input from '@/components/basic/Input.vue';
import ErrorMessage from '@/components/basic/ErrorMessage.vue';
import PlaceholderContainer from '@/components/basic/PlaceholderContainer.vue';
import Button from '@/components/basic/Button.vue';
import TextArea from '@/components/basic/TextArea.vue';
import Paginate from '@/components/basic/Paginate.vue';
import List from '@/components/basic/List.vue';
import isMobileMixin from '@/mixins/isMobile.mixin';
import App from './App.vue';
import router from './router';
import store from './store';
import routerSync from './store/routerSync';

Vue.config.productionTip = false;

Vue.use(Vuelidate);
Vue.use(VueMeta);
Vue.use(VModal);
Vue.component('Input', Input);
Vue.component('ErrorMessage', ErrorMessage);
Vue.component('PlaceholderContainer', PlaceholderContainer);
Vue.component('TextArea', TextArea);
Vue.component('Button', Button);
Vue.component('vue-slider', VueSlider);
Vue.component('multiselect', Multiselect);
Vue.component('paginate', Paginate);
Vue.component('list', List);
Vue.mixin(isMobileMixin);

routerSync(store, router, { moduleName: 'router' });

// eslint-disable-next-line func-names
window.fbAsyncInit = function () {
  window.FB.init({
    appId: config.fbAppId,
    autoLogAppEvents: true,
    xfbml: true,
    version: 'v6.0',
  });
};

store.dispatch({ type: 'session/authorize' })
  .then(() => {
    new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
  });
