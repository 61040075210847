<template>
  <div class="registration-container">
    <employer-form
      @destroy="activeIndex = 0"
      @done="$emit('success')"
    />
    <div class="to-login">
      <span>Već imate aktivan račun? </span>
      <a @click.prevent="$emit('gotoLogin')">Prijavi se!</a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import EmployerForm from './Employer/Form.vue';


export default {
  name: 'Registration',
  components: {
    EmployerForm,
  },
  methods: {
    ...mapActions('session', ['register']),
    selectType(type) {
      this.activeIndex = type === 'employee' ? 1 : 2;
    },
  },
  data() {
    return {
      withChoice: false,
      activeIndex: 0,
    };
  },
};
</script>

<style lang="scss">
  .registration-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    form {
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      .input-container {
        margin-bottom: 0;
      }
    }
    input::placeholder {
      color: #FFF;
    }
    .phase-paginate {
      width: 100%;
      max-width: 90px;
      margin: 30px auto 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .phase-step {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #4A7C87;
        transition: all 300ms ease-out;
        &.active {
          background-color: #FFFFFF;
        }
      }
    }
    .to-login {
      display: flex;
      justify-content: center;
      margin-top: 38px;
      color: #fff;
      a {
        margin-left: 5px;
        text-decoration: none;
        color: #2AFF70!important;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        &:hover {
          color: #00FF54!important;
        }
      }
    }
    /**
     * Make all multiselect component look the same in Registration
     */
    .multiselect {
      .multiselect__select:before {
        color: #9CE2FF;
        border-color: #9CE2FF transparent transparent;
      }
      .multiselect__tags {
        height: 100%;
        width: 100%;
        padding: 12px 40px 12px 15px;
        background-color: #1C434A;
        color: #FFF;
        font-size: 19px;
        border-width: 1px;
        border-style: solid;
        border-color: transparent;
        border-image: initial;
        border-radius: 5px;
        input {
          color: #FFF;
          &::placeholder {
            color: #FFF;
          }
        }
        .multiselect__placeholder {
          color: #FFF;
        }
        .multiselect__single {
          background: none;
          color: #FFF;
        }
      }
      .multiselect__content-wrapper {
        background:#1C434A;
        color: white;
        border: none;
      }
    }
  }
</style>
