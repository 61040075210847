import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home/Home.vue';
// import JobSearch from '@/views/employee/JobSearch/JobSearch.vue';
import EmployeeJob from '@/views/employee/Job/Job.vue';
import EmployerJob from '@/views/employer/Job/Job.vue';
import UserProfile from '@/views/UserProfile/UserProfile.vue';
import CompanyProfile from '@/views/employer/CompanyProfile/CompanyProfile.vue';
import PublishedJobs from '@/views/employer/PublishedJobs/PublishedJobs.vue';
import JobApplications from '@/views/employer/JobApplications/JobApplications.vue';
import JobApplication from '@/views/employer/JobApplication/JobApplication.vue';
import JobForm from '@/views/employer/JobForm/JobForm.vue';
import JobCreated from '@/views/employer/JobCreated/JobCreated.vue';
import NotFound from '@/views/NotFound/NotFound.vue';
import TermsOfService from '@/views/TermsOfService/TermsOfService.vue';
import { allow } from './auth';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  // {
  //   path: '/jobs',
  //   name: 'JobSearch',
  //   component: JobSearch,
  // },
  {
    path: '/jobs/:id',
    name: 'EmployeeJob',
    component: EmployeeJob,
  },
  {
    path: '/user/profile',
    name: 'UserProfile',
    component: UserProfile,
    beforeEnter: allow('employee', 'employer'),
  },
  {
    path: '/employer/company-profile',
    name: 'CompanyProfile',
    component: CompanyProfile,
    beforeEnter: allow('employer'),
  },
  {
    path: '/employer/published-jobs',
    name: 'PublishedJobs',
    component: PublishedJobs,
    beforeEnter: allow('employer'),
  },
  {
    path: '/employer/published-jobs/:id',
    name: 'PublishedJob',
    component: EmployerJob,
    beforeEnter: allow('employer'),
  },
  {
    path: '/employer/published-jobs/:id/applications',
    name: 'JobApplications',
    component: JobApplications,
    beforeEnter: allow('employer'),
  },
  {
    /**
     * API is pure hack, so we have to use user's ID to fetch "job application" :)
     */
    path: '/employer/published-jobs/:id/applications/:userId',
    name: 'JobApplication',
    component: JobApplication,
    beforeEnter: allow('employer'),
  },
  {
    path: '/employer/published-jobs/:id/:mode',
    name: 'EditJob',
    component: JobForm,
    beforeEnter: allow('employer'),
  },
  {
    path: '/employer/new-job',
    name: 'NewJob',
    component: JobForm,
    beforeEnter: allow('employer'),
  },
  {
    path: '/employer/job-created',
    name: 'JobCreated',
    component: JobCreated,
    beforeEnter: allow('employer'),
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: '/terms-of-service',
    name: 'TermsOfServiceEN',
    component: TermsOfService,
  },
  {
    path: '/pravila-koristenja',
    name: 'TermsOfServiceHR',
    component: TermsOfService,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  // eslint-disable-next-line no-unused-vars
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ x: 0, y: 0 });
      });
    });
  },
});

export default router;
