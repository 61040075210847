<template>
  <form class="add-we-container"  @submit.prevent="submit">
    <div class="modal-header">
      <span>Radno iskustvo</span>
      <div class="modal-close" @click="$emit('close')">
        <i class="fas fa-times"></i>
      </div>
    </div>
    <div class="modal-content">
      <div class="component-cell">
        <Input
          theme="gray"
          title="Naziv poslodavca"
          v-model="$v.companyTitle.$model"
          :errorMessage="companyTitleErrors"
        />
      </div>
      <div class="component-cell">
        <text-area
          theme="gray"
          title="Kratki opis zaduženja"
          v-model="$v.description.$model"
          :errorMessage="descriptionErrors"
        />
      </div>
      <div class="component-cell">
        <h4>RADNO MJESTO</h4>
        <multiselect
          v-model="position"
          :options="positions"
          label="name"
          placeholder="Pretraži radna mjesta"
        />
        <error-message :message="positionErrors" />
      </div>
      <div class="columns">
        <div class="component-cell">
          <h4>POČETAK RADNOG ODNOSA</h4>
          <month-picker v-model="startDate" />
        </div>
        <div class="component-cell">
          <h4>KRAJ RADNOG ODNOSA</h4>
          <month-picker
            v-model="$v.endDate.$model"
            :errorMessage="endDateErrors"
          />
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <Button
        :title="data.id ? 'Uredi' : 'Dodaj'"
        type="submit"
      />
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import MonthPicker from './MonthPicker.vue';

export default {
  name: 'WorkExperienceForm',
  components: {
    MonthPicker,
  },
  props: {
    data: {
      type: Object,
      default() {
        return {
          id: null,
          companyTitle: '',
          description: '',
          position: null,
          startDate: new Date(),
          endDate: new Date(),
        };
      },
    },
  },
  computed: {
    ...mapState('shared/positions', {
      positions: state => state.list.data,
    }),
    companyTitleErrors() {
      if (!this.$v.companyTitle.$dirty) return null;
      if (!this.$v.companyTitle.required) return 'Obavezan unos';
      return null;
    },
    descriptionErrors() {
      if (!this.$v.description.$dirty) return null;
      if (!this.$v.description.required) return 'Obavezan unos';
      return null;
    },
    positionErrors() {
      if (!this.$v.position.$dirty) return null;
      if (!this.$v.position.required) return 'Obavezan unos';
      return null;
    },
    endDateErrors() {
      if (!this.$v.endDate.required) return 'Obavezan unos';
      if (this.$v.endDate.$model < this.$v.startDate.$model) return 'Mora biti veći od početka';
      return null;
    },
  },
  validations: {
    companyTitle: {
      required,
    },
    description: {
      required,
    },
    position: {
      required,
    },
    startDate: {
      required,
    },
    endDate: {
      required,
    },
  },
  data() {
    return {
      id: this.data.id,
      companyTitle: this.data.companyTitle,
      description: this.data.description,
      position: this.data.position
        ? { id: this.data.position.id, name: this.data.position.title }
        : undefined,
      startDate: this.data.startDate,
      endDate: this.data.endDate,
    };
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.$emit('save', {
          id: this.id,
          companyTitle: this.companyTitle,
          description: this.description,
          position: this.position,
          startDate: this.startDate,
          endDate: this.endDate,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.add-we-container {
  padding: 0!important;
  .modal-header {
    padding: 30px;
    background-color: #F9F9F9;
    border-bottom: solid 1px #EBEBEB;
    font-size: 41px;
    color: #333333;
    @media (max-width: 430px) { font-size: 31px; }
    .modal-close {
      position: absolute;
      top: 30px;
      right: 30px;
      font-size: 44px;
      color: #333333;
      cursor: pointer;
      transition: all 150ms linear;
      &:hover { color: #545454; }
      @media (max-width: 430px) { font-size: 34px; }
    }
  }
  .modal-footer {
    padding: 25px 30px 35px;
    border-bottom: solid 1px #EBEBEB;
    background-color: #F9F9F9;
  }
  .modal-content {
    .columns {
      display: flex;
      @media (max-width: 430px) { flex-direction: column; }
      > div {
        flex: 1;
        &:first-of-type {
          border-right: solid 1px #EBEBEB;
        }
      }
    }
    .component-cell {
      padding: 25px 30px 35px;
      border-bottom: solid 1px #EBEBEB;
      h4 {
        display: block;
        margin: 0 0 15px;
        font-weight: bold;
        font-size: 14px;
        font-weight: bold;
        color: #393939;
      }
      input {
        border: none;
        height: 50px;
      }
    }
  }
}
</style>

<style lang="scss">
  .add-we-container .component-cell .input-container.alt-input input {
    border: none;
    height: 50px;
  }
  .add-we-container .component-cell .input-container.alt-input textarea {
    border: none;
    height: 50px;
  }
</style>
