<template>
  <div class="container">
    <form @submit.prevent="submit">
      <h1>Uredi profil</h1>
      <div class="basic-info-container">
        <div class="profile-picture-wrapper">
          <h4>Profilna slika</h4>
          <div class="profile-picture">
            <img src="@/assets/images/test_profile.svg" alt="profile-pic" />
          </div>
        </div>
        <div class="basic-info-wrapper">
          <div class="bi-input">
            <label for="first-name">Ime:</label>
            <input
              id="first-name"
              type="text"
              name="firstName"
              v-model="form.firstName"
            />
          </div>
          <div class="bi-input">
            <label for="last-name">Prezime:</label>
            <input
              id="last-name"
              type="text"
              name="lastName"
              v-model="form.lastName"
            />
          </div>
          <div class="bi-input">
            <label for="phone">Telefon:</label>
            <input
              id="phone"
              type="text"
              name="phone"
              v-model="form.phoneNumber"
            />
          </div>
        </div>
      </div>
      <div class="work-experience-container">
        <h3>RADNO ISKUSTVO</h3>
        <div class="work-experience-wrapper">
          <div class="add-work-experience" @click="openWorkExperienceModal()">
            <h5>Dodaj radno iskustvo</h5>
            <i class="fas fa-plus"></i>
          </div>
          <work-experience-list
            :data="workExperience"
            @click="openWorkExperienceModal"
            @delete="deleteWorkExpereience"
          />
        </div>
      </div>
      <div class="biography-container">
        <h3>BIOGRAFIJA</h3>
        <div class="textarea-wrapper">
          <textarea
            placeholder="Dostignuća, ambicije i ciljevi u karijeri, interesi, hobiji..."
            v-model="form.biography"
          />
        </div>
      </div>
      <div class="education-container">
        <h3>OBRAZOVANJE</h3>
        <div class="textarea-wrapper">
          <h5>Srednja škola</h5>
          <textarea
            placeholder="Naziv obrazovne ustanove..."
            v-model="form.highSchool"
          />
          <h5>Visoko obrazovanje</h5>
          <textarea
            placeholder="Naziv obrazovne ustanove..."
            v-model="form.academicEducation"
          />
        </div>
      </div>
      <Button
        type="submit"
        title="Spremi"
        :loading="isSavingProfile"
      />
    </form>
    <modal
      name='workExperience-modal'
      height="auto"
      width="100%"
      :scrollable="true"
      :max-width="700"
      :adaptive="true"
    >
      <work-experience-form
        :data="selectedWorkExperience"
        @save="submitWorkExperience"
        @close="closeWorkExperienceModal"
      />
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import LoadingBarMixin from '@/mixins/loadingBar.mixin';
import WorkExperienceForm from './WorkExperienceForm.vue';
import WorkExperienceList from './WorkExperienceList.vue';

export default {
  name: 'UserProfile',
  mixins: [LoadingBarMixin],
  components: {
    WorkExperienceForm,
    WorkExperienceList,
  },
  computed: {
    ...mapState('employee/profile', {
      userProfile: state => state.details.data,
      isSavingProfile: state => state.details.save.inProgress,
      saveProfileSuccess: state => state.details.save.success,
    }),
    ...mapState('employee/workExperience', {
      workExperience: state => state.list.data,
      isDeletingWorkExperience: state => state.details.delete.inProgress,
      saveWorkExperienceSuccess: state => state.details.save.success,
      createWorkExperienceSuccess: state => state.details.create.success,
      deleteWorkExperienceSuccess: state => state.details.delete.success,
    }),
  },
  data() {
    return {
      form: {
        id: null,
        firstName: '',
        lastName: '',
        avatarUrl: '',
        phoneNumber: '',
        biography: '',
        highSchool: '',
        academicEducation: '',
      },
      selectedWorkExperience: {},
    };
  },
  methods: {
    openWorkExperienceModal(workExperience) {
      this.selectedWorkExperience = workExperience;
      this.$modal.show('workExperience-modal');
    },
    closeWorkExperienceModal() {
      this.$modal.hide('workExperience-modal');
    },
    submit() {
      this.$store.dispatch('employee/profile/save', this.form)
        .then(() => {
          if (this.saveProfileSuccess) {
            this.$store.dispatch('employee/profile/set', this.form);
            this.$store.dispatch('application/ui/showToast', { message: 'Profil ažuriran' });
          }
        });
    },
    saveWorkExperience(workExperience) {
      this.$store.dispatch('employee/workExperience/save', {
        userId: this.$store.getters['session/user'].id,
        ...workExperience,
      })
        .then(() => {
          if (this.saveWorkExperienceSuccess) {
            this.getWorkExperience();
            this.$store.dispatch('application/ui/showToast', { message: 'Ažurirano radno iskustvo' });
          }
        });
    },
    createWorkExperience(workExperience) {
      this.$store.dispatch('employee/workExperience/create', workExperience)
        .then(() => {
          if (this.createWorkExperienceSuccess) {
            this.getWorkExperience();
            this.$store.dispatch('application/ui/showToast', { message: 'Dodano radno iskustvo' });
          }
        });
    },
    deleteWorkExpereience(workExperience) {
      this.$store.dispatch('employee/workExperience/delete', workExperience.id)
        .then(() => {
          if (this.deleteWorkExperienceSuccess) {
            this.getWorkExperience();
            this.$store.dispatch('application/ui/showToast', { message: 'Izbrisano radno iskustvo' });
          }
        });
    },
    submitWorkExperience(workExperience) {
      this.$modal.hide('workExperience-modal');
      if (workExperience.id) {
        this.saveWorkExperience(workExperience);
      } else {
        this.createWorkExperience(workExperience);
      }
    },
    getProfile() {
      this.dispatchWithLoadingBar('employee/profile/get')
        .then(() => {
          this.$store.dispatch('application/ui/setLoadStatus', { inProgress: false });
          this.setFormData(this.userProfile);
        });
    },
    setFormData(data) {
      this.form = Object.assign({}, data);
    },
    getWorkExperience() {
      this.dispatchWithLoadingBar('employee/workExperience/getList');
    },
  },
  mounted() {
    this.getProfile();
    this.getWorkExperience();
  },
};
</script>

<style lang="scss" scoped>
.textarea-wrapper {
  width: 100%;
  padding-top: 20px;
  border: solid 1px #EBEBEB;
  border-radius: 5px;
  textarea {
    width: calc(100% - 60px);
    min-height: 130px;
    margin: 10px 30px 30px;
    font-size: 16px;
    color: #777777;
    resize: vertical;
    border: none;
  }
}
.container {
  width: 100%;
  max-width: 700px;
  margin: 40px auto 100px;
  padding-top: 30px;
  text-align: left;
  form {
    width: 100%;
    margin-bottom: 55px;
    display: flex;
    flex-direction: column;
    button[type="submit"] {
      margin-top: 40px;
    }
    h1 {
      margin-bottom: 40px;
      font-size: 43px;
      font-weight: bold;
      text-align: center;
    }
    h3 {
      margin: 60px 0 12px;
      font-size: 20px;
      font-weight: bold;
      color: #393939;
      opacity: 0.33;
    }
    h5 {
      margin: 10px 0 0;
      padding: 0 30px;
      font-size: 19px;
      font-weight: bold;
      color: #000000;
    }
    .basic-info-container {
      display: flex;
      flex-direction: column;
      border: solid 1px #EBEBEB;
      border-radius: 5px;
      .profile-picture-wrapper {
        padding: 35px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #F9F9F9;
        border-bottom: solid 1px #EBEBEB;
        h4 {
          margin-bottom: 12px;
          font-size: 19px;
          font-weight: bold;
          color: #777777;
        }
        .profile-picture {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          overflow: hidden;
          cursor: pointer;
        }
      }
      .basic-info-wrapper {
        .bi-input {
          height: 60px;
          padding: 0 30px;
          display: flex;
          align-items: center;
          border-bottom: solid 1px #EBEBEB;
          font-size: 19px;
          &:last-of-type {
            border-bottom: none;
          }
          label {
            width: 150px;
            display: flex;
            color: #000000;
            align-items: center;
            font-weight: bold;
          }
          input {
            flex: 1;
            height: 100%;
            font-size: 19px;
            border: none;
          }
        }
      }
    }
    .work-experience-container {
      .work-experience-wrapper {
        width: 100%;
        border: solid 1px #EBEBEB;
        border-radius: 5px;
        .add-work-experience {
          margin: 0;
          padding: 15px 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          cursor: pointer;
          h5 {
            margin: 0;
            padding: 0;
            padding-right: 20px;
          }
          i {
            font-size: 25px;
            color: #26D35D;
          }
        }
      }
    }
    .education-container {
      h5:nth-of-type(2) {
        border-top: solid 1px #EBEBEB;
        margin-top: 0;
        padding-top: 30px;
      }
    }
  }
  button {
    &:hover {
      border-color: transparent;
    }
  }
}
@media (max-width: 430px) {
  .container {
    form {
      .basic-info-container {
        .basic-info-wrapper {
          .bi-input {
            flex-direction: column;
            text-align: center;
            height: 100%;
            padding: 15px 20px;
            label {
              width: 100%;
              justify-content: center;
              margin-bottom: 5px;
            }
            input {
              width: 100%;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>
