<template>
  <div class="container">
    <aside :class="{ 'mobile': isMobile }">
      <slot name="master"></slot>
    </aside>
    <section v-if="isNotMobile">
      <slot name="detail"></slot>
    </section>
  </div>
</template>

<script>
export default {
  name: 'MasterDetail',
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  min-height: calc(100vh - 200px);
  aside {
    display: flex;
    flex-direction: column;
    width: 280px;
    margin-right: 40px;
  }
  aside.mobile {
    flex: 1;
    margin-right: 0;
    max-width: unset;
  }
  section {
    flex: 1;
    display: flex;
  }
}
</style>
