<template>
  <job-search-details :jobId="jobId" />
</template>

<script>
import JobSearchDetails from '@/views/employee/JobSearch/JobSearchDetails.vue';

export default {
  name: 'Job',
  components: {
    JobSearchDetails,
  },
  data() {
    return {
      jobId: null,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.jobId = this.$route.params.id;
    }
  },
};
</script>
