<template>
  <div>
    <h1>UVJETI KORIŠTENJA</h1>
    <li>
      <strong>Uvodne odredbe</strong>
      <p>Ovim Uvjetima korištenja uređuju se prava i obveze u vezi s korištenjem aplikacije Poslovac
        na web stranici <router-link to="/">www.poslovac.net</router-link>, a koja je dostupna
        putem aplikacija za mobilne platforme (dalje u tekstu: Poslovac ili Aplikacija) i
        predstavlja uslugu koju pruža trgovačko društvo TAU ON-LINE d.o.o., Zagreb, Strojarska cesta
        20, MBS 080297343, OIB 14273924910 (dalje u tekstu: Društvo).</p>
      <p>Uvjeti korištenja, kao i sve njegove izmjene i dopune koje bi uslijedile, vrijede od dana
        kada su objavljeni na web stranici <router-link to="/">www.poslovac.net</router-link> ,
        te su sastavni dio svakog ugovora, usmenog ili pisanog, sklopljenog između Društva i
        korisnika, te obvezuju svaku fizičku i pravnu osobu koja koristi aplikaciju Poslovac.</p>
      <p>Poslovac je namijenjen posredovanju u informacijama o otvorenim radnim mjestima koja na
        aplikaciji Poslovac objavljuje Poslodavac i tako ih čini dostupnim Posloprimcima koji žele
        biti informirani o otvorenim radnim mjestima.</p>
      <p>Poslovac omogućuje korisnicima internet pristup podacima postavljenim od strane autora
        objave. Objava se sastoji od teksta, lokacije i fotografija, odnosno grafičkih unosa
        korisnika, razvrstanih po pojedinačnim kategorijama.</p>
      <p>Poslovac omogućuje registriranim korisnicima da ga koriste kao platformu za kreiranje,
        postavljanje, mijenjanje svih informacija u vezi sa objavama, a koje radnje su tehnički
        moguće u skladu sa funkcionalnošću same Aplikacije.</p>
      <p>Svako korištenje PosLovca u cijelosti ili bilo kojeg njegovog dijela koje nije u skladu s
        Uvjetima korištenja, smatrat će se zloupotrebom usluge koju pruža Društvo i kršenjem Uvjeta
        korištenja.</p>
      <p>Društvo zadržava pravo izmjene, ukidanja (privremeno ili trajno) bilo kojeg elementa
        PosLovca, kao i sadržaja odnosno unosa, bez obzira na to tko je autor i bez prethodnog
        odobrenja ili obavijesti, uz primjenu dobrih poslovnih običaja.</p>
      <p>Društvo zadržava pravo brisanja tekstova neprimjerenog, uvredljivog i diskriminatornog
        sadržaja, a koji sadržaj bi bio protivan Ustavu Republike Hrvatske, važećim prisilnim
        propisima i moralu. Ako se Poslodavac ne pridržava prisilnih pozitivnih propisa, a posebice
        prisilnih odredaba važećeg Zakona o radu, Društvo zadržava pravo na neobjavljivanje
        pojedinačnog ili svih sadržaja tog Poslodavca. Društvo također zadržava pravo izmjene
        sadržaja u smislu ispravljanja pravopisnih pogrešaka i strukture objavljenog sadržaja,
        sukladno Uvjetima korištenja.</p>
    </li>
    <li>
      <strong>Definicije</strong>
      <p>POSJETITELJ je svaka osoba koja putem interneta pristupi Aplikaciji u smislu ovih Uvjeta
        korištenja, bez prijave ili registracije, ili plaćanja bilo kakve naknade. Posjetitelj se
        može upoznati s Uvjetima korištenja i svrhom Aplikacije, te se može registrirati kao
        korisnik.</p>
      <p>POSLOPRIMAC je svaka fizička osoba koja koristi Poslovac radi pronalaska odgovarajućeg
        radnog mjesta u smislu važećeg Zakona o radu, odnosno pronalaska angažmana u smislu odredbi
        važećeg Zakona o obveznim odnosima o ugovoru o djelu, odnosno radi pronalaska volonterskog
        mjesta, mjesta za stručno osposobljavanje i sl.</p>
      <p>POSLODAVAC je svaka fizička i/ili pravna osoba koja koristi Poslovac u smislu odredbi
        važećeg Zakona o radu, bez obzira na vrstu ugovora o radu koji želi sklopiti, kao i svaka
        druga fizička i/ili pravna osoba koja koristi Poslovac radi pronalaska izvođača i/ili
        izvršitelja u smislu odredbi važećeg Zakona o obveznim odnosima o ugovoru o djelu, odnosno
        koja traži volontere, pripravnike, osoblje za stručno osposobljavanje i sl.</p>
      <p>RADNO MJESTO je općeniti naziv za svaku vrstu radnog mjesta, zapošljavanja, angažiranja,
        volontiranja, vježbeništva, pripravništva, stručnog osposobljavanja, stručnog
        osposobljavanja bez zasnivanja radnog odnosa i sl.</p>
      <p>KORIŠTENJE je svaka objava o otvorenom radnom mjestu ili bilo koji drugi angažman od strane
        Poslodavaca, odnosno činjenje dostupnim vlastitih osobnih podataka u cilju pronalaženja
        radnog mjesta ili drugog angažmana od strane Posloprimaca.</p>
      <p>OSOBNI PODATAK je u smislu ovih Uvjeta korištenja svaka informacija koja se odnosi na
        identificiranu fizičku osobu ili fizičku osobu koja se može identificirati, a koji osobni
        podaci su zaštićeni prema odredbama Opće uredbe o zaštiti podataka (eng. GDPR) i Zakona o
        provedbi Opće uredbe o zaštiti podataka.</p>
      <p>CJENIK je u smislu ovih Uvjeta korištenja cjenik usluga koje su dostupne na Aplikaciji.</p>
      <p>GDPR je naziv za Uredbu 2016/679 Europskog parlamenta i vijeća od 27. travnja 2016. godine
        o zaštiti pojedinaca u vezi s obradom osobnih podataka i o slobodnom kretanju takvih
        podataka te o stavljanju izvan snage Direktive 95/46/EZ (Opća uredba o zaštiti
        podataka).</p>
      <p>VODITELJ OBRADE je fizička ili pravna osoba koja određuje svrhu i sredstva obrade osobnih
        podataka.</p>
      <p>IZVRŠITELJ OBRADE je fizička ili pravna osoba koja obrađuje osobne podatke u ime voditelja
        obrade.</p>
    </li>
    <li>
      <strong>Poslodavci</strong>
      <p>U svrhu korištenja Aplikacije, Poslodavac je obvezan putem registracije izraditi svoj
        korisnički račun, pod uvjetima i na način iz ovih Uvjeta korištenja.</p>
      <p>Registrirani Poslodavci mogu u svako doba objavljivati oglase za Radna mjesta, naknadno ih
        mijenjati u dogovoru sa savjetnicima Aplikacije, pratiti statistiku posjećenosti objavljenih
        oglasa, pretraživati sažetke životopisa, te koristiti ostale napredne funkcije koje su
        instalirane ili će to tek biti.</p>
      <p>Poslodavac samostalno određuje sadržaj, kao i kategoriju u kojoj se sadržaj objavljuje.
        Sadržaj se objavljuje na Aplikaciji isključivo uz prethodno odobrenje Društva. Poslodavac
        ima isključivu odgovornost i snosi sve pravne posljedice koje mogu proizaći objavljivanjem
        njegovog sadržaja na Aplikaciji.</p>
      <p>Poslodavac garantira da je vlasnik odnosno korisnik svih autorskih i/ili srodnih prava na
        cjelokupnom sadržaju, kao i svim pojedinim dijelovima tog sadržaja, koji objavljuje na
        Aplikaciji. Za svako eventualno kršenje autorskog i/ili srodnog prava, kao i drugih prava
        intelektualnog vlasništva, odgovara isključivo Poslodavac.</p>
      <p>Poslodavac se obavezuje da će u razumnom vremenskom roku odgovarati na poruke i pozive
        Posloprimaca.</p>
      <p>Postavljanjem objave na Aplikaciju, Poslodavac bezuvjetno i neopozivo daje svoj pristanak
        Društvu da njegovu objavu promovira, oglašavanjem ili na drugi prikladan način prema ocjeni
        Društva.</p>
      <p>Poslodavac se obavezuje da će svoj korisnički račun na Aplikaciji koristiti isključivo u
        svrhu pronalaženja odgovarajućih zaposlenika za svoje potrebe. Poslodavac se obavezuje da
        neće spremati podatke iz aplikacije Poslovac na svoja računala korištenjem automatskih
        aplikacija. Poslodavac se obavezuje da neće davati odnosno dopuštati uvid u podatke, bilo
        direktno, bilo davanjem na upotrebu svog korisničkog imena i lozinke, trećim osobama.</p>
      <p>Ukoliko Poslodavac koristi svoj korisnički račun u bilo koje druge svrhe (npr. za promociju
        i prodaju raznih proizvoda, usluga i sl.), Društvo će svaki takav korisnički račun blokirati
        i ukloniti, te svaka takva zlouporaba korisničkog računa podliježe zakonskim propisima o
        pokretanju postupka radi naknade štete Društvu protiv štetnika.</p>
      <p>Usluga objavljivanja oglasa za slobodna Radna mjesta na aplikaciji Poslovac naplaćuje se u
        skladu s Cjenikom važećim u trenutku objavljivanja oglasa.</p>
    </li>
    <li>
      <strong>Posloprimci</strong>
      <p>U svrhu korištenja Aplikacije, Posloprimac je obvezan putem registracije izraditi svoj
        korisnički račun, pod uvjetima i na način iz ovih Uvjeta korištenja.</p>
      <p>Prilikom registracije Posloprimac samostalno bira lozinku, te snosi isključivu odgovornost
        za tajnost lozinke i korištenje Aplikacije putem pristupnih podataka. Posloprimac se
        obvezuje da neće koristiti tuđe pristupne podatke ili davati svoje korisničke podatke trećim
        osobama. Posloprimac može obavijestiti Društvo ukoliko posumnja na neovlašteno korištenje
        svojih pristupnih podataka.</p>
      <p>Za točnost i ispravnost unesenih podataka, odgovara isključivo Posloprimac koji je podatak
        unio. Točnost unosa podrazumijeva da podaci dolaze iz nekog vjerodostojnog izvora i/ili
        osobnog saznanja Posloprimca. Ispravnost podrazumijeva gramatičku i pravopisnu
        ispravnost.</p>
      <p>Posloprimac se može bez ikakve nadoknade, upoznati s objavama, njihovim trajanjem, opisom
        svakog pojedinačnog nepopunjenog radnog mjesta, nazivima pojedinačnih autora objava
        (Poslodavcima), kao i njihovim kontakt podacima i drugim sadržajem. Za vrijeme trajanja
        objave Posloprimac može preuzimati podatke o Poslodavcu, postavljati pitanja Poslodavcu, a
        sve u skladu sa dostupnim opcijama.</p>
      <p>Posloprimac se obvezuje svoj korisnički račun na Aplikaciji koristiti isključivo u svrhu
        traženja, pronalaska i informiranja o Radnim mjestima.</p>
      <p>Ukoliko Posloprimac koristi svoj korisnički račun u bilo koje druge svrhe (npr. za
        promociju i prodaju raznih proizvoda, usluga i sl.), Društvo će svaki takav korisnički račun
        blokirati i ukloniti, te svaka takva zlouporaba korisničkog računa podliježe zakonskim
        propisima o pokretanju postupka radi naknade štete Društvu protiv štetnika.</p>
    </li>
    <li>
      <strong>Odgovornost za točnost podataka i odgovornost za štetu</strong>
      <p>Društvo ne odgovara za, niti jamči točnost dostupnih osobnih podataka, kontakata,
        kvalifikacija, karakteristika niti bilo kojih drugih podataka koji su navedeni u korisničkim
        računima, životopisima, molbama ili bilo kojim drugim priloženim dokumentima Posloprimaca
        i/ili oglasima Poslodavaca.</p>
      <p>Društvo ne odgovara niti Poslodavcima, niti Posloprimcima za bilo koju vrstu štete te se u
        tom smislu korištenjem Aplikacije Poslodavci i Posloprimci odriču potraživati bilo kakvu
        štetu, imovinsku ili neimovinsku, koja bi im mogla biti nanesena zloupotrebom pogrešnih
        podataka od strane Poslodavaca, Posloprimaca ili pak trećih osoba.</p>
      <p>Društvo nije odgovorno za eventualnu privremenu nedostupnost Aplikacije, niti za djelomično
        ili potpuno nefunkcioniranje ili neispravno funkcioniranje istog. Društvo nije odgovorno za
        tehničke probleme koji mogu dovesti do kašnjenja i/ili pogrešne obrade elektronskih
        podataka. Za sve navedeno odgovorni su pružatelji internetskih i telekomunikacijskih
        usluga.</p>
      <p>Aplikacija može biti privremeno nedostupna ili dostupna u ograničenom obujmu, kao rezultat
        redovnog ili izvanrednog održavanja sustava ili u slučaju unapređenja sustava.</p>
      <p>Posloprimci i Poslodavci koji zlouporabljuju ili neovlašteno otkrivaju pristupne podatke za
        svoj korisnički račun trećim osobama koje za to nisu ovlaštene, odgovaraju za svu štetu koja
        je proizašla neovlaštenim pristupom korisničkom računu, bilo Društvu, bilo drugim pravnim
        ili fizičkim osobama čije su osobne podatke zloupotrijebili.</p>
    </li>
    <li>
      <strong>Autorska prava</strong>
      <p>Društvo ima za područje Republike Hrvatske isključivo autorsko pravo i prava intelektualnog
        vlasništva na Aplikaciji, kao i na svim pojedinim sastavnim elementima kao što su: ime,
        tekst, vizualni i audio elementi, vizualni identitet, podaci i baze podataka, programski kod
        i drugi elementi Aplikacije, kojih je autor Društvo.</p>
      <p>Neovlašteno korištenje bilo kojeg dijela Aplikacije, ili Aplikacije u cjelini, bez izričite
        prethodne pisane suglasnosti Društva kao nosioca isključivih autorskih prava, smatrat će se
        povredom autorskih prava Društva i podložno je pokretanju sudskih postupaka u skladu sa
        važećim propisima Republike Hrvatske.</p>
      <p>Aplikacija može sadržavati i elemente na kojima isključiva autorska i druga prava
        intelektualnog vlasništva imaju treće osobe, kao što su sadržaji korisnika Aplikacije,
        poslovnih partnera, oglašivača i besplatnog sadržaja. Treće osobe imaju isključivu
        odgovornost za sadržaj na kojem su nositelji tih prava, bez obzira na to što se takav
        sadržaj nalazi na Aplikaciji Društva.</p>
      <p>Postavljanjem sadržaja na Aplikaciju, korisnik je suglasan da isti postane vidljiv svakom
        posjetitelju Aplikacije, pod uvjetima i na način iz ovih Uvjeta korištenja. Prenošenje
        sadržaja ili dijela sadržaja s bilo kojeg dijela Aplikacije dozvoljeno je samo uz izričitu
        prethodnu pisanu suglasnost Društva, uz napomenu da je sadržaj preuzet s Aplikacije i uz
        naznaku odgovarajuće poveznice na kojem se preuzeti sadržaj nalazi. Društvo ima isključivu
        odgovornost samo za sadržaj na kojem ima autorska prava i/ili prava intelektualnog
        vlasništva.</p>
      <p>Svaki korisnik Aplikacije samostalno snosi odgovornost za sadržaj koji je njegovo autorsko
        djelo, odnosno za sadržaj koji je samostalno postavio i putem Aplikacije učinilo javno
        dostupnim.</p>
    </li>
    <li>
      <strong>Zaštita i obrada osobnih podataka</strong><strong />
      <p><strong>7.a. Objava oglasa za radna mjesta</strong></p>
      <p>U skladu s Općom uredbom o zaštiti podataka (eng. GDPR), pri objavi oglasa Poslodavaca na
        Aplikaciji, Društvo se smatra Izvršiteljem obrade podataka, a Poslodavac se smatra
        Voditeljem obrade podataka.</p>
      <p>Voditelj obrade podataka obvezan je prikupljati osobne podatake pri objavi oglasa na
        transparentan način te na temelju valjanih pravnih osnova (namjera zapošljavanja odnosno
        sklapanje ugovora o radu).</p>
      <p>Voditelj obrade dužan je prikupljati samo one podatke koji su primjereni, relavnatni i
        ograničeni na podatke koji su nužni za svrhu za koju se podaci prikupljaju.</p>
      <p>Voditelj obrade jamči Izvršitelju obrade da neće koristiti osobne podatke koji su
        prikupljeni putem stranice Poslovac u bilo koje druge svrhe osim u svrhu zapošljavanja.</p>
      <p>Voditelj obrade potvrđuje da je implementirao organizacijske i tehničke mjere koje će
        omogućiti kontrolu nad prikupljanjem podataka putem stranice Poslovac, te je odgovoran za
        preuzimanje podataka (prijava) i dodjeljivanje pristupnih podataka za svoj korisnički
        račun.</p>
      <p>Voditelj obrade se obvezuje u potpunosti surađivati sa Izvršiteljem obrade kako bi svakom
        Posloprimcu koji to zahtijeva omogućio ostvarenje prava koja su utvrđena propisima o zaštiti
        osobnih podataka (pravo pristupa, ispravka, prenosivosti, brisanja, ograničenja obrade i
        prigovora na obradu osobnih podataka).</p>
      <p>Voditelj obrade se obvezuje u slučaju kršenja propisa u pogledu obrade osobnih podataka u
        potpunosti surađivati sa Izvršiteljem na otklanjanju štete i suradnji sa regulatornim
        tjelima.</p>
      <p>Izvršitelj obrade se obvezuje provoditi obradu osobnih podataka (prikupljanje prijava na
        oglase Poslodavaca) isključivo u okviru usluge, u ime Voditelja obrade i u svrhu koja je
        određena međusobnom poslovnom suradnjom, evidentirati i čuvati sve procese obrade osobnih
        podataka koje provodi u ime i za račun Voditelja obrade do opoziva od strane Voditelja
        obrade.</p>
      <p>Izvršitelj obrade osigurava Voditelju obrade da pristup Osobnim podacima imaju samo oni
        njegovi zaposlenici i/ili druge osobe koje djeluju pod njegovim nadzorom a koje su na to
        ovlaštene temeljem propisa ili ugovornog odnosa te da pristup Osobnim podacima postoji samo
        u onoj mjeri u kojoj je to nužno za pružanje usluge.</p>
      <p>Izvršitelj obrade navodi kako je poduzeo sve potrebne organizacijske i tehničke mjere kako
        su propisane GDPR-om, a osobito redovite sustave kontrole, testiranja, cjelovitosti,
        dostupnosti i otpornosti IT sustava.<br/>
        Izvršitelj obrade će redovito procjenjivati učinkovitost tehničkih i organizacijskih mjera
        te ih kontinuirano usvršavati i unapređivati.<br/>
        Izvršitelj obrade i Voditelj obrade će se međusobno informirati o svakoj povredi osobnih
        podataka koja je relavantna unutar 24 sata od kada je šteta nastala.<br/>
        Izvršitelj obrade može obrađivati podatke Voditelja obrade smo u statističke i analitičke
        svrhe testiranja IT sustava i praćenje statistika prijava na oglas za posao.</p>
      <p><strong>7.b. Privola</strong></p>
      <p>Korištenjem Aplikacije Poslodavci koji su fizičke osobe i Posloprimci daju izričitu privolu
        Društvu za prikupljanje i obradu njihovih osobnih podataka koje učine dostupnim u okviru
        svojih korisničkih računa, objavom u životopisu, molbi ili bilo kojim drugim priloženim
        dokumentima.</p>
      <p>Posloprimci korištenjem Aplikacije daju izričitu privolu Poslodavcima za prikupljanje i
        obradu njihovih osobnih podataka. Slanjem prijave Posloprimac daje privolu za to da osobnim
        podacima navedenim u objavljenom životopisu, molbama za posao i drugim dokumentima,
        pristupaju Poslodavci kojima su dali privolu.</p>
      <p>Privola za prikupljanje i obradu osobnih podataka, a sukladno odredbama Opće uredbe o
        zaštiti podataka (eng. GDPR) i Zakona o provedbi Opće uredbe o zaštiti podataka daje se
        isključivo u svrhu prijavljivanja na oglase za Radna mjesta te u svrhu obavljanja svih
        ostalih radnji usko povezanih s oglašavanjem i prijavljivanjem na oglase za Radna mjesta, te
        se u drugu svrhu ne mogu upotrijebiti.</p>
      <p>Poslodavci koji su fizičke osobe i Posloprimci mogu se informirati o obradi svojih osobnih
        podataka, podnijeti prigovor na obradu svojih osobnih podataka ili mogu u bilo kojem
        trenutku povući privolu za obradu svojih osobnih podataka bez dodatnog obrazloženja slanjem
        opoziva privole pisanim putem na adresu: <strong>TAU ON-LINE d.o.o. (Službenik za zaštitu
          osobnih podataka), Strojarska cesta 20, 10000 Zagreb</strong>, na e-mail adresu: <a
          href="mailto:szop@moj-posao.net"><strong>szop@moj-posao.net</strong></a>, deaktiviranjem
        ili brisanjem svog korisničkog računa.</p>
      <p>Osobni podaci, ali i svi drugi podaci se mogu koristiti samo na način kako je to propisano
        u ovim Uvjetima korištenja. Korištenje podataka u bilo koje druge svrhe (kopiranje,
        prijepis, distribuiranje itd.) bez privole Društva, Posloprimca i Poslodavca fizičke osobe,
        a osobito za masovnu distribuciju priopćenja, promotivnih poruka i sličnih informacija, je
        strogo zabranjeno. Društvo će svaku aktivnost poduzetu protivno navedenoj zabrani prijaviti
        nadležnim tijelima uključujući, ali ne ograničavajući se na, Državno odvjetništvo Republike
        Hrvatske, Ministarstvo unutarnjih poslova, te Agenciju za zaštitu osobnih podataka.</p>
    </li>
    <li>
      <strong>Jurisdikcija i rješavanje sporova</strong>
      <p>Na sve pravne odnose koji nastanu između Društva, Poslodavaca i Posloprimaca, uz ove Uvjete
        korištenja primjenjuje se hrvatsko materijalno pravo izuzev odredbi koje se odnose na
        rješavanje sukoba zakona.</p>
      <p>Sve eventualne sporove će Društvo, Poslodavci i Posloprimci pokušati riješiti mirnim putem,
        a u slučaju pokretanja sudskih postupaka, isključivo je nadležan stvarno nadležni sud u
        Zagrebu.</p>
    </li>
    <li>
      <strong>Završne odredbe</strong>
      <p>Ovi Uvjeti korištenja stupaju na snagu od dana njihovog objavljivanja na web stranici
        Portala.</p>
      <p>Društvo zadržava pravo u bilo kojem trenutku izmijeniti i dopuniti ove Uvjete korištenja.
        Važećim Uvjetima korištenja smatrat će se verzija objavljena na web stranici <router-link
          to="/">www.poslovac.net</router-link>.</p>
      <p>Dajem privolu Društvu TAU ON-LINE d.o.o. za prikupljanje i obradu mojih osobnih podataka u
        skladu sa svrhom navedenom u ovim Uvjetima korištenja.</p>
      <p>Suglasan sam da se moji osobni podaci čuvaju do opoziva, zahtjeva za brisanjem ili
        povlačenjem suglasnosti.</p>
      <p>Upoznat sam sa svojim pravom da svaku sumnju na zlouporabu osobnih podataka prijavim
        pisanim putem na adresu: <strong>TAU ON-LINE d.o.o. (Službenik za zaštitu osobnih podataka),
          Strojarska cesta 20, 10000 Zagreb</strong> ili na e-mail adresu: <a
          href="mailto:szop@moj-posao.net"><strong>szop@moj-posao.net</strong></a>.</p>
      <p>Zagreb, 25. srpnja 2018.</p>
    </li>
  </div>
</template>

<script>
export default {
  name: 'TermsHR',
};
</script>
