/* eslint-disable class-methods-use-this */
import axios from '@/utils/axios';
import { getWorkExperienceResponseDataToModel, saveWorkExperienceModelToRequestData } from './service-helpers';

class WorkExperienceService {
  async getWorkExperience() {
    const response = await axios.get('/user/v2/exp_list/');
    return {
      data: getWorkExperienceResponseDataToModel(response.data),
      total: response.data.length,
    };
  }

  async saveWorkExperience(data) {
    const response = await axios.put(`/user/exp_detail/${data.id}/`, saveWorkExperienceModelToRequestData(data));
    if (response.status === 200) {
      return {
        success: true,
        message: '',
      };
    }

    return {
      success: false,
      message: '',
    };
  }

  async createWorkExperience(data) {
    const response = await axios.post('/user/v2/exp_create/', saveWorkExperienceModelToRequestData(data));
    if (response.status === 201) {
      return {
        success: true,
        message: '',
      };
    }

    return {
      success: false,
      message: '',
    };
  }

  async deleteWorkExperience(id) {
    const response = await axios.get(`/user/exp_delete?expId=${id}`);
    if (response.status === 200) {
      return {
        success: true,
        message: '',
      };
    }

    return {
      success: false,
      message: '',
    };
  }
}

export default new WorkExperienceService();
