<template>
  <div id="terms-of-service">
    <div class="choose-lang">
      <router-link to="/pravila-koristenja">HR</router-link >
      <span> | </span>
      <router-link to="/terms-of-service">EN</router-link >
    </div>
    <TermsEN v-if="$route.name === 'TermsOfServiceEN'" />
    <TermsHR v-else-if="$route.name === 'TermsOfServiceHR'" />
  </div>
</template>

<script>
import TermsHR from './TermsHR.vue';
import TermsEN from './TermsEN.vue';

export default {
  name: 'TermsOfService',
  components: { TermsHR, TermsEN },
};
</script>

<style lang="scss">
#terms-of-service {
  list-style: decimal;
  text-align: left;
  h1 {
    margin-top: 30px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 2.5rem;
    font-weight: bolder;
    line-height: 1.2;
  }
  li {
    font-weight: bold;
    > strong {
      display: inline-block;
      margin-bottom: 16px;
      font-weight: bolder;
    }
  }
  a { font-size: 16px; }
  p {
    margin-bottom: 35px;
    font-weight: normal;
  };
  span {
    margin: 0 0 10px 40px;
    display: block;
  }
  .choose-lang {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    font-size: 24px;
    a {
      font-size: 24px;
      font-weight: bold;
    }
    span { margin: 0 5px; }
  }
}
</style>
