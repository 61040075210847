/* eslint-disable import/prefer-default-export */
import store from '@/store';

export const allow = (...users) => (to, from, next) => {
  const isAuthorized = store.getters['session/isAuthorized'];
  if (isAuthorized) {
    const user = store.getters['session/user'];
    const currentUser = user.isEmployer ? 'employer' : 'employee';
    if (users.includes(currentUser)) {
      next();
    } else {
      next({ name: 'Home' });
    }
  } else {
    next({ name: 'Home' });
  }
};
