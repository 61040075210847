<template>
  <modal
    height="auto"
    :name="modalName"
    :adaptive="true"
    :pivotY="isMobile ? 0 : 0.5"
  >
    <div class="container">
      <h2 class="title">{{ title }}</h2>
      <p class="description">{{ description }}</p>
      <slot />
      <div class="controls">
        <Button
          icon="fas fa-times"
          theme="danger"
          title="Odustani"
          @click="close"
        />
        <Button
          title="OK"
          @click="confirm"
        />
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'ConfirmModal',
  props: {
    title: {
      type: String,
    },
    description: {
      type: String,
    },
    autoClose: {
      type: Boolean,
      default() {
        return true;
      },
    },
  },
  data() {
    return {
      modalName: `confirm-modal-${new Date().getTime()}`,
    };
  },
  methods: {
    open() {
      this.$modal.show(this.modalName);
    },
    close() {
      this.$modal.hide(this.modalName);
    },
    confirm() {
      this.$emit('confirm');
      if (this.autoClose) {
        this.$modal.hide(this.modalName);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 20px;
}
.title {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding: 20px 0;
}
.controls {
  display: flex;
  padding-top: 40px;
  justify-content: center;
}
</style>
