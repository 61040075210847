import { createStore } from '@/store/storeFactories';
import JobService from '@/services/job.service';

export default createStore({
  defaultFilterState: {
    userId: null,
    search: '',
    offset: 0,
    limit: 4,
  },
  defaultDetailsState: {
    company: {},
    location: {},
    expirationDate: new Date(),
    salary: {
      from: null,
      to: null,
    },
  },
  services: {
    getListService: JobService.getJobsForEmployer,
    getService: JobService.getJob,
    saveService: JobService.saveJob,
    createService: JobService.createJob,
    deleteService: JobService.deleteJob,
  },
});
