<template>
  <div class="container">
    <h2>PREGLED OGLASA</h2>
    <div class="job-preview">
      <header>
        <h2>NASLOV OGLASA</h2>
        <span>{{ data.title }}</span>
      </header>
      <section>
        <div>
          <h2>TVRTKA</h2>
          <div>{{ company }}</div>
        </div>
        <div>
          <h2>VRSTA OGLASA</h2>
          <div>{{ jobType.name }}</div>
        </div>
        <div>
          <h2>UKUPNA CIJENA</h2>
          <div>{{ (jobType.price * 1.25).toFixed(2) }} EUR</div>
        </div>
        <div>
          <p>
            Nakon predaje i uplate oglas će biti pregleda i autoriziran.
            Na email ćete dobiti informacije u vezi plaćanja.
          </p>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobPreview',
  props: {
    data: {
      type: Object,
      required: true,
    },
    companies: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    jobTypes: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  computed: {
    company() {
      if (this.data.companyID && this.companies.length > 0) {
        const company = this.companies.find(c => c.id === this.data.companyID);
        return company.name;
      }

      return null;
    },
    jobType() {
      if (this.data.typeID && this.jobTypes.length > 0) {
        const jobType = this.jobTypes.find(t => t.id === this.data.typeID);
        return jobType;
      }

      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  > h2 {
    font-weight: bold;
    color: rgb(192, 192, 192);
  }

  .job-preview {
    margin-top: 20px;
    border: 1px solid #c5c5c5;
    border-radius: 5px;
    h2 { font-weight: bold; }
    header {
      padding: 20px 30px;
      span {
        font-size: 25px;
        font-weight: bold;
        color: #26D35D;
      }
    }
    section {
      padding: 20px 30px;
      background-color: rgb(240, 240, 240);
      > div {
        margin: 20px 0px;
      }
    }
  }
}
</style>
