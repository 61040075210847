<template>
  <div class="container">
    <div calss="image-container">
    </div>
    <div class="salary-container">
      <h2>UNESITE RASPON PLAĆE (NEOBAVEZNO)</h2>
      <div class="salary-range">
        <div>
          <span>Od</span>
          <Input
            type="number"
            :placeholder="salaryFromPlaceHolder"
            v-model="validate.salary.from.$model"
            :errorMessage="salaryFromErrors"
          />
        </div>
        <div>
          <span>Do</span>
          <Input
            type="number"
            :placeholder="salaryToPlaceHolder"
            v-model="validate.salary.to.$model"
            :errorMessage="salaryToErrors"
          />
        </div>
      </div>
    </div>
    <div>
      <Input
        title="UNESITE IZDVOJENU POGODNOST (NEOBAVEZNO)"
        v-model="validate.specialBenefit.$model"
        :errorMessage="specialBenefitErrors"
        :placeholder="specialBenefitPlaceHolder"
      />
    </div>
    <div class="">
      <h2>NAČIN NA KOJI ŽELITE PRIMATI PRIJAVE:</h2>
      <selectables
        :options="applicationTypes"
        type="checkbox"
        v-model="validate.applicationTypes.$model"
      >
        <template v-slot:default="slotProps">
          <div class="selectable-option">
            <h3>{{ slotProps.item.name }}</h3>
            <input
              v-if="slotProps.item.id === 2 && validate.applicationTypes.$model.includes(2)"
              v-model="phoneNumber"
            />
          </div>
        </template>
      </selectables>
    </div>
  </div>
</template>

<script>

import Input from '@/components/basic/Input.vue';
import Selectables from './Selectables.vue';


export default {
  name: 'AdditionalInfo',
  components: {
    Selectables,
    Input,
  },
  props: {
    validate: {
      type: Object,
      required: true,
    },
    companies: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
  },
  computed: {

    phoneNumber: {
      set(val) {
        this.validate.phoneNumber.$model = val;
      },
      get() {
        return this.companies[0].phoneNumber;
      },
    },
    phoneNumber1() {
      if (this.companies[0].phoneNumber) return this.companies[0].phoneNumber;
      return 'Upisite tel. broj';
    },
    salaryFromErrors() {
      if (!this.validate.salary.from.$dirty) return null;
      if (!this.validate.salary.from.numeric) return 'Nije broj';
      if (!this.validate.salary.from.between) return 'Neispravan raspon';
      return null;
    },
    salaryToErrors() {
      if (!this.validate.salary.to.$dirty) return null;
      if (!this.validate.salary.to.numeric) return 'Nije broj';
      return null;
    },
    specialBenefitErrors() {
      if (!this.validate.specialBenefit.maxLength) return 'Unos mora imati 30 ili manje znakova';
      return null;
    },
    salaryFromPlaceHolder() {
      return '0';
    },
    salaryToPlaceHolder() {
      let val = 0;
      if (this.validate.salary.from.$model) {
        val = this.validate.salary.from.$model;
      }

      return `Min. ${val}`;
    },
    specialBenefitPlaceHolder() {
      return 'Npr. Rad od doma';
    },
  },
  data() {
    return {
      applicationTypes: [
        {
          id: 1,
          name: 'Prijava unutar aplikacije',
        },
        {
          id: 2,
          name: 'Telefonski poziv',
        },
      ],
      // phoneNumber: this.companies[0].phoneNumber,
    };
  },
};
</script>

<style lang="scss" scoped>
.container {
  h2 {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #393939;
  }
  .salary-container {
    .salary-range {
      display: flex;
      @media (max-width: 440px) {
        flex-direction: column;
      }
      > div {
        display: flex;
        align-items: center;
        margin-right: 50px;
        > span {
          display: block;
          margin-bottom: 30px;
          margin-right: 10px;
        }
      }
    }
  }
}
</style>
