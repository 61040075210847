<template>
  <div :class="['input-container', theme]">
    <h4>{{ title }}</h4>
    <label>
      <div v-if="leftIcon" class="before">
        <i :class="leftIcon" />
      </div>
      <input
        :style="{ paddingLeft: leftIcon ? '0' : '20px' }"
        v-bind="$attrs"
        :value="value"
        @input="$emit('input', $event.target.value)"
      />
      <div class="after"></div>
    </label>
    <error-message :message="errorMessage" />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  name: 'Input',
  props: {
    title: String,
    value: String,
    leftIcon: String,
    errorMessage: String,
    theme: {
      type: String,
      default() {
        return 'default';
      },
    },
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.default {
  label {
    background-color: white;
    border: 1px solid #e8e8e8;
  }
  h4 { color: #393939; }
  input {
    color: #777777;
    &:focus { outline: none; }
  }
}
.blue {
  label { background-color: #1C434A; }
  h4 { color: white; }
  input {
    color:#FFF;
    border-color: transparent;
    &:-webkit-autofill {
      box-shadow: inset 0 0 0 9999px #1C434A;
      -webkit-box-shadow: inset 0 0 0 9999px #1C434A;
      -webkit-text-fill-color: #FFF;
      border-width: 0;
    }
  }
  i { color: #909090; }
}
.gray {
  label { background-color: #F5F5F5; }
}
.input-container {
  margin-bottom: 10px;
  h4 {
    display: block;
    margin: 0 0 5px;
    text-align: left;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
  }
  label {
    display: flex;
    height: 60px;
    border-radius: 5px;
    overflow: hidden;
  }
  .before {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
    width: 70px;
  }
  input {
    height: 60px;
    width: 100%;
    padding: 0 20px;
    border: 0;
    border-image: initial;
    background: inherit;
    font-size: 19px;
  }
  .after {}
  i {
    font-size: 19px;
    opacity: .5;
  }
}
</style>
