<template>
  <div class="app">
    <toast />
    <header :class="{
      'transparent-header': $route.name === 'Home',
      'blue-background' : blueBackground && $route.name === 'Home',
    }">
      <NavigationBar />
    </header>
    <main>
      <app-container :ignoreLayoutWhenInRoutes="['Home', 'JobSearch']">
        <transition :name="isMobile ? 'slide-fade' : 'fade'">
          <router-view />
        </transition>
      </app-container>
    </main>
    <footer>
      <Footer />
    </footer>
    <modals />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Toast from '@/components/misc/Toast.vue';
import AppContainer from '@/components/layout/AppContainer.vue';
import NavigationBar from '@/components/misc/NavigationBar/NavigationBar.vue';
import Footer from '@/components/misc/Footer.vue';
import Modals from '@/Modals.vue';

export default {
  name: 'App',
  components: {
    Toast,
    NavigationBar,
    AppContainer,
    Footer,
    Modals,
  },
  data() {
    return {
      blueBackground: false,
    };
  },
  created() {
    this.initBranch();
    window.addEventListener('scroll', this.showBackground);
    this.showBackground();
  },
  destroyed() {
    window.removeEventListener('scroll', this.showBackground);
  },
  methods: {
    ...mapActions('session', ['mojPosaoLogin']),
    showBackground() {
      this.blueBackground = window.pageYOffset >= (window.innerWidth < 700 ? 100 : 250);
    },
    initBranch() {
      window.branch.init('key_live_oowvS8r8o4BAefdz1w6oxicgCxpupccw', (error, data) => {
        const { jobId } = data.data_parsed;
        if (jobId !== undefined) {
          this.$router.replace({ name: 'EmployeeJob', params: { id: jobId } });
        }
      });
    },
  },
  mounted() {
    if (this.$route.query.code) {
      this.mojPosaoLogin(this.$route.query.code);
    }
  },
};
</script>

<style lang="scss" scoped>
$duration: 250ms;
$mobile-duration: 300ms;
.fade-enter {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter-active {
  transition: all $duration ease $duration;
}
.fade-leave {
  opacity: 1;
}
.fade-leave-to {
  opacity: 0;
}
.fade-leave-active {
  transition: all $duration ease-out;
}

.slide-fade-enter {
  opacity: 0;
}
.slide-fade-enter-to {
  opacity: 1;
}
.slide-fade-enter-active {
  transition: all $mobile-duration ease $mobile-duration;
}
.slide-fade-leave {
  opacity: 1;
}
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(500px);
}
.slide-fade-leave-active {
  transition: all $mobile-duration ease-out;
}
</style>

<style lang="scss">
@import './reset.scss', './normalize.scss';

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  header {
    color: #333333;
    padding-bottom: 70px;
    z-index: 801;
    .to-home {
      > img {
        &:nth-of-type(2) {
          display: none;
        }
      }
    }
    .button {
      color: inherit!important;
    }
    .a {
      .button {
        color: #FFFFFF!important;
      }
    }
  }
  header.transparent-header {
    color: #FFFFFF;
    .navigation-bar {
      background: transparent;
      border-bottom: none;
      .hamburger-handle {
        svg g path {
          stroke: rgb(255, 255, 255);
        }
      }
      .to-home {
        > img {
          &:last-of-type {
            display: none;
          }
          &:nth-of-type(2) {
            display: block;
          }
        }
      }
    }
    &.blue-background {
      .navigation-bar {
        background: #153F47;
        transition: all 0.3s ease-in-out;
      }
    }
    @media (max-width: 700px) {
      .navigation-bar {
        .additional-options {
          background-color: #153F47;
          .option-button {
            color: #FFFFFF!important;
            border-color: #299BCB;
          }
        }
      }
    }
  }
  main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  footer {}
}
</style>
