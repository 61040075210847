<template>
  <div class="container">
    <div class="inner-container">
      <i class="far fa-check-circle" />
      <h3>Prijava je uspješno zaprimljena i proslijeđena poslodavcu!</h3>
      <p>Svoje prijave i detalje istih možeš pregledati u aplikaciji</p>
      <a href="https://play.google.com/store/apps/details?id=net.poslovac"
        @click.prevent="openGooglePlayStoreInNewTab"
      >Poslovac</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'JobApplicationThankYou',
  methods: {
    openGooglePlayStoreInNewTab() {
      const win = window.open('https://play.google.com/store/apps/details?id=net.poslovac');
      win.focus();
    },
  },
};
</script>

<style lang="scss" scoped>
  .container {
    display: flex;
    flex-direction: column;
    h1 {
      margin: -8px 0 20px;
      padding: 0;
      font-size: 40px;
      line-height: 48px;
      font-weight: bold;
      text-align: left;
    }
    .inner-container {
      display: flex;
      flex: 1;
      margin-top: 20px;
      padding: 25px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: #FFFFFF;
      border: solid 1px #EAEAEA;
      border-radius: 5px;
      text-align: center;
      i {
        margin-top: 20px;
        color: #26D35D;
        font-size: 120px;
      }
      h3 {
        max-width: 500px;
        margin: 50px 0;
        font-size: 34px;
        line-height: 41px;
        color: #333333;
      }
    }
  }
  @media (max-width: 1000px) {
    .container {
      padding: 0 20px;
      .inner-container {
        h3 {
          margin-bottom: 25px;
        }
      }
    }
  }
</style>
