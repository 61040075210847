<template>
  <form @submit.prevent="submit">
    <div class="login-buttons">
      <!-- MP login turned off currently -->
      <div v-if="false" class="button login-mp" @click="mpLogin">
        <img src="@/assets/images/logos/MPlogin.svg" />
      </div>
      <div class="button login-fb" @click="fbLogin">
        <img src="@/assets/images/logos/FBlogin.svg" />
      </div>
    </div>
    <div class="separator">
      <span class="separator-text">ILI</span>
      <div class="line"/>
    </div>
    <div class="login-inputs">
      <Input
        title="E-mail adresa"
        type="text"
        theme="blue"
        leftIcon="far fa-envelope"
        v-model.trim="$v.form.email.$model"
        :errorMessage="emailErrors"
      />
      <div class="forgotten-password">
        <a target="_blank" href="https://backend.poslovac.net/accounts/password/reset/" >
          Zaboravljena lozinka?
        </a>
      </div>
      <Input
        title="Lozinka"
        type="password"
        theme="blue"
        leftIcon="fas fa-lock"
        v-model="$v.form.password.$model"
        :errorMessage="passwordErrors"
      />
    </div>
    <div class="invalid-credentials">
      {{ serverMessage }}
    </div>
    <div class="bottom-box">
      <div class="login-submit">
        <Button
          title="Prijavi me"
          :loading="loginInProgress"
          type="submit"
        />
      </div>
      <div class="to-registration">
        <span>Nemaš profil?</span>
        <a @click.prevent="$emit('gotoRegistration')">Registriraj se!</a>
      </div>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { required, email, minLength } from 'vuelidate/lib/validators';
import config from '@/config';

export default {
  name: 'Login',
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
    };
  },
  validations: {
    form: {
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
    },
  },
  computed: {
    ...mapGetters('session', ['isAuthorized']),
    ...mapState('session', {
      success: state => state.login.success,
      loginInProgress: state => state.login.inProgress,
      serverMessage: state => state.login.message,
    }),
    emailErrors() {
      if (!this.$v.form.email.$dirty) return null;
      if (!this.$v.form.email.required) return 'Obavezan unos';
      if (!this.$v.form.email.email) return 'Nije Email';
      return null;
    },
    passwordErrors() {
      if (!this.$v.form.password.$dirty) return null;
      if (!this.$v.form.password.required) return 'Obavezan unos';
      if (!this.$v.form.password.minLength) return 'Minimalno 6 znakova';
      return null;
    },
  },
  methods: {
    ...mapActions('session', ['login', 'facebookLogin', 'mojPosaoLogin']),
    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        await this.login({ email: this.form.email, password: this.form.password });
        if (this.success) {
          this.$emit('success');
        }
      }
    },
    async fbLogin() {
      await this.facebookLogin();
      if (this.success) {
        this.$emit('success');
      }
    },
    async mpLogin() {
      window.location.href = config.mpLoginUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.forgotten-password {
  display: block;
  position: relative;
  top: 18px;
  right: 10px;
  text-align: right;
  font-size: 15px;
  cursor: pointer;
  z-index: 10;
  a {
    color: #2AFF70;
    &:hover {
      color:#00FF54;
    }
  }
}
form {
  display: flex;
  flex-direction: column;
  flex: 1;
  .login-buttons {
    width: 100%;
    .button {
      width: 100%;
      display: flex;
      justify-content: center;
      border: 0;
      padding: 15px;
      margin-bottom: 18px;
      color: white;
      border-radius: 5px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      font-size: 1.1em;
      cursor: pointer;
      &.login-mp {
        background-color: #D51B1B;
        img {
          max-height: 26px;
          max-width: 140px;
          position: relative;
          top: 3px;
        }
      }
      &.login-fb {
        text-align: center;
        background-color: #485894;
        img {
          max-height: 21px;
          max-width: 110px;
        }
      }
    }
  }
  .separator {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 40px 0 25px 0;

    .separator-text {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      height: 38px;
      width: 38px;
      background-color: #B1E9F5;
      color: #236C7B;
      font-size: 15px;
      font-weight: bold;
      z-index: 99;
    }
    .line {
      position: absolute;
      top: 19px;
      width: 100%;
      height: 1px;
      background-color: #B1E9F5;
    }
  }
  .bottom-box {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex: 1;
    margin-top: 50px;

    .to-registration {
      display: flex;
      justify-content: center;
      margin-top: 38px;
      color: #fff;
      a {
        margin-left: 5px;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        color: #2AFF70;
        &:hover {
          color: #00FF54;
        }
      }
    }
  }
  .invalid-credentials {
    text-align: center;
    color: rgb(234, 86, 0);
  }
}
</style>
