<template>
  <master-detail>
    <template v-slot:master>
      <h3>Moji oglasi</h3>
      <list
        :data="jobs"
        :total="jobsCount"
        :limit="4"
        :offset="jobsFilter.offset"
        :isLoading="jobsLoading"
        :isLoadingItem="loadingJob"
        @select="showJob"
        @pageChange="changePage"
      >
        <template v-slot:default="slotProps">
          <job-list-item
            :isEmployer="true"
            :data="slotProps.item"
            :isSelected="slotProps.isSelected"
          />
        </template>
      </list>
    </template>
    <template v-slot:detail>
      <published-job-details
        :jobId="selectedJobId"
        @delete="deleteJob"
      />
    </template>
  </master-detail>
</template>

<script>
import { mapState } from 'vuex';
import LoadingBarMixin from '@/mixins/loadingBar.mixin';
import MasterDetail from '@/components/layout/MasterDetail.vue';
import JobListItem from '@/components/shared/JobListItem.vue';
import PublishedJobDetails from './PublishedJobDetails.vue';

export default {
  name: 'PublishedJobs',
  mixins: [LoadingBarMixin],
  components: {
    MasterDetail,
    JobListItem,
    PublishedJobDetails,
  },
  computed: {
    ...mapState('employer/jobs', {
      jobsFilter: state => state.list.filter,
      jobs: state => state.list.data,
      jobsCount: state => state.list.total,
      jobsLoading: state => state.list.inProgress,
      loadingJob: state => state.details.load.inProgress,
      selectedJobId: state => state.details.data.id,
      deleteInProgress: state => state.details.delete.inProgress,
      deleteSuccess: state => state.details.delete.success,
    }),
  },
  methods: {
    showJob(job) {
      if (this.isMobile) {
        this.$router.push({ name: 'PublishedJob', params: { id: job.id } });
      } else {
        this.$store.dispatch('employer/jobs/set', { id: job.id });
      }
    },
    search(filter) {
      this.dispatchWithLoadingBar('employer/jobs/getList', {
        userId: this.$store.getters['session/user'].id,
        ...filter,
      });
    },
    changePage(page) {
      const offset = (page - 1) * 4;
      this.search({ offset });
    },
    deleteJob(jobId) {
      this.$store.dispatch('employer/jobs/delete', jobId)
        .then(() => {
          if (this.deleteSuccess) {
            this.$store.dispatch('application/ui/showToast', { message: 'Oglas uspješno izbrisan' });
            this.$store.dispatch('employer/jobs/clear');
            this.search();
          }
        });
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<style lang="scss" scoped>
.job-details-container {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  h1 { margin-bottom: 25px; }
}
h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #393939;
  opacity: 0.33;
  text-align: left;
  text-transform: uppercase;
}
</style>
