<template>
  <div class="container" :class="{ 'container': true, 'selected': isSelected }">
    <h1>{{ data.user.firstName }}</h1>
    <p class="application-time">
      <i class="fas fa-clock"></i>
      {{ format(data.applicationDate, 'HH:mm - dd.MM.yyyy.') }}
    </p>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  name: 'JobApplicationListItem',
  props: {
    isSelected: {
      type: Boolean,
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: { format },
};
</script>

<style lang="scss" scoped>
.container.selected {
  background: white;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 80px;
  padding: 15px 25px;
  border-top: solid 1px #E8E8E8;
  background-color: #EFEFEF;
  h1 {
    font-size: 20px;
    text-align: left;
  }
  p {
    text-align: left;
    color: #8A8A8A;
    i {
      color: #26D35D;
      padding-right: 6px;
    }
  }
}
</style>
