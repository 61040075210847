import jwtDecoder from 'jwt-decode';
import axios from '@/utils/axios';

const TOKEN_KEY = '__TOKEN__';

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
  setToken(state, token) {
    window.localStorage.setItem(TOKEN_KEY, token);
    state.token = token;
  },
  clearToken(state) {
    window.localStorage.removeItem(TOKEN_KEY);
    state.token = null;
  },
  setLoginStatus(state, {
    inProgress = false,
    success = false,
    message = null,
    hasError = false,
  }) {
    state.login.inProgress = inProgress;
    state.login.hasError = hasError;
    state.login.success = success;
    state.login.message = message;
  },
  setRegistrationStatus(state, {
    inProgress = false,
    success = false,
    message = null,
    hasError = false,
  }) {
    state.registration.inProgress = inProgress;
    state.registration.success = success;
    state.registration.message = message;
    state.registration.hasError = hasError;
  },
};

const getters = {
  isAuthorized: state => state.token !== null,
  user: state => state.user,
};

const state = {
  user: {},
  token: null,
  registration: {
    inProgress: false,
    success: false,
    message: null,
    hasError: false,
  },
  login: {
    inProgress: false,
    success: false,
    message: null,
    hasError: false,
  },
};

const actions = {
  /**
   * Authorize user and load required data
   */
  async authorize({ commit, dispatch }, { token = localStorage.getItem(TOKEN_KEY) }) {
    dispatch('shared/industries/getList', null, { root: true });
    dispatch('shared/positions/getList', null, { root: true });

    if (token) {
      const decodedToken = jwtDecoder(token);
      const user = {
        id: decodedToken.user_id,
        email: decodedToken.email,
        avatarUrl: decodedToken.avatar_url,
        isEmployer: decodedToken.is_employer,
        similarCompanyFound: decodedToken.similar_company_found,
      };

      commit('setToken', token);
      commit('setUser', user);
      await dispatch('employee/profile/get', null, { root: true });

      if (user.isEmployer) {
        // We have to fetch list of companies because some APIs require us
        // to deliver company ID in request
        await Promise.all([
          dispatch('employer/companies/getList', null, { root: true }),
          dispatch('employer/jobTypes/getList', null, { root: true }),
        ]);
      }
    } else if (process.env.NODE_ENV === 'development') {
      // console.error('USER NOT AUTHORIZED');
    }
  },
  /**
   * Unautohirze user
   */
  unauthorize({ commit }) {
    commit('clearToken');
  },
  /**
   * Logs in user
   */
  async login({ commit, dispatch }, { email, password }) {
    if (state.login.inProgress) return;

    try {
      commit('setLoginStatus', { inProgress: true });
      const response = await axios.post('/v2/authentication/login/', { username: email, password });
      if (response.status === 200) {
        const { data: { token } } = response;
        await dispatch('authorize', { token });
        commit('setLoginStatus', { success: true });
      } else {
        commit('setLoginStatus', {
          success: false,
          message: 'Neispravni podaci',
        });
      }
    } catch (error) {
      commit('setLoginStatus', { hasError: true });
    }
  },
  async logout({ dispatch }) {
    await dispatch('unauthorize');
    dispatch('router/push', { name: 'Home' }, { root: true });
  },
  /**
   * Login via Facebook
   */
  async facebookLogin({ commit, dispatch }) {
    await new Promise((resolve) => {
      window.FB.login(async (fbResponse) => {
        if (fbResponse.status === 'connected') {
          const jsonPaylod = { access_token: fbResponse.authResponse.accessToken };

          if (process.env.NODE_ENV === 'development') {
            // console.log('FB LOGIN WITH ACCESS TOKEN: ', fbResponse.authResponse.accessToken);
          }

          try {
            const response = await axios.post('/v2/authentication/facebook/', jsonPaylod);

            if (response.status === 200) {
              await dispatch('authorize', { token: response.data.token });
              commit('setLoginStatus', { success: true });
            } else if (response.status === 404) {
              commit('setLoginStatus', {
                success: false,
                message: 'Nepostojeći korisnik',
              });
            } else {
              // console.error('Unknown poslovac-facebook status');
            }
          } catch (error) {
            // console.error(error);
            // User has probably canceled the request
          }
        } else {
          // The person is not logged into this app or we are unable to tell.
          // console.error('Unknown status');
        }
        resolve();
      }, { scope: 'email', return_scopes: true });
    });
  },
  async mojPosaoLogin({ commit, dispatch }, token) {
    try {
      const response = await axios.post('/v2/authentication/mojposao/', { access_token: token });
      if (response.status === 200) {
        await dispatch('authorize', { token: response.data.token });
        commit('setLoginStatus', { success: true });
        await dispatch('router/clearQuery', null, { root: true });
      } else {
        // console.error('CANNOT LOGIN');
      }
    } catch (error) {
      commit('setLoginStatus', { hasError: true });
    }
  },
  /**
   * Registers user as Employee or Employer
   */
  async register({ commit, dispatch }, { user, isEmployer }) {
    if (state.registration.inProgress) return;

    try {
      commit('setRegistrationStatus', { inProgress: true });
      const payload = {
        first_name: user.firstName,
        last_name: user.lastName,
        password: user.password,
        email: user.email,
        positions: user.positions,
        is_employer: isEmployer,
        phone_number: user.phone,
        companies: user.company && [{
          name: user.company.name,
          oib: user.company.oib,
          e_mail: user.company.email,
          // Phone number is the same as for user
          phone_number: user.phone,
          industries: user.company.industry && [user.company.industry.id],
          address: user.company.location.place,
          point: {
            latitude: user.company.location.lat,
            longitude: user.company.location.lng,
          },
        }],
      };

      const response = await axios.post('/v2/authentication/register/', payload);
      if (response.status === 201) {
        commit('setRegistrationStatus', { success: true });
        const { data: { token } } = response;
        await dispatch('authorize', { token });
        commit('setRegistrationStatus', { success: true });
      } else if (response.status === 409) {
        commit('setRegistrationStatus', {
          success: false,
          message: 'Korisnik je već registriran',
        });
      } else {
        commit('setRegistrationStatus', {
          success: false,
          message: 'Provjerite sve podatke',
        });
      }
    } catch (error) {
      commit('setRegistrationStatus', { hasError: true });
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
