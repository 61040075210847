<template>
  <form @submit.prevent="submit">
    <tab-view :activeTab="activeTab" @change="selectTab">
      <template #tabs>
        <span>KORAK 1</span>
        <span>KORAK 2</span>
        <span>KORAK 3</span>
        <span>KORAK 4</span>
        <span>KORAK 5</span>
        <span>KORAK 6</span>
      </template>
      <template #default>
        <select-company
          :data="companies"
          :validate="$v.form"
        />
        <select-type
          :data="jobTypes"
          :validate="$v.form"
        />
        <basic-info :validate="$v.form" />
        <location :validate="$v.form" />
        <additional-info
          :validate="$v.form"
          :companies="companies"/>
        <job-preview
          :data="form"
          :companies="companies"
          :jobTypes="jobTypes"
        />
      </template>
    </tab-view>
    <div class="steps-container">
      <div class="controls">
        <Button
          theme="gray-100"
          icon="fas fa-chevron-left"
          @click="prevTab"
        />
        <Button
          v-if="activeTab === 5"
          theme="blue"
          title="Završi"
          :loading="jobSaving || jobCreating"
          @click="submit"
        />
        <Button
          v-else
          theme="blue"
          title="Dalje"
          @click="nextTab"
        />
      </div>
      <p class="position">{{ activeTab + 1 }}/6</p>
    </div>
  </form>
</template>

<script>
import {
  required, numeric,
  minValue, between,
  maxLength,
} from 'vuelidate/lib/validators';
import { mapState } from 'vuex';
import TabView from './TabView.vue';
import SelectCompany from './SelectCompany.vue';
import SelectType from './SelectType.vue';
import BasicInfo from './BasicInfo.vue';
import Location from './Location.vue';
import AdditionalInfo from './AdditionalInfo.vue';
import JobPreview from './JobPreview.vue';

export default {
  name: 'JobForm',
  components: {
    TabView,
    SelectCompany,
    SelectType,
    BasicInfo,
    Location,
    AdditionalInfo,
    JobPreview,
  },
  computed: {
    ...mapState('employer/jobTypes', {
      jobTypes: state => state.list.data,
    }),
    ...mapState('employer/companies', {
      companies: state => state.list.data,
    }),
    ...mapState('employer/jobs', {
      jobSaving: state => state.details.save.inProgress,
      jobSavingSuccess: state => state.details.save.success,
      jobSavingMessage: state => state.details.save.message,
      jobCreating: state => state.details.create.inProgress,
      jobCreatingSuccess: state => state.details.create.success,
      jobCreatingMessage: state => state.details.create.message,
      job: state => state.details.data,
    }),
  },
  data() {
    return {
      activeTab: 0,
      form: {
        id: null,
        companyID: null,
        typeID: 1,
        title: null,
        description: null,
        location: {
          lat: 45.8150,
          lng: 15.9819,
          place: null,
        },
        imageUrl: '',
        salary: { from: undefined, to: undefined },
        specialBenefit: '',
        applicationTypes: [1],
        phoneNumber: '',
      },
    };
  },
  validations() {
    return {
      form: {
        companyID: {
          required,
        },
        typeID: {
          required,
        },
        title: {
          required,
        },
        description: {
          required,
        },
        location: {
          required,
        },
        salary: {
          from: {
            numeric,
            minValue: minValue(0),
            between: between(0, this.form.salary.to),
          },
          to: {
            numeric,
            minValue: minValue(0),
          },
        },
        specialBenefit: {
          maxLength: maxLength(30),
        },
        applicationTypes: {
          required,
        },
        phoneNumber: {
        },
      },
    };
  },
  methods: {
    selectTab(index) {
      this.activeTab = index;
    },
    nextTab() {
      if (this.activeTab <= 5) {
        this.activeTab += 1;
      }
    },
    prevTab() {
      if (this.activeTab > 0) {
        this.activeTab -= 1;
      }
    },
    saveJob(job) {
      this.$store.dispatch('employer/jobs/save', job)
        .then(() => {
          if (this.jobSavingSuccess) {
            this.$router.push({ name: 'PublishedJobs' });
            this.$store.dispatch('application/ui/showToast', { message: 'Oglas uspješno ažuriran' });
          } else {
            this.$store.dispatch('application/ui/showToast', {
              type: 'warning',
              message: this.jobSavingMessage,
            });
          }
        });
    },
    createJob(job) {
      this.$store.dispatch('employer/jobs/create', job)
        .then(() => {
          if (this.jobCreatingSuccess) {
            this.$router.push({ name: 'JobCreated' });
          } else {
            this.$store.dispatch('application/ui/showToast', {
              type: 'warning',
              message: this.jobCreatingMessage,
            });
          }
        });
    },
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const { form } = this;
        const job = {
          id: form.id,
          typeID: form.typeID,
          companyID: form.companyID,
          title: form.title,
          description: form.description,
          location: form.location,
          salary: form.salary,
          applicationTypes: form.applicationTypes,
          phoneNumber: form.phoneNumber,
          specialBenefit: form.specialBenefit,
        };

        if (job.id) {
          this.saveJob(job);
        } else {
          this.createJob(job);
        }
      } else {
        this.activeTab = 2;
      }
    },
    setFormData(job) {
      this.form = {
        id: job.id,
        typeID: job.typeID,
        companyID: job.company.id,
        title: job.title,
        description: job.description,
        location: {
          lat: job.location.lat,
          lng: job.location.lng,
        },
        imageUrl: '',
        salary: {
          from: Number(job.salary.from),
          to: Number(job.salary.to),
        },
        specialBenefit: job.specialBenefit,
        applicationTypes: job.applicationTypes,
      };
    },
    initializeForm(id, mode) {
      if (id) {
        this.$store.dispatch('employer/jobs/select', this.$route.params.id)
          .then(() => {
            const { job } = this;
            job.id = mode === 'edit' ? job.id : null;
            this.setFormData(job);
          });
      } else {
        // Select first compmany
        const [company] = this.companies;
        this.form.companyID = company.id;
        this.form.companyPhone = company.phoneNumber;
      }
    },
  },
  mounted() {
    const { id, mode } = this.$route.params;
    this.initializeForm(id, mode);
  },
};
</script>

<style lang="scss" scoped>
.container {
  min-height: 350px;
}
.steps-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 50px;
  .controls {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 520px;
  }
  button {
    margin: 0!important;
    border-color: transparent!important;
    &:hover {
      border-color: transparent!important;
    }
    &.back-button {
      background-color: #E1E1E1!important;
      i {
        color: #B7B7B7!important;
      }
      &:hover {
        background-color: #DCDCDC!important;
      }
    }
  }
  .position {
    margin-top: 15px;
    font-size: 19px;
    color: #8A8A8A;
    text-align: center;
  }
}
</style>
