<template>
  <div class="container">
    <published-job-details :jobId="jobId" />
  </div>
</template>

<script>
import PublishedJobDetails from '@/views/employer/PublishedJobs/PublishedJobDetails.vue';

export default {
  name: 'EmployerJob',
  components: {
    PublishedJobDetails,
  },
  data() {
    return {
      jobId: null,
    };
  },
  mounted() {
    if (this.$route.params.id) {
      this.jobId = this.$route.params.id;
    }
  },
};
</script>
