<template>
  <div class="inner-container">
    <div class="status-wrapper">
      <h4>Status oglasa</h4>
      <div v-if="status === 'created'" class="inner-wrapper created">
        <i class="fas fa-hourglass-end"></i>
        <span>Čekanje na autorizaciju</span>
      </div>
      <div v-if="status === 'active'" class="inner-wrapper active">
        <i class="fas fa-check-circle"></i>
        <span>Aktivan</span>
      </div>
      <div v-if="status === 'expired'" class="inner-wrapper expired">
        <i class="fas fa-check-circle"></i>
        <span>Oglas je završio</span>
      </div>
    </div>
    <div
      @click="goToJobApplications"
      class="applications-wrapper"
    >
      <h4>Prijave</h4>
      <div class="inner-wrapper">
        <i class="fas fa-users"></i>
        <span>{{ data.jobApplicationsCount }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AdControls',
  props: {
    data: Object,
    status: String,
  },
  methods: {
    goToJobApplications() {
      if (this.data.jobApplicationsCount > 0) {
        this.$router.push({ name: 'JobApplications', params: { id: this.data.id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.inner-container {
  display: flex;
  border-radius: 5px;
  border: solid 1px #F1F1F1;
  padding: 25px;
  margin-bottom: 40px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }
  i {
    font-size: 20px;
    padding-right: 12px;
  }
  span {
    font-size: 25px;
    color: #333333;
  }
  .inner-wrapper {
    display: flex;
    align-items: center;
    &.created {
      i {
        color: #E5DC9F;
      }
    }
    &.active {
      i {
        color: #26D35D;
      }
    }
    &.expired {
      i {
        color: #DCDCDC;
      }
    }
  }
  .applications-wrapper {
    margin-left: 60px;
    cursor: pointer;
    @media (max-width: 1000px) {
      margin: 15px 0 0 0;
    }
    i {
      color: #26D35D;
      transform-origin: center;
      transition: all 200ms ease-in-out;
    }
    &:hover {
      i {
        transform: scale(1.15);
      }
    }
  }
}
</style>
