<template>
  <job-application-details :userId="userId" />
</template>

<script>
import JobApplicationDetails from '@/views/employer/JobApplications/JobApplicationDetail.vue';

export default {
  name: 'JobApplication',
  components: {
    JobApplicationDetails,
  },
  data() {
    return {
      userId: null,
    };
  },
  mounted() {
    if (this.$route.params.userId) {
      this.userId = this.$route.params.userId;
    }
  },
};
</script>
