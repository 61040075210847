/* eslint-disable class-methods-use-this */
import axios from '@/utils/axios';
import { getJobApplicationResponseDataToModel, getJobApplicationsResponseDataToModel } from './service-helpers';

class JobApplicationService {
  async getJobApplication(jobApp) {
    const [profileResponse] = await Promise.all([
      axios.get(`/profile/user/${jobApp.user.id}/`),
    ]);

    return getJobApplicationResponseDataToModel(jobApp, profileResponse, profileResponse.data.workExperiences);
  }

  async getJobApplications({ jobId }) {
    const response = await axios.get(`/recruits/list/${jobId}/`);
    return {
      data: getJobApplicationsResponseDataToModel(response.data),
      total: response.data.length,
    };
  }

  async createJobApplication(jobId) {
    const payload = { job_id: jobId, questions: [] };
    const response = await axios.post('/v2/job_applications/', payload);
    if (response.status === 201) {
      return {
        success: true,
        message: '',
      };
    }

    return {
      success: false,
      message: 'Korisnik je već prijavljen',
    };
  }

  async createJobPhoneApplication(jobId) {
    // Source 1 indicates poslovac web client made the request
    // 1 is the id for poslovac web client as application source
    const payload = { application_source: 1 };
    const response = await axios.post(`v3/jobs/${jobId}/phonecall/`, payload);
    // we are doing this in background so nothing needs to be shown to the user
    return response;
  }

  async createExternalLinkApplication(jobId) {
    const response = await axios.post(`v3/jobs/external-application/${jobId}`);
    // we are doing this in background so nothing needs to be shown to the user
    return response;
  }
}

export default new JobApplicationService();
