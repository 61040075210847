import positions from './positions.store';
import industries from './industries.store';

export default {
  namespaced: true,
  modules: {
    positions,
    industries,
  },
};
