<template>
  <ul class="container">
    <li v-for="item in data" :key="item.id">
      <div class="work-experience">
        <h6>{{ item.position.title }}</h6>
        <p>{{ item.companyTitle }}</p>
        <p>
          {{ format(item.startDate, 'MM.yyyy') }} - {{ format(item.endDate, 'MM.yyyy') }}
          ({{ workDuration(item.startDate, item.endDate) }})
        </p>
        <p class="description">{{ item.description }}</p>
      </div>
    </li>
  </ul>
</template>

<script>
import { format } from 'date-fns';
import { differenceInYearAndMonthsStr } from '@/utils/date';

export default {
  name: 'WorkExperienceList',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    format,
    workDuration: differenceInYearAndMonthsStr,
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #F5F5F5;
  li {
    padding-top: 25px;
    &:first-of-type {
      padding-top: 0;
    }
    h6 {
      margin-bottom: 8px;
      font-size: 19px;
      font-weight: bold;
      color: #54B454;
    }
    p {
      color: #BBBBBB;
      &.description {
        margin-top: 15px;
        color: #777777
      }
    }
  }
}
</style>
