<template>
  <div class="container">
    <transition name="fade">
      <div v-if="isLoading && data.length === 0" class="loading">
        <i class="fas fa-circle-notch fa-spin" />
      </div>
      <div v-else-if="total === 0" class="empty">
        <i class="fas fa-clipboard-list" />
        <h1>Nema rezultata</h1>
        <p>Pokušajte promjeniti parametre pretrage<p/>
      </div>
      <div v-else class="list-container">
        <ul class="list">
          <li
            v-for="item in data"
            v-bind:key="item.id"
            @click="select(item)"
          >
            <slot
              :item="item"
              :isSelected="selectedItemId === item.id"
            />
            <div
              v-if="isLoadingItem && selectedItemId === item.id"
              class="loading-bar"
            />
          </li>
        </ul>
        <paginate
          v-model="page"
          :page-count="Math.ceil(total/limit)"
          :click-handler="changePage"
          prev-text="<"
          next-text=">"
        />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'List',
  props: {
    total: {
      type: Number,
    },
    limit: {
      type: Number,
      default: 4,
    },
    offset: {
      type: Number,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
    isLoadingItem: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isLoading: {
      type: Boolean,
      default() {
        return true;
      },
    },
    selectedId: {
      type: [Number, String],
    },
  },
  methods: {
    select(item) {
      this.selectedItemId = item.id;
      this.$emit('select', item);
    },
    changePage(page) {
      this.$emit('pageChange', page);
    },
  },
  data() {
    return {
      selectedItemId: this.selectedId,
    };
  },
  computed: {
    page() {
      return this.offset / this.limit + 1;
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes widthKeyframes {
  0%, 100% { transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85); }
  0% { width: 0; }
  100% { width: 100%; }
}
$duration: 2000ms;
.fade-enter { opacity: 0; }
.fade-enter-to { opacity: 1; }
.fade-enter-active { transition: opacity $duration ease-out; }

.fade-leave { opacity: 1; }
.fade-leave-to { opacity: 0; }
.fade-leave-active { transition: opacity $duration ease-out; }

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.list-container {
  .list {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: solid 1px #E8E8E8;
    & > li {
      position: relative;
      border-top: solid 1px #E8E8E8;
      cursor: pointer;
      &:first-child { border-top: none; }
    }
  }
}
.loading {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background: #F1F1F1;
  i {
    font-size: 50px;
    color: white;
  }
}
.empty {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #F1F1F1;
  color: gray;
  i { font-size: 100px; }
  h1 {
    margin-top: 20px;
    font-size: 20px;
  }
}
.loading-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 3px;
  background: #54B454;
  animation: widthKeyframes 300ms;
}
</style>
