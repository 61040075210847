<template>
  <div class="job-detail-container" v-if="data.id">
    <div class="details">
      <h1>{{ data.title }}</h1>
      <transition name="fade">
        <job-application-thank-you v-if="showThankYou" />
        <div class="detail-wrapper" v-else>
          <div class="detail-tiles">
            <div class="detail-tile location-tile">
              <span class="title">Lokacija</span>
              <span class="info">{{ data.location.place }}</span>
            </div>
            <div class="detail-tile expiration-tile">
              <span class="title">Rok prijave:</span>
              <span class="info">{{ format(data.expirationDate, 'dd.MM.yyyy.') }}</span>
            </div>
            <div
              class="detail-tile salary-tile" v-if="data.salary.from || data.salary.to">
              <span class="title">Plaća</span>
              <span class="info">
                <span v-if="data.salary.from">od {{ data.salary.from }} EUR</span>
                <span v-if="data.salary.from">{{' '}}</span>
                <span v-if="data.salary.to">do {{ data.salary.to }} EUR</span>
              </span>
            </div>
          </div>
          <div class="job-description">
            <div class="description">
              <h4>Opis posla</h4>
              <p>{{ data.description }}</p>
            </div>
            <div class="employer">
              <div class="employer-initials">{{ data.company.name.substring(0,2) }}</div>
              <div class="employer-details">
                <div class="employer-header">Poslodavac</div>
                <div class="employer-name">{{ data.company.name }}</div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';
import JobApplicationThankYou from './JobApplicationThankYou.vue';

export default {
  name: 'JobDetails',
  components: {
    JobApplicationThankYou,
  },
  props: {
    data: {
      type: Object,
    },
    showThankYou: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  methods: {
    format,
  },
};
</script>

<style lang="scss" scoped>
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  transition: opacity 500ms;
  transition-delay: 150ms;
}
.fade-enter-top {
  opacity: 1;
}
.job-detail-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  text-align: left;
  .details {
    flex: 1;
    display: flex;
    flex-direction: column;
    h1 {
      margin: -8px 0 20px;
      padding: 0;
      font-size: 40px;
      line-height: 48px;
      font-weight: bold;
    }
    .detail-wrapper {
      flex: 1;
      display: flex;
      flex-direction: column;
      .detail-tiles {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        .detail-tile {
          display: flex;
          flex-direction: column;
          padding: 10px 75px 10px 15px;
          margin: 0 10px 10px 0;
          background-color: #33BAAA;
          background-position: right bottom;
          background-repeat: no-repeat;
          background-size: cover;
          color: #FFFFFF;
          border-radius: 5px;
          overflow: hidden;
          &.location-tile {
            background-image: url("../../assets/images/backgrounds/pin-bg.svg");
          }
          &.expiration-tile {
            background-image: url("../../assets/images/backgrounds/calendar-bg.svg");
          }
          &.salary-tile {
            background-image: url("../../assets/images/backgrounds/card-bg.svg");
          }
          .info {
            font-weight: bold;
          }
        }
      }
      .job-description {
        flex: 1;
        display: flex;
        flex-direction: column;
        .description {
          display: flex;
          flex-direction: column;
          margin-top: 20px;
          padding: 25px;
          height: 100%;
          border: solid 1px #EAEAEA;
          border-radius: 5px 5px 0 0;
          h4 {
            margin-bottom: 20px;
            font-size: 19px;
            line-height: 23px;
            font-weight: bold;
            color: #333333;
            text-transform: uppercase;
          }
          p {
            color: #777777;
            white-space: pre-wrap;
            word-break: break-all;
            word-break: break-word;
          }
        }
        .employer {
          display: flex;
          align-items: center;
          padding: 10px 25px;
          background-color: #F1F1F1;
          border: solid 1px #F1F1F1;
          border-radius: 0 0 5px 5px;
          .employer-initials {
            width: 51px;
            height: 51px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 15px;
            background-color: #FFFFFF;
            border: solid 2px #DCDCDC;
            border-radius: 50%;
            font-size: 17px;
            font-weight: bold;
            color: #DCDCDC;
          }
          .employer-header {
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 17px;
            font-weight: bold;
            color: #BABABA;
            text-transform: uppercase;
          }
          .employer-details {
            flex: 1;
            font-weight: bold;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>
