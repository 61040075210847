/* eslint-disable class-methods-use-this */

class JobTypeService {
  async getJobTypes() {
    const jobTypes = [{
      id: 1,
      name: 'Obični oglas',
      description: 'Oglas u pripadajućoj kategoriji/županiji. Oglas traje 15 dana.',
      price: 24.00,
    },
    {
      id: 2,
      name: 'Izdvojeni oglas',
      description: 'Izdvojeni oglas u pripadajućoj kategoriji/županiji. Oglas traje 15 dana.',
      price: 34.00,
    },
    {
      id: 3,
      name: 'Top oglas',
      description: 'Oglas koji se pokazuje na početnoj stranici i u kategoriji. Oglas traje 15 dana.',
      price: 49.00,
    }];

    return {
      data: jobTypes,
      total: jobTypes.length,
    };
  }
}

export default new JobTypeService();
