<template>
  <div :class="{
    'job-list-item-container': true,
    'selected': isSelected,
    'featured': data.typeID === 2,
   }">
    <div class="header">
      <span v-if="data.typeID === 2" class="featured-job">Izdvojeni oglas</span>
      <h1>{{data.title}}</h1>
      <span>{{data.company.name}}</span>
    </div>
    <div class="body">
      <ul class="icons">
        <li>
          <i class="fas fa-map-marker-alt"></i>
          <span>{{data.location.place}}</span>
        </li>
        <li>
          <i class="fas fa-calendar-alt"></i>
          <span>{{format(data.expirationDate, 'dd.MM.yyyy.')}}</span>
        </li>
      </ul>
    </div>
    <div class="footer">
      <template v-if="isEmployer">
        <div class="status in-authorization" v-if="status === 'not-authorized'">
          <i class="fas fa-hourglass-end"></i>
          <span>Čekanje na autorizaciju</span>
        </div>
        <div class="status is-active" v-if="status === 'active'">
          <i class="fas fa-check-circle"></i>
          <span>Aktivan</span>
        </div>
        <div class="status has-ended" v-if="status === 'expired'">
          <i class="fas fa-check-circle"></i>
          <span>Oglas je završio</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { format } from 'date-fns';

export default {
  name: 'ListItem',
  props: {
    data: {
      type: Object,
    },
    isEmployer: {
      type: Boolean,
      default: false,
    },
    isSelected: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    format,
  },
  computed: {
    status() {
      if (this.data.isActive) return 'active';
      if (this.data.expirationDate < new Date()) return 'expired';
      return 'not-authorized';
    },
  },
};
</script>

<style lang="scss" scoped>
.job-list-item-container.featured {
  background: linear-gradient(#FAFAFA, #EFEFEF);
}
.job-list-item-container.selected {
  background: white;
}
.job-list-item-container {
  text-align: left;
  padding: 20px;
  background: #F1F1F1;
  .header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 50px;
    h1 {
      font-size: 20px;
      line-height: 27px;
      font-weight: bold;
      color: #333333;
      margin-bottom: 5px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
    }
    span {
      color: #777777;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      &.featured-job {
        margin-bottom: 5px;
        font-size: 14px;
        font-weight: bold;
        color: #54B454;
        text-transform: uppercase;
      }
    }
  }
  .body {
    ul.icons {
      display: flex;
      flex-wrap: wrap;
      li {
        overflow: hidden;
        margin: 0 20px 5px 0;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        i {
          color: #54B454;
          width: 25px;
        }
      }
    }
  }
  .footer {
    .status {
      width: 100%;
      height: 32px;
      padding: 0 8px;
      margin-top: 10px;
      display: flex;
      align-items: center;
      border-radius: 3px;
      color: #FFFFFF;
      i {
        padding-right: 10px;
      }
      &.in-authorization { background-color: #33BAAA; }
      &.is-active { background-color: #26D35D; }
      &.has-ended { background-color: #DCDCDC; }
    }
  }
}
</style>
