<template>
  <ul :class="['container', isMobile ? 'mobile-container' : 'desktop-container']">
    <transition-group :name="isMobile ? 'mobile-list' : 'list'">
      <li
        :class="toast.type"
        v-for="toast in toasts"
        v-bind:key="toast.id"
      >
        <i :class="getIcon(toast.type)" />
        <span>{{ toast.message }}</span>
      </li>
    </transition-group>
  </ul>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Toast',
  computed: {
    ...mapState('application/ui', {
      toasts: state => state.toasts,
    }),
  },
  methods: {
    getIcon(type) {
      if (type === 'alert') return 'fas fa-times-circle';
      if (type === 'info') return 'fas fa-info-circle';
      return 'fas fa-exclamation-circle';
    },
  },
};
</script>

<style lang="scss" scoped>
.list-enter-active, .list-leave-active {
  transition: all 300ms;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(30px);
}

.mobile-list-enter-active, .mobile-list-leave-active {
  transition: all 300ms;
}
.mobile-list-enter, .mobile-list-leave-to /* .list-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-30px);
}

.info { background: rgb(1, 145, 8); }
.warning { background: rgb(194, 116, 0); }
.alert { background: rgb(175, 0, 0); }

.container {
  position: fixed;
  z-index: 1000;
  li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: white;
    i {
      font-size: 30px;
      margin-right: 10px;
      border-radius: 5px;
    }
  }
}
.desktop-container {
  top: 80px;
  right: 10px;
  width: 350px;
  opacity: .85;
  li {
    margin-bottom: 15px;
    padding: 12px;
  }
}
.mobile-container {
  top: 0;
  left: 0;
  width: 100%;
  opacity: .97;
  li {
    margin-bottom: 1px;
    padding: 30px 12px;
    &:first-child {
      padding-top: 100px;
    }
  }
}
</style>
