<template>
  <vue-js-paginate
    v-bind="$attrs"
    container-class="pagination"
    page-class="page-item"
    next-class="next-item"
    prev-class="prev-item"
    :margin-pages="1"
  />
</template>

<script>
import VueJsPaginate from 'vuejs-paginate';

export default {
  name: 'paginate',
  components: {
    VueJsPaginate,
  },
};
</script>

<style>
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
.pagination li {
  border: solid 1px #e8e8e8;
  border-radius: 5px;
  transition: all 150ms linear;
  margin-right: 3px;
}
@media (max-width: 1000px) {
  .pagination li {
    margin: 0 2px;
  }
}
.page-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
}
.page-item:hover, .next-item:hover, .prev-item:hover {
  background: #33BAAA;
  border-color: none;
  color: #FFF !important;
}
.page-item a:hover, .next-item a:hover, .prev-item a:hover {
  color: #FFF !important;
}
.page-item.active {
  background: #33BAAA;
  border-color: none;
}
.page-item.disabled {
  display: none;
  cursor: none;
}
.page-item a {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #585858 !important;
}
.page-item.active a {
  color: #FFFFFF !important;
}
.next-item, .prev-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
}
.next-item.disabled, .prev-item.disabled {
  opacity: 0.5;
}
.next-item.disabled a, .prev-item.disabled a {
    cursor: default;
}
.next-item a, .prev-item a {
  color: gray !important;
}
</style>
