<template>
  <div class="container">
    <form @submit.prevent="submit">
      <h1>Profil tvrtke</h1>
      <multiselect
        :options="companies"
        v-model="selectedCompany"
        :searchable="false"
        label="name"
        placeholder="Odaberi aktivnu tvrtku"
      />
      <div class="basic-info-container">
        <div class="company-picture-wrapper">
          <h4>Logo tvrtke</h4>
          <div class="company-picture">
            <img src="@/assets/images/test_profile.svg" alt="company-pic" />
          </div>
          <p class="logo-change-info">Ako želite zamjeniti logo
            pošaljite email na: kontakt@poslovac.net</p>
        </div>
        <div class="basic-info-wrapper">
          <div class="bi-input">
            <label for="company-name">Naziv tvrtke:</label>
            <input
              id="company-name"
              type="text"
              name="companyName"
              v-model="form.name"
            />
          </div>
          <div class="bi-input">
            <label for="oib">OIB tvrtke:</label>
            <input
              id="oib"
              type="text"
              name="oib"
              v-model="form.oib"
            />
          </div>
          <div class="bi-input">
            <label for="contact-email">Kontakt e-mail:</label>
            <input
              id="contact-email"
              type="text"
              name="contact-email"
              v-model="form.email"
            />
          </div>
          <div class="bi-input">
            <label for="industries">Djelatnost tvrtke:</label>
            <multiselect
              id="industries"
              :options="industries"
              v-model="form.industry"
              :searchable="false"
              label="name"
              placeholder="-"
            />
          </div>
          <div class="bi-input">
            <label for="company-address">Adresa tvrtke:</label>
            <places-autocomplete
              type="text"
              :types="[]"
              v-model="form.location"
            />
          </div>
        </div>
      </div>
      <Button
        theme="blue"
        type="submit"
        title="Spremi"
        :loading="isSavingCompany"
      />
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { required, email } from 'vuelidate/lib/validators';
import PlacesAutocomplete from '@/components/shared/PlacesAutocomplete.vue';

export default {
  name: 'CompanyProfile',
  components: {
    PlacesAutocomplete,
  },
  computed: {
    ...mapState('employer/companies/', {
      companies: state => state.list.data,
      isSavingCompany: state => state.details.save.inProgress,
    }),
    ...mapState('shared/industries/', {
      industries: state => state.list.data,
    }),
    emailErrors() {
      if (!this.$v.form.email.required) return 'e-mail je obavezan';
      if (!this.$v.form.email.email) return 'Neispravan e-mail';
      return null;
    },
    oibErrors() {
      if (!this.$v.form.oib.required) return 'OIB je obavezan';
      if (!this.$v.form.oib.oib) return 'Neispravan OIB';
      return null;
    },
  },
  data() {
    return {
      selectedCompany: null,
      form: {
        id: null,
        avatarUrl: '',
        name: '',
        oib: '',
        email: '',
        industry: {},
        location: {
          lat: null,
          lng: null,
          place: '',
        },
      },
    };
  },
  validations: {
    form: {
      oib: {
      },
      email: {
        required,
        email,
      },
    },
  },
  methods: {
    submit() {
      if (this.$v.$invalid) {
        if (this.emailErrors) {
          this.$store.dispatch('application/ui/showToast', { message: this.emailErrors, type: 'warning' });
        }

        if (this.oibErrors) {
          this.$store.dispatch('application/ui/showToast', { message: this.oibErrors, type: 'warning' });
        }
        return;
      }
      this.$store.dispatch('employer/companies/save', this.form)
        .then(() => {
          this.$store.dispatch('application/ui/showToast', { message: 'Ažurirana kompanija' });
        });
    },
    setFormData(data) {
      /**
       * Warning: for some reason business logic demands that we always
       * preselect first idustry from the list.
       */
      this.form = Object.assign({ industry: data.industries[0] }, data);
    },
    selectFirstCompany() {
      const [company] = this.companies;
      this.selectedCompany = company;
      this.setFormData(company);
    },
  },
  created() {
    this.selectFirstCompany();
  },
  watch: {
    selectedCompany(company) {
      this.setFormData(company);
    },
  },
};
</script>

<style lang="scss" scoped>
  .container::v-deep {
    width: 100%;
    max-width: 700px;
    margin: 40px auto 100px;
    padding-top: 30px;
    text-align: left;
    form {
      width: 100%;
      margin-bottom: 55px;
      display: flex;
      flex-direction: column;
      button[type="submit"] {
        margin-top: 55px;
      }
      h1 {
        margin-bottom: 40px;
        font-size: 43px;
        font-weight: bold;
        text-align: center;
      }
      h3 {
        margin: 60px 0 12px;
        font-size: 20px;
        font-weight: bold;
        color: #393939;
        opacity: 0.33;
      }
      h5 {
        margin: 10px 0 0;
        padding: 0 30px;
        font-size: 19px;
        font-weight: bold;
        color: #000000;
      }
      .multiselect {
        margin: 20px 0;
      }
      .basic-info-container {
        display: flex;
        flex-direction: column;
        border: solid 1px #EBEBEB;
        border-radius: 5px;
        .company-picture-wrapper {
          padding: 35px 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          background-color: #F9F9F9;
          border-bottom: solid 1px #EBEBEB;
          h4 {
            margin-bottom: 12px;
            font-size: 19px;
            font-weight: bold;
            color: #777777;
          }
          .company-picture {
            width: 180px;
            height: 180px;
            border-radius: 50%;
            overflow: hidden;
          }
          .logo-change-info {
            padding-top: 2%;
          }
        }
        .basic-info-wrapper {
          .bi-input {
            height: 60px;
            padding: 5px 30px;
            display: flex;
            align-items: center;
            border-bottom: solid 1px #EBEBEB;
            font-size: 19px;
            &:last-of-type {
              border-bottom: none;
            }
            label {
              width: 180px;
              display: flex;
              color: #000000;
              align-items: center;
              font-weight: bold;
            }
            input {
              flex: 1;
              height: 100%;
              font-size: 19px;
              border: none;
            }
          }
        }
      }
    }
  }
  @media (max-width: 430px) {
    .container {
      form {
        .basic-info-container {
          .basic-info-wrapper {
            .bi-input {
              flex-direction: column;
              text-align: center;
              height: 100%;
              padding: 15px 20px;
              label {
                width: 100%;
                justify-content: center;
                margin-bottom: 5px;
              }
              input {
                width: 100%;
                text-align: center;
              }
            }
          }
        }
      }
    }
  }
</style>
