<template>
  <div v-if="isLoading" class="container" />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'LoadingBar',
  computed: {
    ...mapState('application/ui', {
      isLoading: state => state.navigationBar.isLoading,
    }),
  },
};
</script>

<style lang="scss" scoped>
@keyframes widthKeyframes {
  0%, 100% {
    transition-timing-function: cubic-bezier(1, 0, 0.65, 0.85);
  }
  0% {
    width: 0;
  }
  100% {
    width: 100%;
  }
}
.container {
  position: absolute;
  bottom: 0px;
  height: 4px;
  width: 100%;
  background: #54B454;
  animation: widthKeyframes 700ms;
}
</style>
