<template>
  <section id="contact">
    <h3>Imaš pitanje? Javi nam se:</h3>
    <div class="inner-container">
    <div class="contact-box">
      <img src="@/assets/images/icons/search-job.svg" />
      <h3>Tražitelji posla</h3>
      <a href="mailto:korisnici@poslovac.net">korisnici@poslovac.net</a>
    </div>
    <div class="contact-box">
      <img src="@/assets/images/icons/search-employee.svg" />
      <h3>Tražite zaposlenike?</h3>
      <a href="mailto:kontakt@poslovac.net">kontakt@poslovac.net</a><br>
      <span>+385 1 6388 248</span>
    </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Contact',
};
</script>

<style lang="scss" scoped>
  section#contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #fff;
    padding: 50px 20px;
    background: url("../../assets/images/backgrounds/background_Contact.jpg") top left;
    background-size: cover;
    h3 {
      width: 100%;
      text-align: center;
      margin-bottom: 40px;
      font-size: 1.6rem;
    }
    .inner-container {
      display: flex;
      justify-content: center;
      width: 100%;
    }
    .contact-box {
      width: 100%;
      max-width: 375px;
      text-align: center;
      margin-bottom: 15px;

      h3 { margin-bottom: 5px; }
      a {
        font-size: 19px;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
      }
      span {
        font-size: 19px;
        transition: all 0.2s ease-in-out;
        text-decoration: none;
        color:#2AFF70;
        display: block;
        padding-top: 4px;
      }
    }
  }
  @media (max-width: 600px) {
    .inner-container {
      align-items: center;
      flex-direction: column;
    }
  }
</style>
