<template>
  <div :class="{
    'app-container': applyStyle,
    'app-container-mobile': isMobile,
  }">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'AppContainer',
  props: {
    ignoreLayoutWhenInRoutes: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    applyStyle() {
      return this.ignoreLayoutWhenInRoutes.includes(this.$route.name) === false;
    },
  },
};
</script>

<style lang="scss">
.app-container {
  width: 100%;
  max-width: 1040px;
  padding: 20px;
  margin: 90px auto 80px;
  @media (max-width: 1000px) {
    margin-top: 50px;
  }
}
.app-container-mobile {
}
</style>
