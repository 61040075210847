/* eslint-disable class-methods-use-this */
import axios from '@/utils/axios';
import {
  getJobResponseDataToModel,
  getJobsResponseDataToModelForEmployee,
  getJobsResponseDataToModelForEmployer,
  saveJobModelToRequestData,
} from './service-helpers';

class JobService {
  async getJob(id) {
    const response = await axios.get(`/v2/job_ads/${id}/`);
    return getJobResponseDataToModel(response.data.data);
  }

  async getJobsForEmployee(filter) {
    const queryString = Object.keys(filter).reduce((acc, key) => {
      const value = filter[key];
      if (value !== undefined || value !== '') {
        return `${acc}&${key}=${value}`;
      }

      return `${acc}`;
    }, '');
    const response = await axios.get(`/v2/job_ads/poslovacWeb?${queryString}`);
    return {
      data: getJobsResponseDataToModelForEmployee(response.data.results),
      total: response.data.count,
    };
  }

  async getJobsForEmployer(filter) {
    const queryString = Object.keys(filter).reduce((acc, key) => {
      const value = filter[key];
      if ((value !== undefined || value !== '') && key !== 'userId') {
        return `${acc}&${key}=${value}`;
      }

      return `${acc}`;
    }, '');

    const response = await axios.get(`/v2/users/${filter.userId}/job_ads/?${queryString}`);
    /**
     * Client side pagination FTW :D
     */
    const filteredJobs = response.data.data.slice(filter.offset, filter.offset + filter.limit);
    return {
      data: getJobsResponseDataToModelForEmployer(filteredJobs),
      total: response.data.data.length,
    };
  }

  async saveJob(job) {
    const response = await axios.put(`/jobs/details/${job.id}/`, saveJobModelToRequestData(job));
    if (response.status === 200) {
      return {
        success: true,
        message: '',
      };
    }

    return {
      success: false,
      message: 'Neispravni podaci',
    };
  }

  async createJob(job) {
    const response = await axios.post('/jobs/create/', saveJobModelToRequestData(job));
    if (response.status === 201) {
      return {
        success: true,
        message: '',
      };
    }

    return {
      success: false,
      message: 'Neispravni podaci',
    };
  }

  async deleteJob(jobId) {
    const response = await axios.delete(`/jobs/details/${jobId}/`);

    if (response.status === 204) {
      return {
        success: true,
        message: '',
      };
    }

    return {
      success: false,
      message: '',
    };
  }
}

export default new JobService();
