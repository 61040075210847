import { createStore } from '@/store/storeFactories';
import JobApplicationService from '@/services/job-application.service';

export default createStore({
  defaultFilterState: {
    jobId: null,
    limit: 4,
    offset: 0,
  },
  defaultDetailsState: {
    id: null,
    user: {},
    job: {},
  },
  services: {
    getListService: JobApplicationService.getJobApplications,
    getService: JobApplicationService.getJobApplication,
  },
});
