<template>
  <master-detail>
    <template #master>
      <h3>Oglas</h3>
      <h1>{{ job.title }}</h1>
      <h3>Prijave</h3>
      <list
        :data="jobApplications"
        :total="jobApplicationsCount"
        :limit="4"
        :offset="0"
        :isLoading="jobApplicationsLoading"
        :isLoadingItem="jobApplicationLoading"
        @select="showJobApplication"
        @pageChange="changePage"
      >
        <template v-slot:default="slotProps">
          <job-application-list-item
            :data="slotProps.item"
            :isSelected="slotProps.isSelected"
          />
        </template>
      </list>
    </template>
    <template #detail>
      <job-application-detail :userId="selectedUserId" />
    </template>
  </master-detail>
</template>

<script>
import { mapState } from 'vuex';
import LoadingBarMixin from '@/mixins/loadingBar.mixin';
import MasterDetail from '@/components/layout/MasterDetail.vue';
import JobApplicationListItem from './JobApplicationListItem.vue';
import JobApplicationDetail from './JobApplicationDetail.vue';

export default {
  name: 'Applications',
  mixins: [LoadingBarMixin],
  components: {
    MasterDetail,
    JobApplicationListItem,
    JobApplicationDetail,
  },
  computed: {
    ...mapState('employer/jobApplications', {
      jobApplications: state => state.list.data,
      jobApplicationsCount: state => state.list.total,
      jobApplicationsLoading: state => state.list.inProgress,
      jobApplicationLoading: state => state.details.load.inProgress,
      selectedUserId: state => state.details.data.user.id,
    }),
    ...mapState('employer/jobs', {
      job: state => state.details.data,
    }),
  },
  methods: {
    showJobApplication(jobApp) {
      if (this.isMobile) {
        this.$router.push({ name: 'JobApplication', params: { userId: jobApp.user.id } });
      } else {
        this.$store.dispatch('employer/jobApplications/set', { user: { id: jobApp.user.id } });
      }
    },
    getJobApplications() {
      this.dispatchWithLoadingBar('employer/jobApplications/getList', { jobId: this.$route.params.id });
    },
    changePage() {
      // console.log(page);
    },
  },
  mounted() {
    this.getJobApplications();
    // If we don't have job already present in the store, reload the job
    if (!this.job.id) {
      this.$store.dispatch('employer/jobs/select', this.$route.params.id);
    }
  },
};
</script>

<style lang="scss" scoped>
/* Left here for when search is implemented */
.search-filter {
  padding: 10px;
  i {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 50px;
    color: #818181;
    font-size: 18px;
  }
  input {
    width: 100%;
    height: 40px;
    padding-left: 50px;
    padding-right: 25px;
    background-color: #F1F1F1;
    color: #6B6B6B;
    border: none;
    border-radius: 5px;
    font-size: 18px;
    font-weight: normal;
    &::placeholder {
      color: #6B6B6B;
    }
  }
}
h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #393939;
  opacity: 0.33;
  text-align: left;
  text-transform: uppercase;
}
h1 {
  margin-top: -10px;
  margin-bottom: 30px;
  font-size: 40px;
  font-weight: bold;
  text-align: left;
}
</style>
