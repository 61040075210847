/* eslint-disable class-methods-use-this */
import axios from '@/utils/axios';
import { getPositionsResponseDataToModel } from './service-helpers';

class PositionService {
  async getPositions() {
    const response = await axios.get('/v2/positions');
    return {
      data: getPositionsResponseDataToModel(response.data.data),
      total: response.data.length,
    };
  }
}

export default new PositionService();
