<template>
  <div>
    <ul class="month-picker-container">
      <li>
        <multiselect
          v-model="month"
          :options="time.months"
          label="label"
          placeholder="Mjesec"
          @select="handleMonthChange"
        />
      </li>
      <li>
        <multiselect
          v-model="year"
          :options="time.years"
          label="label"
          placeholder="Godina"
          @select="handleYearChange"
        />
      </li>
    </ul>
    <error-message :message="errorMessage" />
  </div>
</template>

<script>
function getYears() {
  const thisYear = new Date().getFullYear();
  const years = [];

  for (let i = 0; i < 80; i += 1) {
    years.push({ value: thisYear - i, label: thisYear - i });
  }

  return years;
}

function getMonths() {
  const months = [];
  for (let i = 1; i <= 12; i += 1) {
    months.push({ value: i, label: i });
  }

  return months;
}

export default {
  name: 'MonthPicker',
  props: {
    value: {
      type: Date,
      default: new Date(),
    },
    errorMessage: {
      type: String,
    },
  },
  data() {
    const month = this.value.getMonth() + 1;
    const year = this.value.getFullYear();

    return {
      time: { years: getYears(), months: getMonths() },
      month: { value: month, label: month },
      year: { value: year, label: year },
      selectedDate: null,
    };
  },
  methods: {
    handleMonthChange(option) {
      const month = option.value;
      this.selectedDate = new Date(this.year.value, month - 1);
      this.$emit('input', this.selectedDate);
    },
    handleYearChange(option) {
      const year = option.value;
      this.selectedDate = new Date(year, this.month.value - 1);
      this.$emit('input', this.selectedDate);
    },
  },
};
</script>

<style lang="scss" scoped>
.month-picker-container::v-deep {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  li {
    width: 100%;
  }
  li:nth-child(1) .multiselect__tags {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  li:nth-child(2) .multiselect__tags {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
}
</style>
