<template>
  <div class="footer-container">
    <p>
      Web portalom i mobilnom aplikacijom upravlja tvrtka Alma Career Croatia d.o.o. 
      Strojarska cesta 20, 10000 Zagreb, Hrvatska, OIB: 14273924910 
      upisan u registar trgovačkog suda u Zagrebu - MBS 080343957 - 
      IBAN HR3023600001102440138 pri Zagrebačka banka d.d. - Temeljni kapital 2.670,00 EUR upisan u cijelosti.
    </p>
    <a href="https://poslovac.hr/info/uvjeti-koristenja">Uvjeti korištenja</a>
  </div>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<style lang="scss" scoped>
.footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
  color: #fff;
  text-align: center;
  background: #333333;
  p {
    margin: 10px;
    max-width: 1200px;
    padding: 0 30px;
  }
  a {
    font-size: 18px;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
  }
}
</style>
