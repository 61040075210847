const mutations = {
  toggleLoadingBar(state) {
    state.navigationBar.isLoading = !state.navigationBar.isLoading;
  },
  setLoadingBar(state, { isLoading = false }) {
    state.navigationBar.isLoading = isLoading;
  },
  showToast(state, { message, type }) {
    const toastId = new Date().getTime();
    state.toasts.push({ id: toastId, type, message });
  },
  popToast(state) {
    state.toasts.splice(state.toasts.length - 1, 1);
  },
};

const actions = {
  toggleLoadingBar({ commit }) {
    commit('toggleLoadingBar');
  },
  setLoadingBar({ commit }, isLoading) {
    commit('setLoadingBar', { isLoading });
  },
  setLoadStatus({ dispatch }, { inProgress = false }) {
    dispatch('setLoadingBar', inProgress);
  },
  showToast({ commit }, { message, type = 'info' }) {
    commit('showToast', { message, type });
    setTimeout(() => {
      commit('popToast');
    }, 3000);
  },
};

const state = {
  navigationBar: {
    isLoading: false,
  },
  toasts: [],
};

export default {
  namespaced: true,
  mutations,
  actions,
  state,
};
