<template>
  <div class="container">
    <h2>UNESITE LOKACIJU RADNOG MJESTA</h2>
    <ul>
      <li>
        <div class="places-container">
          <places-autocomplete
            v-model="validate.location.$model"
            :types="[]"
          />
        </div>
      </li>
      <li>
        <div class="map-container">
          <div id="map" />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import PlacesAutocomplete from '@/components/shared/PlacesAutocomplete.vue';

export default {
  name: 'Location',
  components: {
    PlacesAutocomplete,
  },
  props: {
    validate: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      marker: null,
    };
  },
  methods: {
    loadMaps() {
      return new window.google.maps.Map(document.getElementById('map'), {
        center: this.validate.location.$model,
        zoom: 12,
      });
    },
    setLocationOnMap(location) {
      const position = new window.google.maps.LatLng(location.lat, location.lng);
      if (this.marker === null) {
        this.marker = new window.google.maps.Marker({
          position,
          map: this.map,
        });
      }
      this.marker.setPosition(position);
      this.map.setOptions({
        zoom: 16,
        center: position,
      });
    },
  },
  updated() {
    this.setLocationOnMap(this.validate.location.$model);
  },
  mounted() {
    this.map = this.loadMaps();
    this.setLocationOnMap(this.validate.location.$model);
  },
};
</script>

<style lang="scss" scoped>
.container {
  > h2 {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #393939;
  }
  .map-container {
    height: 500px;
    margin-top: 20px;
    > div { height: 100%; }
  }
}
</style>
