<template>
    <div class="navigation-bar">
      <div class="navigation-columns">
        <div class="navigation-left">
          <router-link to="/">
            <div class="to-home">
              <img
              />
              <img
              src="https://static.mojposao.hr/brand/poslovachr/logo--r-index.svg"
                alt="poslovac-logo-txt"
              />
             
            </div>
          </router-link>
        </div>
        <div class="navigation-right">
          <div v-if="isAuthorized">
            <router-link v-if="user.isEmployer" :to="{ name: 'NewJob' }" class="option-button">
              Objavi oglas
            </router-link>
            <div class="user-dd-handle" @click="dropdownShow=!dropdownShow" ref="dd-handle">
              <div class="user-avatar">
                <img src="@/assets/images/test_profile.svg" />
              </div>
              <span>{{user.email}}</span>
              <i class="fas fa-caret-down"></i>
            </div>
          </div>
          <div v-else>
            <Button
              title="Registracija"
              @click="$modal.toggle('registration-modal')"
            />
            <Button
              title="Prijava"
              @click="$modal.toggle('login-modal')"
            />
            <div
              class="hamburger-handle"
              ref="dd-handle"
              @click="dropdownShow=!dropdownShow"
            >
              <hamburger-icon />
            </div>
          </div>
        </div>
      </div>
      <div
        :class="['additional-options', dropdownShow ? 'open' : 'closed']"
        v-closable="{ exclude: ['dd-handle'], handler: 'onClose' }"
      >
        <div
          v-if="!isAuthorized"
          class="option-button"
          @click="$modal.toggle('login-modal')"
        >
          Prijavi se
        </div>
        <div
          v-if="!isAuthorized"
          class="option-button"
          @click="$modal.toggle('registration-modal')"
        >
          Registriraj se
        </div>
        <router-link
          v-if="isAuthorized && user.isEmployer"
          :to="{ name: 'PublishedJobs' }"
          class="option-button"
        >
          <i class="far fa-list-alt"></i>
          <span>Moji oglasi</span>
        </router-link>
        <router-link
          v-if="isAuthorized"
          :to="{ name: 'UserProfile' }"
          class="option-button"
        >
          <i class="fas fa-user-edit"></i>
          <span>Uredi korisnički profil</span>
        </router-link>
        <router-link
          v-if="isAuthorized && user.isEmployer"
          :to="{ name: 'CompanyProfile' }"
          class="option-button"
        >
          <i class="fas fa-building"></i>
          <span>Uredi profil tvrtke</span>
        </router-link>
        <div
          v-if="isAuthorized"
          class="option-button"
          @click="logout"
        >
          <i class="fas fa-power-off"></i>
          <span>Odjavi se</span>
        </div>
      </div>
      <div
        v-show="$route.name !== 'JobSearch' && $route.name !== 'Home' && isNotMobile"
        class="additional-padding"
      />
      <button
        v-show="['PublishedJob', 'JobApplication', 'EmployeeJob'].includes($route.name)"
        class="go-back"
        @click="$router.go(-1)"
      >
        <i class="fas fa-chevron-left"></i>Natrag
      </button>
      <loading-bar />

    <div
      v-if="isAuthorized && user.isEmployer && user.similarCompanyFound"
      class="warning-employer-not-completed"
    >
    Vaš korisnički račun je u procesu aktivacije. Pristup prijavama će biti omogućen tokom dana,
    najkasnije u roku od 24h (osim vikendom). Ukoliko i dalje ne vidite prijave,
    kontaktirajte nas na 01 638 8248.
    </div>
    </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import HamburgerIcon from './HamburgerIcon.vue';
import LoadingBar from './LoadingBar.vue';

let handleOutsideClick;

Vue.directive('closable', {
  bind(el, binding, vnode) {
    handleOutsideClick = (e) => {
      e.stopPropagation();
      const { handler, exclude } = binding.value;

      let clickedOnExcludedEl = false;
      exclude.forEach((refName) => {
        if (!clickedOnExcludedEl) {
          const excludedEl = vnode.context.$refs[refName];
          clickedOnExcludedEl = excludedEl.contains(e.target);
        }
      });

      if (e.target && !clickedOnExcludedEl) {
        vnode.context[handler]();
      }
    };
    document.addEventListener('click', handleOutsideClick);
  },

  unbind() {
    document.removeEventListener('click', handleOutsideClick);
  },
});

export default {
  name: 'App',
  components: {
    HamburgerIcon,
    LoadingBar,
  },
  data() {
    return {
      dropdownShow: false,
    };
  },
  computed: {
    ...mapGetters('session', ['isAuthorized', 'user']),
  },
  methods: {
    ...mapActions('session', ['logout']),
    onClose() {
      this.dropdownShow = false;
    },
  },
};
</script>

<style lang="scss">
.navigation-bar {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #FFFFFF;
  border-bottom: solid 1px #DFDFDF;
  .navigation-columns {
    position: relative;
    width: 100%;
    max-width: 1640px;
    z-index: 10;
    height: 70px;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    .navigation-left {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .to-home {
        height: 40px;
        display: flex;
        align-items: center;
        > img {
          &:first-of-type {
            margin-right: 15px;
            width: 42px;
          }
        }
      }
    }
    .navigation-right {
      > div {
        position: relative;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        > a {
          margin: 0 50px;
          font-size: 15px;
          text-transform: uppercase;
          &:hover {
            color: inherit;
          }
        }
      }
      .user-dd-handle {
        margin-left: 15px;
        display: flex;
        align-items: center;
        cursor: pointer;
        .user-avatar {
          width: 40px;
          height: 40px;
          margin-right: 15px;
          border-radius: 50%;
          overflow: hidden;
        }
        span {
          flex: 1;
          max-width: 200px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          text-transform: lowercase;
          font-size: 15px;
          margin-right: 5px;
        }
      }
      .hamburger-handle {
        display: none;
      }
      button {
        background-color: transparent;
        border-color: transparent;
        text-transform: uppercase;
        color: #333333;
        background: none;
      }
    }
  }
  .warning-employer-not-completed {
    min-height: 40px;
    background-color: orange;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgb(10, 10, 17);
    position: absolute;
  }
  .additional-options {
    position: absolute;
    top: 60px;
    right: 20px;
    z-index: 15;
    color: #808080;
    background-color: #FFFFFF;
    border-radius: 2px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
    opacity: 0;
    pointer-events: none;
    transition: all 80ms linear;
    &.open {
      opacity: 1;
      pointer-events: auto;
    }
    .option-button {
      padding: 15px;
      display: flex;
      border-bottom: solid 1px #F1F1F1;
      cursor: pointer;
      font-size: 16px!important;
      font-weight: normal!important;
      color: #808080!important;
      &:hover {
        color: #808080!important;
      }
      &:last-child {
        border: none;
      }
      i {
        width: 28px;
        text-align: left;
      }
    }
  }
  a {
    color: inherit;
    font-weight: bold;
  }
  .additional-padding {
    height: 30px;
    position: fixed;
    top: 70px;
    left: 0;
    right: 0;
    background: linear-gradient(#ECECEC, #FFFFFF);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  }
  button.go-back {
    display: none;
    position: fixed;
    left: 0;
    width: 112px;
    height: 47px;
    background-color: #E1E1E1;
    font-size: 16px;
    color: #FFFFFF;
    border: none;
    border-radius: 0 0 5px 0;
    i {
      padding-right: 15px;
    }
    @media (max-width: 1000px) {
      display: block;
    }
  }
}

@media (max-width: 700px) {
  .navigation-bar {
    .navigation-columns {
      .navigation-left {
        .to-home {
          img:nth-of-type(n+2) {
            display: none!important;
          }
        }
      }
      .navigation-right {
        > div {
          > a {
            margin: 0 10px;
          }
        }
        .user-dd-handle {
          position: relative;
          span {
            display: none;
          }
        }
        .hamburger-handle {
          display: block;
        }
        > div > button {
          display: none;
        }
      }
    }
    .additional-options {
      position: fixed;
      z-index: 5;
      top: 0;
      left: 0;
      right: 0;
      padding-top: 70px;
      transform: translateY(-270px);
      transition: all 150ms linear;
      &.open {
        transform: translateY(0);
      }
      .option-button {
        text-transform: uppercase;
        padding: 25px 0;
        margin: 0 25px;
      }
    }
  }
}
@media (min-width: 1600px) {
  .navigation-bar {
    .additional-options {
      right: calc((100vw - 1600px)/2);
    }
  }
}
</style>
