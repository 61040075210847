<template>
  <button
    v-bind="$attrs"
    :disabled="disabled"
    :type="type"
    :class="['button', theme, disabled && 'gray']"
    @click="$emit('click')"
  >
    <i :class="{ 'fas fa-spinner fa-pulse': loading }" />
    <span class="title">{{title}}</span>
    <i :class="icon" />
  </button>
</template>

<script>
export default {
  name: 'Button',
  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
      default: 'button',
    },
    icon: {
      type: String,
    },
    loading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    disabled: {
      type: Boolean,
      default() {
        return false;
      },
    },
    theme: {
      type: String,
      default() {
        return 'default';
      },
    },
  },
};
</script>

<style lang="scss">
.button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 160px;
  height: 50px;
  padding: 0 16px;
  margin: 0 auto;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 150ms ease-in-out 0s;
  &:hover,
  &:focus,
  &:focus-within,
  &:active { outline: none; }
  &:disabled {
    background: rgb(168, 168, 168);
    color: lightgray;
    border-color: rgb(168, 168, 168);
  }
}
</style>

<style lang="scss" scoped>
.default {
  background-color: #26D35D;
  color: #FFFFFF;
  background: linear-gradient(90deg, #26d35d 0%, #03bd94 100%);
  &:hover {
    background: linear-gradient(90deg, #26d35d 0%, #26d35d 100%);
  }
  border: none;
}
.white {
  background-color: #FFFFFF;
  border-color: #DFDFDF;
  color: #333333;
  &:hover { background-color: #faf5f5; }
  border: solid 2px #dfdfdf;
}
.blue {
  border: none;
  background-color: #26D35D;
  background: linear-gradient(90deg, #26d35d 0%, #03bd94 100%);
  color: #FFFFFF;
  &:hover {
    background: linear-gradient(90deg, #26d35d 0%, #26d35d 100%);
  }
}
.blue-100 {
  width: 50px;
  background-color: #2A515A;
  &:hover { background-color: #3E7785; }
  i {
    font-size: 23px;
    color: #FFF;
  }
}
.gray {
  background: rgb(187, 187, 187);
  color: rgb(224, 224, 224);
  border-color: rgb(187, 187, 187);
  cursor: default;
  &:hover {
    background-color: rgb(187, 187, 187);
    border: solid 2px rgb(187, 187, 187);
  }
}
.gray-100 {
  background: #E1E1E1;
  color: #B7B7B7;
  width: 50px;
  &:hover {
    background-color:#c1c1c1;
    color: #FFF;
  }
}
.danger {
  background: #E75C65;
  color: white;
  border: 0;
}
</style>
