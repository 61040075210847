<script>
export default {
  name: 'Slider',
  props: {
    activeIndex: {
      type: Number,
      required: true,
      default: 0,
    },
  },
  render(ce) {
    return ce(
      'div',
      {
        class: 'slider-container',
      },
      this.$slots.default.map((vNode, idx) => ce(
        'div',
        {
          class: { visible: this.activeIndex === idx },
        },
        [vNode],
      )),
    );
  },
};
</script>

<style lang="scss" scoped>
.slider-container {
  height: 100%;
  > div {
    display: none;
    height: 100%;
    &.visible {
      display: block;
    }
  }
}
</style>
