import companies from './companies.store';
import jobs from './jobs.store';
import jobApplications from './job-applications.store';
import jobTypes from './job-types.store';

export default {
  namespaced: true,
  modules: {
    companies,
    jobs,
    jobApplications,
    jobTypes,
  },
};
