/* eslint-disable class-methods-use-this */
import axios from '@/utils/axios';
import { getIndustriesResponseDataToModel } from './service-helpers';

class IndustryService {
  async getIndustries() {
    const response = await axios.get('/categories/industries/');
    return {
      data: getIndustriesResponseDataToModel(response.data),
      total: response.data.length,
    };
  }
}

export default new IndustryService();
