<template>
  <ul>
    <li v-for="item in data" :key="item.id">
      <div class="work-experience" @click="$emit('click', item)">
        <h6>{{ item.position.title }} (m/ž)</h6>
        <p>{{ item.companyTitle }}</p>
        <p>
          {{ format(item.startDate, 'MM.yyyy') }} - {{ format(item.endDate, 'MM.yyyy') }}
          ({{ workDuration(item.startDate, item.endDate) }})
        </p>
        <p class="description">
          {{ item.description }}
        </p>
      </div>
      <div class="delete-button-container">
        <i class="fas fa-trash-alt" role="button" @click="$emit('delete', item)" />
      </div>
    </li>
  </ul>
</template>

<script>
import { format } from 'date-fns';
import { differenceInYearAndMonthsStr } from '@/utils/date';

export default {
  name: 'WorkExperienceList',
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    format,
    workDuration: differenceInYearAndMonthsStr,
  },
};
</script>

<style lang="scss" scoped>
ul li {
  display: flex;
  border-top: solid 1px #EBEBEB;
}
.delete-button-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  flex-basis: 82px;
  margin-top: 14px;
  color: #CCC;
  i {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 40px;
    cursor: pointer;
    transition: font-size 300ms ease;
    &:hover {
      font-size: 20px;
      transition: font-size 300ms ease;
    }
  }
}
.work-experience {
  flex: 1;
  padding: 25px 30px;
  font-size: 19px;
  h6 {
    margin-bottom: 10px;
    color:#777777;
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
  }
  p {
    color: #BBBBBB;
  }
  .description {
    margin-top: 15px;
    color: #777777;
    font-size: 16px;
  }
}
</style>
