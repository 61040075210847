import jobs from './jobs.store';
import jobApplication from './job-application.store';
import jobPhoneApplication from './job-phone-application.store';
import jobExternalLinkApplication from './job-external-link-application.store';
import profile from './profile.store';
import workExperience from './work-experience.store';

export default {
  namespaced: true,
  modules: {
    jobs,
    jobApplication,
    jobPhoneApplication,
    jobExternalLinkApplication,
    profile,
    workExperience,
  },
};
