<template>
  <div class="home-container">
    <Intro />
    <About />
    <Contact />
  </div>
</template>

<script>
import Intro from './Intro.vue';
import About from './About.vue';
import Contact from './Contact.vue';

export default {
  name: 'Home',
  components: {
    Intro,
    Contact,
    About,
  },
};
</script>

<style lang="scss" scoped>
  .home-container {
    flex: 1;
    margin-top: -70px;
    max-width: 100%;
    width: 100%;
  }
</style>
