import axios from 'axios';
import config from '@/config';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

axios.defaults.validateStatus = status => (status >= 200 && status < 500);

function requestHandler(cfg) {
  const jwt = localStorage.getItem('__TOKEN__');

  if (jwt) {
    return {
      ...cfg,
      headers: { Authorization: `JWT ${jwt}` },
    };
  }

  return cfg;
}

function responseHandler(response) {
  if (response.status === 401) {
    store.dispatch('session/logout');
  }

  return response;
}

function responseErrorHandler(error) {
  if (process.env.NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  store.dispatch('application/ui/showToast', {
    type: 'alert',
    message: 'Greška na serveru',
  });
}

const axiosInstance = axios.create({
  baseURL: config.baseUrl,
});

axiosInstance.interceptors.request.use(requestHandler);
axiosInstance.interceptors.response.use(responseHandler, responseErrorHandler);

export default axiosInstance;
