<template>
  <ul>
    <li>
      <Input
        title="UNESITE NASLOV OGLASA"
        v-model="validate.title.$model"
        :errorMessage="titleErrors"
      />
    <li>
    <li>
      <text-area
        title="UNESITE OPIS OGLASA"
        v-model="validate.description.$model"
        :errorMessage="descriptionErrors"
      />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'BasicInfo',
  props: {
    validate: {
      type: Object,
      required: true,
    },
  },
  computed: {
    titleErrors() {
      if (!this.validate.title.$dirty) return null;
      if (!this.validate.title.required) return 'Obavezan unos';
      return null;
    },
    descriptionErrors() {
      if (!this.validate.description.$dirty) return null;
      if (!this.validate.description.required) return 'Obavezan unos';
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
ul {

}
</style>
