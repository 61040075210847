export default {
  methods: {
    dispatchWithLoadingBar(type, payload) {
      this.$store.dispatch('application/ui/setLoadStatus', { inProgress: true });
      return this.$store.dispatch(type, payload)
        .then(() => {
          this.$store.dispatch('application/ui/setLoadStatus', { inProgress: false });
        })
        .catch(() => {
          this.$store.dispatch('application/ui/setLoadStatus', { inProgress: false });
        });
    },
  },
};
