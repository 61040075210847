/* eslint-disable import/prefer-default-export */
import { differenceInMonths, differenceInYears } from 'date-fns';

export function differenceInYearAndMonthsStr(start, end) {
  let years = 0;
  let months = differenceInMonths(end, start);

  if (months >= 12) {
    years = differenceInYears(end, start);
    months %= 12;
  }

  const yearsStr = years > 0 ? `${years} god.` : '';
  const monthsStr = months > 0 ? `${months} mj.` : '';
  return `${yearsStr} ${monthsStr}`;
}
