<template>
  <div><h1>TERMS AND CONDITIONS OF USE</h1>
    <li>
      <strong>Introduction</strong>
      <p>These Terms and Conditions of Use regulate the rights and obligations regarding the use of
        Poslovac application on the website <router-link to="/">www.poslovac.net</router-link>,
        which is available via applications for mobile platforms (hereinafter: Poslovac or
        Application) and represents a service provided by the company TAU ON-LINE d.o.o., Zagreb,
        Strojarska cesta 20, Court Reg. No.: 080297343, PIN 14273924910 (hereinafter: Company).</p>
      <p>Terms and Conditions of Use, as well as all the amendments thereof that might follow, will
        be in effect from the day when they are published on the website <router-link to="/">
        www.poslovac.net</router-link>; they represent an integral part of
        every contract, whether oral or written, concluded between the Company and the user and are
        binding for every natural and legal person using the Poslovac application.</p>
      <p>Poslovac mediates the exchange of information on job vacancies posted in the Poslovac
        application by the Employer, by making such information available to Employees who want to
        be informed about available job vacancies.</p>
      <p>Poslovac gives users internet access to data published by authors of posts. A post consists
        of text, location and photographs, that is, graphic input by users, divided into individual
        categories.</p>
      <p>Poslovac enables registered users to use it as a platform for creation, publishing and
        altering any information related to posts &#8211; actions which are technically possible in
        accordance with the functionality of the Application.</p>
      <p>Any use of Poslovac as a whole or any of its parts that is not in accordance with the Terms
        and Conditions of Use will be considered misuse of the service provided by the Company and
        infringement of the Terms and Conditions of Use.</p>
      <p>The Company reserves the right to alter and remove (temporarily or permanently) any element
        of Poslovac, as well as any content or input, regardless of the author and without prior
        consent or notification, applying good business practices.</p>
      <p>The Company reserves the right to delete any texts with inappropriate, insulting or
        discriminatory content that is in violation of the Constitution of the Republic of Croatia,
        applicable mandatory regulations and rules of morality. If the Employer does not abide by
        mandatory legal regulations, in particular mandatory provisions of the valid Labour Act, the
        Company reserves the right not to publish a single post or all posts by said Employer. The
        Company also reserves the right to alter posts in the sense of correcting of spelling
        mistakes and structure of the posted content in accordance with the Terms and Conditions of
        Use.</p>
    </li>
    <li>
      <strong>Definitions</strong>
      <p>Within the meaning of these Terms and Conditions of Use, VISITOR is any person who accesses
        the Application via the Internet, without signing in, registering and free of charge. The
        Visitor can learn about the Terms and Conditions of Use and the purpose of the Application
        and can register as a user.</p>
      <p>EMPLOYEE is any natural person using Poslovac for the purpose of finding a suitable
        workplace within the meaning of provisions of the applicable Labour Act or for finding
        engagement within the meaning of provisions of the applicable Civil Obligations Act
        regarding piece work agreements, or for finding volunteer work, a workplace for professional
        training, etc.</p>
      <p>EMPLOYER is any natural and/or legal person that uses Poslovac within the meaning of
        provisions of the applicable Labour Act, regardless of the type of employment contract such
        Employer wants to conclude, as well as any other natural and/or legal person using the
        Poslovac website for the purpose of finding a suitable candidate and/or applicant within the
        meaning of provisions of the applicable Civil Obligations Act regarding piece work
        agreements, that is, any natural and/or legal person looking for volunteers, interns, staff
        for professional training, etc.</p>
      <p>JOB is a general term for any type of workplace, employment, engagement, volunteer work,
        traineeship, internship, professional training, professional training without commencing
        employment, etc.</p>
      <p>USE is any posting of listings regarding a job vacancy or any other type of engagement by
        the Employer or making own personal data available with the aim of finding a workplace or
        other type of engagement by the Employee.</p>
      <p>PERSONAL DATA includes any information referring to an identified or identifiable natural
        person, which are protected in accordance with the provisions of the General Data Protection
        Regulation (GDPR) and the Act on Implementation of the General Data Protection
        Regulation.</p>
      <p>PRICE LIST represents the price list of services available in the Application.</p>
      <p>GDPR pertains to the Regulation 2016/679 of the European Parliament and of the Council of
        27 April 2016 on the protection of natural persons with regard to the processing of personal
        data and on the free movement of such data, and repealing Directive 95/46/EC (General Data
        Protection Regulation).</p>
      <p>CONTROLLER is a natural or legal person that determines the purposes and means of personal
        data processing.</p>
      <p>PROCESSOR is a natural or legal person that processes personal data on behalf of the
        Controller.</p>
    </li>
    <li>
      <strong>Employers</strong>
      <p>To use the Application, the Employer is obligated to register by making a user account, on
        the conditions and in the manner described in these Terms and Conditions of Use.</p>
      <p>Registered Employers can post Workplace listings at any time, subsequently amend them in
        agreement with the Application’s advisers, monitor the viewing statistics for posted
        listings, search CV summaries and use other advanced features that have been or shall be
        installed.</p>
      <p>The Employer independently determines the content and the category within which the content
        is posted. Content is published in the Application exclusively with prior consent by the
        Company. The Employer is exclusively liable and bears any legal consequences that could
        arise from publishing content in the Application.</p>
      <p>The Employer guarantees he is the holder or user of copyright and/or related rights over
        the entire content, as well as over all individual parts of the content published in the
        Application. The Employer is exclusively responsible for any infringement of copyright
        and/or related rights, as well as all other intellectual property rights.</p>
      <p>The Employer undertakes to respond to Employees’ messages and calls within a reasonable
        period.</p>
      <p>By posting in the Application, the Employer gives unconditional and irrevocable consent to
        the Company to promote said post through advertising or in any other appropriate manner, as
        per the Company’s assessment.</p>
      <p>The Employer undertakes to use his own user account in the Application exclusively in order
        to find appropriate employees to suit his needs. The Employer undertakes not to save the
        data from the Poslovac application to his computers by using automatic applications. The
        Employer undertakes not to provide or allow insight into data, whether directly or by
        granting use of his own username and password to third parties.</p>
      <p>In the event that the Employer uses his user account for any other purpose (e.g. for
        promoting and selling various products, services, etc.), the Company will block and remove
        any such user account. Such misuse of user account is subject to legal regulations on filing
        claims for damages against the user responsible for the damage, in order for the Company to
        be indemnified.</p>
      <p>The service of posting listings for vacant Workplaces in the Poslovac application is
        charged in accordance with the Price List valid at the moment of posting of listings.</p>
    </li>
    <li>
      <strong>Employees</strong>
      <p>To use the Application, the Employee is obligated to register by making a user account, on
        the conditions and in the manner described in these Terms and Conditions of Use.</p>
      <p>While registering, the Employee sets his/her own password and bears exclusive
        responsibility for the security of the password and the use of the Application via access
        information. The Employee undertakes not to use access information of other users or to give
        his or her user information to any third parties. The Employee can notify the Company if
        he/she has suspicions regarding unauthorised use of his/her access information.</p>
      <p>The Employee is exclusively responsible for accuracy and correctness of the data he/she has
        entered. Accuracy of data entails that the data comes from a credible source and/or personal
        knowledge of the Employee. Correctness entails correct use of grammar and spelling.</p>
      <p>The Employee can see the content of posts, their duration, description of each job vacancy,
        names of individual authors of posts (Employers), as well as their contact information and
        other content, free of charge. While the post is active, the Employee can download the
        Employer’s data and ask the Employer questions, all in accordance with the options
        available.</p>
      <p>The Employee undertakes to use his/her user account in the Application exclusively to
        search for, find and get information on Workplaces.</p>
      <p>In the event that the Employee uses his user account for any other purpose (e.g. for
        promoting and selling various products, services, etc.), the Company will block and remove
        any such user account. Such misuse of user account is subject to legal regulations on filing
        claims for damages against the user responsible for the damage, in order for the Company to
        be indemnified.</p>
    </li>
    <li>
      <strong>Responsibility for data accuracy and damage liability</strong>
      <p>The Company is neither responsible for nor does it guarantee the accuracy of available
        personal data, contact details, qualifications, characteristics or any other data listed in
        user accounts, CVs, applications or any other documents attached by Employees and/or
        listings posted by Employers.</p>
      <p>The Company is not liable to Employers or Employees for any kind of damage and, in that
        sense, by using the Application, Employers and Employees waive the right to damage claims
        for any sort of damage, be it property or non-property, which could be caused to them
        through the misuse of erroneous data by Employers, Employees or third parties.</p>
      <p>The Company is not responsible for possible temporary unavailability of the Application nor
        for the partial or total non-functioning or malfunctioning of the Application. The Company
        is not responsible for any technical difficulties that may lead to late and/or erroneous
        processing of electronic data. Internet and telecommunications service providers are
        responsible for all of the above.</p>
      <p>The Application may be temporarily unavailable or available in limited capacity as a result
        of regular or extraordinary maintenance of the system or in case of system updates.</p>
      <p>Employers and Employees who misuse or unauthorizedly disclose access information for their
        user account to third parties that do not have the relevant authorisation are liable for any
        damage arising from an unauthorised access to a user account, either to the Company or to
        other legal or natural persons whose personal data they have misused.</p>
    </li>
    <li>
      <strong>Copyright</strong>
      <p>For the territory of the Republic of Croatia, the Company holds exclusive copyright and
        intellectual property rights over the Application, as well as over all individual
        constituent elements of the Application, such as: name, domain, text, visual and audio
        elements, visual identity, data and databases, source code and other elements of the
        Application, the author of which is the Company.</p>
      <p>Unauthorised use of any part of the Application or of the Application as a whole, without
        explicit prior written consent of the Company as the holder of exclusive copyright, shall be
        considered an infringement of the Company’s copyright and be subject to initiation of court
        proceedings in accordance with applicable regulations of the Republic of Croatia.</p>
      <p>The Application may also contain elements over which third parties hold exclusive copyright
        and other intellectual property rights, such as the content uploaded by users of the
        Application, business partners, advertisers, as well as free content. Third parties have
        exclusive responsibility for the content over which they hold the aforementioned rights,
        regardless of the fact that said content can be found in the Application of the Company.</p>
      <p>By uploading content to the Application, the user consents to having said content made
        visible to every visitor of the Application, on the conditions and in the manner described
        in these Terms and Conditions of Use. Transferring content or part of content from any part
        of the Application is allowed only with explicit prior written consent of the Company, with
        a note indicating that the content was taken from the Application and with the addition of
        the appropriate link leading to the part of the Application from which the content was
        taken. The Company is exclusively liable only for the content over which it holds the
        copyright and/or intellectual property rights.</p>
      <p>Every user of the Application is solely responsible for the content on which he/she holds
        the copyright, i.e. for the content he/she has independently uploaded to and made publicly
        available in the Application.</p>
    </li>
    <li>
      <strong>Protection and processing of Personal Data</strong>
      <p>In accordance with the General Data Protection Regulation (GDPR), when Employers post job
        ads on the Application, the Company is considered to be the Processor of personal data, and
        the Employer is considered to be the Controller in the processing of personal data.</p>
      <p>The Controller is obligated to collect personal data received following the posting of
        listings in a transparent manner and in accordance with valid legal bases (intention of
        providing employment or concluding an employment contract).</p>
      <p>The Controller is obligated to collect only the data that is appropriate, relevant and
        limited to data essential for the purpose for which they are being collected.</p>
      <p>The Controller guarantees to the Processor that he will not use the personal data collected
        through the Poslovac website for any purpose other than for providing employment.</p>
      <p>The Controller confirms that organisational and technical measures have been implemented
        which will enable the Controller to exercise control over the collection of personal data
        through the Poslovac website. Also, the Controller is responsible for download of the
        personal data (application) and for assigning access information for his/her user
        account.</p>
      <p>The Controller undertakes to fully cooperate with the Processor in order to enable each
        Employee to exercise the rights laid down in regulations on personal data protection should
        he or she wish to do so (right of access, rectification, erasure, portability, restriction
        of processing and right to object to processing of personal data).</p>
      <p>In the event of breach of regulations related to personal data processing, the Controller
        undertakes to fully cooperate with the Processor on rectifying the damage and working with
        regulatory authorities.</p>
      <p>The Processor undertakes to process personal data (collection of applications to Employers’
        listings) exclusively within the scope of service, on behalf of the Controller and for the
        purpose determined by business cooperation, to record and save all processes of personal
        data processing conducted on behalf and for the account of the Controller until recalled by
        the Controller.</p>
      <p>The Processor guarantees to the Controller that Personal Data can be accessed only by the
        employees and/or other persons working under his supervision who are authorised to do so
        based on regulations or contractual relationships, and that access to Personal Data is only
        granted to the extent to which that is necessary for service provision.</p>
      <p>The Processor states he has undertaken all the organisational and technical measures as
        laid down by the GDPR, especially with regard to the system of regular control, testing,
        integrity, availability and resilience of IT systems.</p>
      <p>The Processor will assess the effectiveness of technical and organisational measures on a
        regular basis, as well as continually perfect and improve them.</p>
      <p>The Processor and the Controller shall inform each other of every relevant personal data
        breach within 24 hours from the occurrence of any such breach.</p>
      <p>The Processor can process the Controller’s data only for the purpose of statistical and
        analytical testing of IT systems and monitoring registration statistics for applications to
        job listings.</p>
      <p><strong>7.b. Consent</strong></p>
      <p>By using the Application, Employers who are natural persons and Employees give explicit
        consent to the Company for collection and processing of their personal data which they make
        available within their user accounts, publication in CVs, applications or any other attached
        documents.</p>
      <p>By using the Application, Employees give explicit consent to Employers for collection and
        processing of their personal data. By sending an application, the Employee consents to
        having the personal data stated in the published CV, job applications and other documents
        accessed by Employers they have given consent to.</p>
      <p>Consent for collection and processing of personal data, in accordance with the provisions
        of the General Data Protection Regulation (GDPR) and the Act on Implementation of the
        General Data Protection Regulation, is given exclusively for the purpose of applying to
        Workplace listings and for the purpose of performing all other actions closely related to
        advertising and applying to Workplace listings; the data cannot be used for any other
        purpose.</p>
      <p>Employers who are natural persons and Employees can get information on the processing of
        their personal data, file a complaint regarding the processing of their personal data or at
        any moment withdraw consent for processing of their personal data without providing
        additional explanations by recalling the consent in writing to the address: <strong>TAU
          ON-LINE d.o.o. (Data protection officer), Strojarska cesta 20, 10000 Zagreb</strong>, to
        the e-mail address: <a href="mailto:szop@moj-posao.net"><strong>szop@moj-posao.net</strong>
        </a>,by deactivating or deleting their user account.</p>
      <p>Personal data and any other data can only be used in the manner prescribed by these Terms
        and Conditions of Use. Using data for any other purpose (copying, transcribing,
        distribution, etc.) without the consent of the Company, the Employee and the Employer who is
        a natural person, and especially for mass distribution of announcements, promotional
        messages and similar information, is strictly forbidden. The Company shall notify competent
        authorities, including but not limited to the State&#8217;s Attorney Office of the Republic
        of Croatia, the Ministry of Interior and the Croatian Personal Data Protection Agency, of
        any activity that is in violation of the aforementioned ban.</p>
    </li>
    <li>
      <strong>Jurisdiction and settlement of disputes</strong>
      <p>For all legal relationships formed between the Company, the Employers and the Employees,
        Croatian substantive law is applied alongside these Terms and Conditions of Use, except for
        the provisions pertaining to resolving the conflict of laws.</p>
      <p>The Company, the Employers and the Employees shall attempt to settle any disputes amicably,
        and in the event of initiation of court proceedings, the court of subject matter
        jurisdiction in Zagreb shall be competent.</p>
    </li>
    <li>
      <strong>Final provisions</strong>
      <p>These Terms and Conditions of Use enter into effect on the day of their publication on the
        website <router-link to="/">www.poslovac.net</router-link>.</p>
      <p>The Company reserves the right to amend these Terms and Conditions of Use at any time.
        Version published on the website <router-link to="/">www.poslovac.net</router-link>
        shall be considered as the valid version of the Terms and Conditions of Use.</p>
      <p>I give consent to the Company TAU ON-LINE d.o.o. for the collection and processing of my
        personal data in accordance with the purpose stated in these Terms and Conditions of
        Use.</p>
      <p>I consent to having my data retained until recalled, until I request erasure or until I
        withdraw my consent.</p>
      <p>I have been informed of my right to report any suspicion of misuse of personal data in
        writing to the address: <strong>TAU ON-LINE d.o.o. (Data protection officer), Strojarska
          cesta 20, 10000 Zagreb</strong> or to the e-mail address: <a
          href="mailto:szop@moj-posao.net"><strong>szop@moj-posao.net</strong></a>.</p>
      <p>Zagreb, 25 July 2018</p>
    </li>
  </div>
</template>

<script>
export default {
  name: 'TermsEN',
};
</script>
