<template>
  <form class="search-form" @submit.prevent="submit">
    <ul>
      <li>
        <label id="search-filter">
          <i class="fas fa-search"></i>
          <input v-model="search" placeholder="Pretraži radna mjesta..." />
        </label>
      </li>
      <div class="input-separator" />
      <li>
        <label id="location-filter">
          <i class="fas fa-map-marker-alt"></i>
          <places-autocomplete v-model="location" />
        </label>
      </li>
    </ul>
    <Button title="TRAŽI" type="submit" />
  </form>
</template>

<script>
import { mapState } from 'vuex';
import PlacesAutocomplete from '@/components/shared/PlacesAutocomplete.vue';

export default {
  name: 'Search',
  components: {
    PlacesAutocomplete,
  },
  computed: {
    ...mapState('employee/jobs', {
      jobsFilter: state => state.list.filter,
    }),
  },
  data() {
    return {
      search: '',
      location: {
        lat: null,
        lng: null,
        place: '',
      },
    };
  },
  methods: {
    submit() {
      let query = {};
      const search = this.search.trim();
      const location = this.location && {
        latitude: this.location.lat,
        longitude: this.location.lng,
        place: this.location.place,
      };

      if (search.length) {
        query.search = search;
      }
      if (location) {
        query = { ...query, ...location };
      }

      this.$router.push({ name: 'JobSearch', query });
    },
  },
  mounted() {
    this.search = this.jobsFilter.search;
    this.location = {
      lat: this.jobsFilter.latitude,
      lng: this.jobsFilter.longitude,
      place: this.jobsFilter.place,
    };
  },
};
</script>

<style lang="scss" scoped>
  .search-form {
    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 95px;
    height: auto;
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 0;
      width: 100%;
      max-width: 980px;
      li {
        display: inline-flex;
        align-items: center;
        padding: 0;
        min-width: 300px;
        padding-right: 20px;
        :nth-of-type(2) {
          position: relative;
          z-index: 20;
          left: -2px;
        }
        label {
          position: relative;
          width: 100%;
          i {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 60px;
            width: 70px;
            color: #8A8A8A;
            font-size: 19px;
          }
          input {
            height: 60px;
            width: 100%;
            padding-left: 60px;
            background-color: transparent;
            border: none;
            border-radius: 0;
            color: #585858;
            font-size: 19px;
            font-weight: normal;
          }
        }
      }
      .input-separator {
        content: "";
        height: 50px;
        width: 1px;
        background-color: black;
        opacity: 0.15;
      }
    }
    button {
      width: 182px;
      height: 64px;
      margin: 10px;
      font-size: 20px;
    }
    @media (max-width: 1300px) {
      flex-wrap: wrap;
      padding-left: 20px;
      padding-right: 20px;
      ul {
        flex-direction: column;
        li {
          width: 100%;
          min-width: unset!important;
          &:nth-of-type(2) {
            flex-direction: column;
          }
        }
        .input-separator {
          width: 96%;
          height: 1px;
        }
      }
      button {
        margin-top: 40px;
      }
    }
  }

  /* Styles for Homepage Search form */
  section#intro {
    .search-form {
      ul {
        border: solid 2px #FFFFFF;
        border-radius: 5px;
        background: linear-gradient(#ECECEC, #FFFFFF);
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
        li {
          &:first-child {
            min-width: 460px;
            flex: 1;
          }
          label {
            input {
              &::placeholder {
                color: #979797;
              }
              &:focus::placeholder {
                color: transparent;
              }
            }
          }
        }
      }
      button {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      }
      @media (max-width: 1300px) {
        max-width: 700px;
      }
    }
  }
</style>
