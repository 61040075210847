import qs from 'querystring';

function cloneRoute(to, from) {
  const clone = {
    name: to.name,
    path: to.path,
    hash: to.hash,
    query: to.query,
    params: to.params,
    fullPath: to.fullPath,
    meta: to.meta,
  };
  if (from) {
    clone.from = cloneRoute(from);
  }
  return Object.freeze(clone);
}

export default function (store, router, options) {
  const moduleName = (options || {}).moduleName || 'route';

  store.registerModule(moduleName, {
    namespaced: true,
    state: cloneRoute(router.currentRoute),
    mutations: {
      routeChanged(state, transition) {
        // eslint-disable-next-line no-param-reassign
        store.state[moduleName] = cloneRoute(transition.to, transition.from);
      },
    },
    actions: {
      changeRoute({ commit }, { to, from }) {
        commit('routeChanged', { to, from });
      },
      push(_, route) {
        if (this.state.router.name !== route.name) {
          router.push(route);
        }
      },
      replaceQuery(context, queryObject) {
        const queryString = qs.stringify(queryObject);
        router.history.replace(`${router.currentRoute.path}?${queryString}`);
      },
      clearQuery() {
        router.history.replace(router.currentRoute.path);
      },
    },
  });

  let isTimeTraveling = false;
  let currentPath;

  // sync router on store change
  const storeUnwatch = store.watch(
    state => state[moduleName],
    (route) => {
      const { fullPath } = route;
      if (fullPath === currentPath) {
        return;
      }
      if (currentPath != null) {
        isTimeTraveling = true;
        router.push(route);
      }
      currentPath = fullPath;
    },
    { sync: true },
  );

  // sync store on router navigation
  const afterEachUnHook = router.afterEach((to, from) => {
    if (isTimeTraveling) {
      isTimeTraveling = false;
      return;
    }
    currentPath = to.fullPath;
    store.dispatch(`${moduleName}/changeRoute`, { to, from });
  });

  return function unsync() {
    // On unsync, remove router hook
    if (afterEachUnHook != null) {
      afterEachUnHook();
    }

    // On unsync, remove store watch
    if (storeUnwatch != null) {
      storeUnwatch();
    }

    // On unsync, unregister Module with store
    store.unregisterModule(moduleName);
  };
}
