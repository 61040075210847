import { createStore } from '@/store/storeFactories';
import WorkExperienceService from '@/services/work-experience.service';

export default createStore({
  services: {
    getListService: WorkExperienceService.getWorkExperience,
    saveService: WorkExperienceService.saveWorkExperience,
    createService: WorkExperienceService.createWorkExperience,
    deleteService: WorkExperienceService.deleteWorkExperience,
  },
});
