/* eslint-disable class-methods-use-this */
import axios from '@/utils/axios';
import { getCompaniesResponseDataToModel, saveCompanyModelToRequestData } from './service-helpers';

class CompanyService {
  async getCompanies() {
    const response = await axios.get('/companies/list/');
    return {
      data: getCompaniesResponseDataToModel(response.data),
      total: response.length,
    };
  }

  async saveCompany(company) {
    const response = await axios.patch(`companies/details/${company.id}/`, saveCompanyModelToRequestData(company));
    if (response.status === 200) {
      return {
        success: true,
        message: '',
      };
    }

    return {
      success: false,
      message: 'Neispravni podaci',
    };
  }
}

export default new CompanyService();
