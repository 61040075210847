/* eslint-disable class-methods-use-this */
import axios from '@/utils/axios';
import { getProfileResponseDataToModel, saveProfileModelToRequestData } from './service-helpers';

class ProfileService {
  async getProfile() {
    const response = await axios.get('/auth/user/');
    return getProfileResponseDataToModel(response.data);
  }

  async saveProfile(data) {
    const response = await axios.patch('/auth/user/', saveProfileModelToRequestData(data));
    if (response.status === 200) {
      return {
        success: true,
        message: '',
      };
    }

    return {
      success: false,
      message: '',
    };
  }
}

export default new ProfileService();
