<template>
  <ul class="container">
    <li v-for="item in data" :key="item.documentId">
      <div class="user-documents">
        <h6>{{ item.documentName }}</h6>
        <a :href="item.partialUrl" download target="_blank">Download</a>
      </div>
    </li>
  </ul>
</template>

<script>
import { format } from 'date-fns';
import { differenceInYearAndMonthsStr } from '@/utils/date';

export default {
  name: 'UserDocuments',
  props: {
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  methods: {
    format,
    workDuration: differenceInYearAndMonthsStr,
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: #F5F5F5;
  li {
    padding-top: 25px;
    &:first-of-type {
      padding-top: 0;
    }
    h6 {
      margin-bottom: 8px;
      font-size: 19px;
      font-weight: bold;
      color: #461dc1;
    }
    p {
      color: #2e16cb;
      &.description {
        margin-top: 15px;
        color: #2e16cb
      }
    }
    a {
      color: #2e16cb
    }
  }
}
</style>
