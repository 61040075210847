<template>
  <div class="container">
    <h2>ODABERITE VRSTU OGLASA:</h2>
    <selectables
      :options="data"
      type="radio"
      v-model="validate.typeID.$model"
    >
      <template v-slot:default="slotProps">
        <div class="selectable-option">
          <div class="left">
            <h3>{{ slotProps.item.name }}</h3>
            <span>{{ slotProps.item.description }}</span>
          </div>
          <div class="right">
            <span class="right-price">{{ slotProps.item.price }} EUR</span>
            <span class="right-tax"> + PDV</span>
          </div>
        </div>
      </template>
    </selectables>
  </div>
</template>

<script>
import Selectables from './Selectables.vue';

export default {
  name: 'SelectType',
  components: {
    Selectables,
  },
  props: {
    validate: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  > h2 {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #393939;
  }
  .selectable-option {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .left {
      color:#777;
      h3 {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 5px;
        @media (max-width: 440px) {
          font-size: 22px;
          white-space: nowrap;
          overflow: hidden;
        }
      }
    }
    .right {
      display: flex;
      flex-direction: column;
      font-size: 30px;
      color: #777;
      @media (max-width: 440px) {
        font-size: 22px;
      }
    }
    .right-price {
    overflow: hidden;
    white-space: nowrap;
    }
    .right-tax {
      font-size: 18px;
      color: #777;
      @media (max-width: 440px) {
        font-size: 10px;
      }
    }
  }
}
</style>
