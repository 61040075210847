<template>
  <div>
    <slider :activeIndex="activeIndex">
      <basic-info
        v-model="form"
        :validate="$v.form"
        @destroy="$emit('destroy')"
        @next="activeIndex = 1"
      />
      <company-info
        v-model="form.company"
        :validate="$v.form.company"
        :industries="industries"
        :isLoading="registrationInProgress"
        :serverValidationMessage="registrationServerMessage"
        @back="activeIndex = 0"
        @submit="submit"
      />
      <thank-you @done="$emit('done')" />
    </slider>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required, email, minLength } from 'vuelidate/lib/validators';
import BasicInfo from './BasicInfo.vue';
import CompanyInfo from './CompanyInfo.vue';
import ThankYou from '../ThankYou.vue';
import Slider from '../Slider.vue';

export default {
  name: 'EmployerForm',
  components: {
    BasicInfo,
    CompanyInfo,
    ThankYou,
    Slider,
  },
  computed: {
    ...mapState('shared/industries', {
      industries: state => state.list.data,
    }),
    ...mapState('session', {
      registrationInProgress: state => state.registration.inProgress,
      registrationSuccess: state => state.registration.success,
      registrationServerMessage: state => state.registration.message,
    }),
  },
  data() {
    return {
      activeIndex: 0,
      form: {
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        phone: '',
        company: {
          name: '',
          oib: '',
          email: '',
          industry: null,
          location: {
            lat: null,
            lng: null,
            place: '',
          },
        },
      },
    };
  },
  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      email: {
        required,
        email,
      },
      password: {
        required,
        minLength: minLength(6),
      },
      phone: {
        required,
      },
      company: {
        name: {
          required,
        },
        oib: {
        },
        email: {
          required,
          email,
        },
        industry: {
          required,
          minLength: minLength(1),
        },
        location: {
          lat: { required },
          lng: { required },
          place: { required },
        },
      },
    },
  },
  methods: {
    ...mapActions('session', ['register']),
    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid === false) {
        await this.register({ user: this.form, isEmployer: true });
        if (this.registrationSuccess) {
          this.activeIndex = 2;
        }
      } else {
        this.switchToFirstInvalidPage();
      }
    },
    switchToFirstInvalidPage() {
      const {
        firstName,
        lastName,
        // eslint-disable-next-line no-shadow
        email,
        password,
        phone,
      } = this.$v.form;

      if (firstName.$invalid
        || lastName.$invalid
        || email.$invalid
        || password.$invalid
        || phone.$invalid
      ) {
        this.activeIndex = 0;
      } else {
        this.activeIndex = 1;
      }
    },
  },
};
</script>
