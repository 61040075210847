<template>
  <div class="container">
    <div class="form-content">
      <Input
        leftIcon="fas fa-user"
        name="firstName"
        title="IME"
        theme="blue"
        v-model="validate.firstName.$model"
        :errorMessage="firstNameErrors"
      />
      <Input
        leftIcon="fas fa-user"
        name="lastName"
        title="PREZIME"
        theme="blue"
        v-model="validate.lastName.$model"
        :errorMessage="lastNameErrors"
      />
      <Input
        leftIcon="fas fa-envelope"
        name="email"
        title="EMAIL ADRESA"
        theme="blue"
        v-model="validate.email.$model"
        :errorMessage="emailErrors"
      />
      <Input
        leftIcon="fas fa-phone"
        name="phone"
        title="BROJ TELEFONA"
        theme="blue"
        v-model="validate.phone.$model"
        :errorMessage="phoneErrors"
      />
      <Input
        type="password"
        leftIcon="fas fa-lock"
        name="password"
        title="LOZINKA"
        theme="blue"
        v-model="validate.password.$model"
        :errorMessage="passwordErrors"
      />
    </div>
    <div class="controls">
      <Button
        title="Nastavi"
        @click="$emit('next')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BasicInfo',
  props: {
    value: {
      type: Object,
      required: true,
    },
    validate: {
      type: Object,
      required: true,
    },
  },
  computed: {
    firstNameErrors() {
      if (!this.validate.firstName.$dirty) return null;
      if (!this.validate.firstName.required) return 'Obavezan unos';
      return null;
    },
    lastNameErrors() {
      if (!this.validate.lastName.$dirty) return null;
      if (!this.validate.lastName.required) return 'Obavezan unos';
      return null;
    },
    emailErrors() {
      if (!this.validate.email.$dirty) return null;
      if (!this.validate.email.required) return 'Obavezan unos';
      if (!this.validate.email.email) return 'Nije email';
      return null;
    },
    phoneErrors() {
      if (!this.validate.phone.$dirty) return null;
      if (!this.validate.phone.required) return 'Obavezan unos';
      return null;
    },
    passwordErrors() {
      if (!this.validate.password.$dirty) return null;
      if (!this.validate.password.required) return 'Obavezan unos';
      if (!this.validate.password.minLength) return 'Najmanje 6 znakova';
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.controls {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 50px;
  button {
      margin: 0;
      border: none;
      &:hover {
        border-color: transparent;
      }
    }
}
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  .form-content {
    flex: 1;
  }
}
</style>
