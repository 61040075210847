<template>
  <input
    :class="theme"
    type="text"
    :value="value.place"
    :placeholder="placeholder"
    ref="placeAutocomplete"
    @keydown="simulateKeyDownOnEnter"
  />
</template>

<script>
export default {
  name: 'PlacesAutocomplete',
  inheritAttrs: false,
  props: {
    value: {
      type: Object,
      default() {
        return {
          lat: null,
          lng: null,
          place: null,
        };
      },
    },
    placeholder: {
      type: String,
      default: 'Unesi lokaciju',
    },
    types: {
      type: Array,
      default() {
        return ['(cities)'];
      },
    },
    theme: {
      type: String,
      default() {
        return 'default';
      },
    },
  },
  methods: {
    simulateKeyDownOnEnter(event) {
      const selectedItem = document.getElementsByClassName('pac-item-selected');
      if (selectedItem.length === 0 && event.which === 13) {
        const kybEvent = new KeyboardEvent('keydown', {
          key: 'ArrowDown',
          keyCode: 40,
          which: 40,
        });
        this.$refs.placeAutocomplete.dispatchEvent(kybEvent);
        event.preventDefault();
        // stopPropagation
      }
    },
  },
  mounted() {
    this.placeAutocomplete = new window.google.maps.places.Autocomplete(
      (this.$refs.placeAutocomplete),
      {
        types: this.types,
        fields: ['geometry.location', 'name'],
      },
    );
    this.placesAutocompleteListener = this.placeAutocomplete.addListener('place_changed', () => {
      const place = this.placeAutocomplete.getPlace();
      if (place.geometry) {
        const lat = place.geometry.location.lat();
        const lng = place.geometry.location.lng();
        const placeName = place.name;
        this.$emit('input', { lat, lng, place: placeName });
      }
    });
  },
  beforeDestroy() {
    window.google.maps.event.clearInstanceListeners(this.placeAutocomplete);
    window.google.maps.event.removeListener(this.placesAutocompleteListener);
  },
};
</script>

<style>
.pac-container { z-index: 100000 !important;  }
</style>

<style lang="scss" scoped>
.default {
  background-color: white;
  border: 1px solid #e8e8e8;
  color: #777777;
  &:focus { outline: none; }
}
.blue {
  color: #FFF;
  border-color: transparent;
  background-color: #1C434A;
  &:-webkit-autofill {
    box-shadow: inset 0 0 0 9999px #1C434A;
    -webkit-box-shadow: inset 0 0 0 9999px #1C434A;
    -webkit-text-fill-color: #FFF;
    border-width: 0;
  }
}
input {
  height: 60px;
  width: 100%;
  padding: 0 20px;
  border-radius: 5px;
  border-image: initial;
  font-size: 19px;
}
</style>
