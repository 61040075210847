import Vue from 'vue';
import Vuex from 'vuex';
import session from './modules/session.store';
import employee from './modules/employee';
import employer from './modules/employer';
import shared from './modules/shared';
import application from './modules/application';

Vue.use(Vuex);

const store = new Vuex.Store({
  strict: true,
  modules: {
    session,
    employee,
    employer,
    shared,
    application,
  },
});

export default store;
