export const Types = {
  SELECT: 'SELECT',
  UPDATE_FILTER: 'UPDATE_FILTER',
  SET_LIST_LOAD_STATE: 'SET_LIST_LOAD_STATE',
  SET_LIST_DATA: 'SET_LIST_DATA',
  SET_LOAD_STATE: 'SET_LOAD_STATE',
  SET: 'SET',
  CLEAR: 'CLEAR',
  SET_DATA: 'SET_DATA',
  SET_SAVE_STATE: 'SET_SAVE_STATE',
  SET_CREATE_STATE: 'SET_CREATE_STATE',
  SET_DELETE_STATE: 'SET_DELETE_STATE',
};

export function createCrudState() {
  return {
    inProgress: false,
    hasError: false,
    success: false,
    message: null,
  };
}

export function createState(defaultDetailState = {}, defaultFilterState = {}) {
  return {
    list: {
      data: [],
      dataById: {},
      total: 0,
      filter: defaultFilterState,
      inProgress: false,
      success: false,
      hasError: false,
    },
    details: {
      data: defaultDetailState,
      load: createCrudState(),
      save: createCrudState(),
      create: createCrudState(),
      delete: createCrudState(),
    },
  };
}

export function createMutations(key = 'id') {
  return {
    [Types.SELECT](state, id) {
      state.details.data.id = id;
    },
    [Types.UPDATE_FILTER](state, filter) {
      state.list.filter = { ...state.list.filter, ...filter };
    },
    [Types.SET_LIST_LOAD_STATE](state, { inProgress = false, success = false, hasError = false }) {
      state.list.inProgress = inProgress;
      state.list.success = success;
      state.list.hasError = hasError;
    },
    [Types.SET_LIST_DATA](state, { data, total }) {
      state.list.data = data;
      state.list.dataById = data.reduce((acc, item) => ({ ...acc, [item[key]]: item }), {});
      state.list.total = total;
    },
    [Types.SET_LOAD_STATE](state, { inProgress = false, success = false, hasError = false }) {
      state.details.load.inProgress = inProgress;
      state.details.load.success = success;
      state.details.load.hasError = hasError;
    },
    [Types.SET](state, data) {
      state.details.data = { ...state.details.data, ...data };
    },
    [Types.CLEAR](state) {
      state.details.data = {};
    },
    [Types.SET_DATA](state, data) {
      state.details.data = data;
    },
    [Types.SET_SAVE_STATE](state, {
      inProgress = false,
      success = false,
      message = null,
      hasError = false,
    }) {
      state.details.save.inProgress = inProgress;
      state.details.save.success = success;
      state.details.save.message = message;
      state.details.save.hasError = hasError;
    },
    [Types.SET_CREATE_STATE](state, {
      inProgress = false,
      success = false,
      message = null,
      hasError = false,
    }) {
      state.details.create.inProgress = inProgress;
      state.details.create.success = success;
      state.details.create.message = message;
      state.details.create.hasError = hasError;
    },
    [Types.SET_DELETE_STATE](state, {
      inProgress = false,
      success = false,
      message = null,
      hasError = false,
    }) {
      state.details.delete.inProgress = inProgress;
      state.details.delete.success = success;
      state.details.delete.message = message;
      state.details.delete.hasError = hasError;
    },
  };
}

export function createActions({
  getService,
  saveService,
  createService,
  deleteService,
  getListService,
}) {
  return {
    getList(context, filterData) {
      context.commit(Types.UPDATE_FILTER, filterData);
      context.commit(Types.SET_LIST_LOAD_STATE, { inProgress: true });
      return getListService(context.state.list.filter)
        .then((result) => {
          context.commit(Types.SET_LIST_LOAD_STATE, { success: true });
          context.commit(Types.SET_LIST_DATA, { data: result.data, total: result.total });
        })
        .catch((error) => {
          context.commit(Types.SET_LIST_LOAD_STATE, { hasError: true });
          throw error;
        });
    },

    select(context, id) {
      context.commit(Types.SELECT, id);
      context.dispatch('get', id);
    },

    get(context, id) {
      context.commit(Types.SET_LOAD_STATE, { inProgress: true });
      return getService(id)
        .then((result) => {
          context.commit(Types.SET_LOAD_STATE, { success: true });
          context.commit(Types.SET_DATA, result);
        })
        .catch((error) => {
          context.commit(Types.SET_LOAD_STATE, { hasError: true });
          throw error;
        });
    },

    set(context, data) {
      context.commit(Types.SET, data);
    },

    clear(context) {
      context.commit(Types.CLEAR);
    },

    save(context, data) {
      context.commit(Types.SET_SAVE_STATE, { inProgress: true });
      return saveService(data)
        .then((result) => {
          if (result.success) {
            context.commit(Types.SET_SAVE_STATE, { success: true });
          } else {
            context.commit(Types.SET_SAVE_STATE, { success: false, message: result.message });
          }
        })
        .catch((error) => {
          context.commit(Types.SET_SAVE_STATE, { hasError: true });
          throw error;
        });
    },

    create(context, data) {
      context.commit(Types.SET_CREATE_STATE, { inProgress: true });
      return createService(data)
        .then((result) => {
          if (result.success) {
            context.commit(Types.SET_CREATE_STATE, { success: true });
          } else {
            context.commit(Types.SET_CREATE_STATE, { success: false, message: result.message });
          }
        })
        .catch((error) => {
          context.commit(Types.SET_CREATE_STATE, { hasError: true });
          throw error;
        });
    },

    delete(context, data) {
      context.commit(Types.SET_DELETE_STATE, { inProgress: true });
      return deleteService(data)
        .then((result) => {
          if (result.success) {
            context.commit(Types.SET_DELETE_STATE, { success: true });
          } else {
            context.commit(Types.SET_DELETE_STATE, { success: false, message: result.message });
          }
        })
        .catch((error) => {
          context.commit(Types.SET_DELETE_STATE, { hasError: true });
          throw error;
        });
    },
  };
}

export function createStore(config) {
  return {
    namespaced: true,
    state: createState(config.defaultDetailsState, config.defaultFilterState),
    actions: createActions(config.services),
    mutations: createMutations(config.key),
  };
}
