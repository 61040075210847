<template>
  <placeholder-container
    :hide="!!job.id"
    message="Označite oglas kako bi ste ga pročitali"
  >
    <div class="container">
      <job-status :data="job" :status="status" />
      <h3>PREGLED OGLASA</h3>
      <job-details :data="job" />
      <div v-show="job.id" class="actions">
        <Button
          :disabled="job.jobApplicationsCount === 0"
          title="Prijave"
          @click="gotoJobApplications(job)"
        />
        <Button
          theme="white"
          title="Kopiraj oglas"
          @click="copyJob(job)"
        />
        <Button
          theme="white"
          :disabled="status === 'active' || status === 'expired'"
          title="Uredi oglas"
          @click="editJob(job)"
        />
        <div class="delete-job-btn" @click="openConfirmDeleteModal">
          <span>Obriši oglas</span>
        </div>
      </div>
      <confirm-modal
        ref="confirmDeleteJobModal"
        title="Jeste li sigurni da želite izbrisati oglas?"
        @confirm="deleteJob(job.id)"
      />
    </div>
  </placeholder-container>
</template>

<script>
import { mapState } from 'vuex';
import ConfirmModal from '@/components/basic/ConfirmModal.vue';
import JobDetails from '@/components/shared/JobDetails.vue';
import JobStatus from './JobStatus.vue';

export default {
  name: 'PublishedJobDetail',
  components: {
    ConfirmModal,
    JobDetails,
    JobStatus,
  },
  props: {
    jobId: {
      type: Number,
    },
  },
  computed: {
    ...mapState('employer/jobs', {
      job: state => state.details.data,
      loadingJob: state => state.details.load.inProgress,
    }),
    status() {
      if (this.job.isActive) return 'active';
      if (this.job.expirationDate < new Date()) return 'expired';
      return 'created';
    },
  },
  methods: {
    gotoJobApplications(job) {
      this.$router.push({ name: 'JobApplications', params: { id: job.id } });
    },
    copyJob(job) {
      this.$router.push({ name: 'EditJob', params: { id: job.id, mode: 'copy' } });
    },
    editJob(job) {
      this.$router.push({ name: 'EditJob', params: { id: job.id, mode: 'edit' } });
    },
    openConfirmDeleteModal() {
      this.$refs.confirmDeleteJobModal.open();
    },
    deleteJob(jobId) {
      this.$emit('delete', jobId);
    },
  },
  watch: {
    jobId(value) {
      this.$store.dispatch('employer/jobs/select', value);
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.actions {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 25px;
  button {
    margin: 0 15px 0 0;
  }
  @media (max-width: 430px) {
    flex-direction: column;
    button {
      width: 100%;
      margin: 0 0 10px;
    }
  }
}
h3 {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
  color: #393939;
  opacity: 0.33;
  text-align: left;
  text-transform: uppercase;
}
.delete-job-btn {
  margin-left: auto;
  padding: 0 16px;
  color: rgb(214, 0, 0);
  font-weight: bold;
  cursor: pointer;

  :hover {
    color: red;
  }
}
</style>
