<template>
  <div class="container">
    <h2>ODABERITE TVRTKU ZA KOJU ŽELITE OBJAVITI OGLAS:</h2>
    <selectables
      :options="data"
      type="radio"
      v-model="validate.companyID.$model"
    >
      <template v-slot:default="slotProps">
        <div class="selectable-option">
          <h3>{{ slotProps.item.name }}</h3>
          <span>{{ slotProps.item.address }}</span>
        </div>
      </template>
    </selectables>
  </div>
</template>

<script>
import Selectables from './Selectables.vue';

export default {
  name: 'SelectCompany',
  components: {
    Selectables,
  },
  props: {
    validate: {
      type: Object,
      required: true,
    },
    data: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  > h2 {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: bold;
    color: #393939;
  }
  .selectable-option {
    overflow: hidden;
    h3 {
      font-size: 30px;
      font-weight: bold;
      color: #777777;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media (max-width: 440px) {
        font-size: 22px;
      }
    }
  }
  .selected {
    h3 {
      color: #26D35D;
    }
  }
}
</style>
