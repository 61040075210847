<template>
  <div class="error-message">
    <transition name="slidefade">
      <span v-show="message">
        {{ message }}
      </span>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'ErrorMessage',
  props: {
    message: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.slidefade-enter {
  opacity: 0;
}
.slidefade-enter-active {
  transition: opacity 300ms;
}
.slidefade-enter-top {
  opacity: 1;
}
.error-message {
  display: block;
  height: 20px;
  margin-top: 5px;
  width: 100%;
  span {
    display: block;
    position: relative;
    text-align: right;
    color: rgb(234, 86, 0);
    font-size: 14px;
    font-weight: bold;
  }
}
</style>
