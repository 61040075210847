<template>
  <section id="intro">
    <div class="outer-container">
      <img src="@/assets/images/logos/Poslovac_txt_logo.png" alt="Poslovac"/>
      <h3>Tvoj lovac na posao</h3>
      <Search v-if="false" />
      <div class="separator" />
      <div class="inner-container">
        <div class="column">
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Search from './Search.vue';

export default {
  name: 'Intro',
  components: { Search },
};
</script>

<style lang="scss" scoped>
  section#intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .outer-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      padding-top: 310px;
      padding-bottom: 50px;
      background: url("../../assets/images/backgrounds/background_faded.png") top left;
      background-size: cover;
      background-color: #F9F9F9;

      h3 {
        color: #FFFFFF;
        font-size: 43px;
        font-weight: 300;
        line-height: 52px;
        margin-top: 0px;
        margin-bottom: 80px;
      }
      h4 {
        color: #000;
        margin: 10px 0 20px 0;
        font-size: 32px;
        font-weight: bold;
      }
      p {color: #777;}

      > img {
        display: block;
        width: 100%;
        height: auto;
        max-width: 543px!important;
        text-shadow: 0px 3px 6px #00000029;
      }
    }
    .column {
      width: 50%;
      display: flex;
      justify-content: center;
      &:first-of-type {
        justify-content: flex-start;
      }
    }
    .separator {
      width: 100%;
      max-width: 520px;
      margin: 100px 0 70px;
      border-bottom: solid 1px #FFF;
      opacity: 0.56;
    }
    .intro-wrapper {
      text-align: left;
      max-width: 522px;
    }
    .inner-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 100%;
      max-width: 1000px;
    }
    .intro-buttons {
      margin-top: 40px;
      display: flex;

      a { margin: 0 13px 40px 0; }
    }
  }
  @media (max-width: 1300px) {
    section#intro {
      .outer-container {
        padding-top: 225px;
        .inner-container {
          padding: 40px 5% 40px 5%;
        }
        > h3 {
          padding: 0 40px;
          margin-top: 0;
          text-align: center;
        }
      }
    }
  }
  @media (max-width: 990px) {
    section#intro {
      .outer-container {
        .separator {
          display: none;
        }
        .inner-container {
          flex-direction: column-reverse;
          .column { width: 100%; }
          .column:nth-of-type(2) {
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 25px auto;
          }
        }
        .intro-buttons {
          width: 100%;
          justify-content: left;
          a { margin-right: 10px; }
          a:last-of-type { margin: 0; }
        }
      }
    }
  }
  @media (max-width: 460px) {
    section#intro {
      .outer-container {
        > img {
          max-width: 260px!important;
        }
        > h3 {
          font-size: 20px;
        }
        h4 {
          font-size: 35px;
        }
      }
    }
  }
</style>
